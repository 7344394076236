import PropTypes from 'prop-types';
// @mui
import { Button, Grid, Snackbar } from '@mui/material';
import ShopProductCard from './ProductCard';
import { ReactSortable } from "react-sortablejs";
import { useEffect, useState } from 'react';

import { FixedSizeList } from 'react-window';


// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
};

export default function ProductList(cardProps) {
  const [open, setOpen] = useState(false);

  const { setUpdateValues, levelList, setLevelList, products, setProducts, selectedLevels, setSelectedLevels, uid } = cardProps;

  const [editableIndex, setEditableIndex] = useState("-1");

  useEffect(() => {
    setLevelList(products)
  }, [products])


  function getChangedValues(arr1, arr2) {
    var changedValues = [];

    // Loop through arr1 and compare each element with the corresponding element in arr2
    for (var i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        changedValues.push(arr2[i]); // If elements are not equal, push the value from arr2 into changedValues array
      }
    }

    return changedValues;
  }



  useEffect(() => {
    let updatedValues = getChangedValues(products, levelList)

    if (updatedValues.length > 0) {
      setUpdateValues(updatedValues)
    } else {
      setUpdateValues([])
    }

  }, [levelList])




  return (
    // <Grid container spacing={3} {...other}>
    <>
      {/* <Snackbar
      key={}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
      message={messageInfo ? messageInfo.message : undefined}
      action={
        <React.Fragment>
          <Button color="secondary" size="small" onClick={handleClose}>
            UNDO
          </Button>
          <IconButton
            aria-label="close"
            color="inherit"
            sx={{ p: 0.5 }}
            onClick={handleClose}
            >
            <CloseIcon />
          </IconButton>
        </React.Fragment>
      }
      /> */}

      {
        localStorage.getItem('level_version') === 'v1' ?
          <>

            <div style={{
              display: "grid",
              gap: "25px",
              gridTemplateColumns: "repeat(1, 1fr)",
              minWidth: "750px"
            }}>

              {levelList.map((product) => (
                <Grid key={product.id} item xs={12} sm={6} md={3}>
                  <ShopProductCard
                    product={product}
                    products={products}
                    setProducts={setProducts}
                    editableIndex={editableIndex}
                    setEditableIndex={setEditableIndex}
                    selectedLevels={selectedLevels}
                    setSelectedLevels={setSelectedLevels}
                    uid={uid}
                  />
                </Grid>
              ))}
            </div>
          </>
          :

          <ReactSortable style={{
            display: "grid",
            gap: "25px",
            gridTemplateColumns: "repeat(1, 1fr)",
            minWidth: "750px"
          }} list={levelList} setList={setLevelList}>

            {levelList.map((product) => (
              <Grid key={product.id} item xs={12} sm={6} md={3}>
                <ShopProductCard
                  product={product}
                  products={products}
                  setProducts={setProducts}
                  editableIndex={editableIndex}
                  setEditableIndex={setEditableIndex}
                  selectedLevels={selectedLevels}
                  setSelectedLevels={setSelectedLevels}
                  uid={uid}
                />
              </Grid>
            ))}
          </ReactSortable>

      }


      {/* // </Grid> */}
    </>
  );
}
