
import React from 'react'

export default function EditIcon() {
  return (
    <div>
      <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.863 6.76712L13.2055 2.16438L14.7397 0.630137C15.1598 0.210046 15.676 0 16.2882 0C16.9005 0 17.4163 0.210046 17.8356 0.630137L19.3699 2.16438C19.79 2.58447 20.0091 3.09151 20.0274 3.68548C20.0457 4.27945 19.8448 4.78612 19.4247 5.20548L17.863 6.76712ZM1.09589 20C0.785392 20 0.524935 19.8948 0.314524 19.6844C0.104113 19.474 -0.000726802 19.2139 3.79201e-06 18.9041V15.8082C3.79201e-06 15.6621 0.0274011 15.5204 0.0821956 15.383C0.13699 15.2457 0.219182 15.1226 0.328771 15.0137L11.6164 3.72603L16.274 8.38356L4.98631 19.6712C4.87672 19.7808 4.75361 19.863 4.61699 19.9178C4.48037 19.9726 4.33863 20 4.19178 20H1.09589Z" fill="#C7CBD6"/>
        </svg>
    </div>
  )
}
