export const createGroup = (
    data: any,
    zipPath:string,
    modeType:string
) => {
    let zipS3Path = ""
    if (zipPath){
        zipS3Path = `${process.env.REACT_APP_S3_BASE_PATH_TL}${zipPath}`
    } 
    
    let group:any = {
        "group_no": data.group_index,
        "group_name": data.group_name,
        "group_media_name": zipPath,
        "group_media_path": zipS3Path,
    }

    if(modeType === "edit") {
        group.group_id = data.group_id;
    }
    return group;
}

export const groupIndexUpdate = (data: any) => {
    let characterPoints:any = {
        "group_data": data,
    }
    return characterPoints;
}

export const levelIndexUpdate = (data: any) => {
    let characterPoints:any = {
        "level_data": data,
    }
    return characterPoints;
}
