import React, {useContext, useEffect, useState} from 'react'
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Tooltip } from '@mui/material';
import { Link, Navigate, useParams } from 'react-router-dom';

import { TextField } from 'mui-rff';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

import { TextField as MUITextField } from '@mui/material';

import { createGroupLevels, createLevelsJSONData } from '../../data/mappers/TinyLearner/createGroupLevels';
import { S3Client, DeleteObjectCommand } from "@aws-sdk/client-s3";

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { HideProgressBar, ShowProgressBar, ShowErrorAlert, ShowSuccessAlert } from '../../store/alerts';
import { isApiCodeSucess, postRequest, tlPostRequest } from '../../data/helpers/services';
import { AccessTokenContext } from '../../routes';
import "yet-another-react-lightbox/styles.css";
import Lightbox from "yet-another-react-lightbox";
import { TinyLearnerEndpoints } from '../../constants';
import { useNavigate } from 'react-router-dom';
import LevelZipUpload from '../../components/LevelZipUpload';
import { ReactSortable } from "react-sortablejs";
import { levelIndexUpdate } from '../../data/mappers/createLevel';


const s3Client = new S3Client({
    credentials: {
        accessKeyId: process.env.REACT_APP_S3_ACCESS_ID,
        secretAccessKey: process.env.REACT_APP_S3_ACCESS_KEY
    },
    region: process.env.REACT_APP_S3_REGION
});


export function PageStyles() {
  return (
    <>
        <style>
            {`
                .yarl__navigation_prev{
                    display: none !important;
                }
                .yarl__navigation_next{
                    display: none !important;
                }
            `}
        </style>
    </>
  )
}

export function DeleteConfirmation(props:any) {
    
    const { setOpenDelete, openDelete, zipArray, setZipArray, fields  } = props;
    const handleClose = () => {
        setOpenDelete({state: false, index: 0});
    };
    
    const removeImages = async (index) =>{
        let tempArray = zipArray;
        let zip_path = tempArray[index].media;

        if (zip_path) {
            const input = {
                Bucket: process.env.REACT_APP_S3_BUCKET,
                Key: zip_path
            }
            const command = new DeleteObjectCommand(input);
            await s3Client.send(command);
        }

        const deletedZip = tempArray[index];
        deletedZip.uploaded = false;
        deletedZip.media_name = "";
        deletedZip.media = "";
        deletedZip.level_no = "";
        deletedZip.is_deleted = true;
        // setZipArray(tempArray)

        // console.log("deletedZip",deletedZip)
        tempArray.splice(index, 1);
        const updatedZipArray = [];
        tempArray.forEach((zipObj, zip_index) => {
            zipObj.Id = `groupLevels[${zip_index}].level_zip_name`;
            // zipObj.level_no = Number(index)+1;
            // console.log("index",zip_index,index)
            // if (zip_index === index) {
            //     zipObj.uploaded = false;
            //     zipObj.media = ""
            //     zipObj.media_name = ""
            // }
            // else {
            //     updatedZipArray.push(zipObj);
            // }
            updatedZipArray.push(zipObj);
        });
            
        // console.log("tempArray>>>>>>>",index,tempArray,updatedZipArray)
        // setZipArray(tempArray)
        setZipArray(updatedZipArray)
        // setZipObj({...zipObj, uploadStarted:false, uploaded:false, media:""})
    }


    const deleteConfirmed = async() => {
        fields.remove(openDelete['index'])
        removeImages(openDelete['index'])

        setOpenDelete({state: false, index: 0});
    };

  return (
    <div>
        <Dialog
          open={openDelete['state']}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <h4>{"Delete Confirmation"}</h4>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this index ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className='cancel-class' onClick={handleClose}>
                Cancel
            </button>

            <button className='delete-class' onClick={deleteConfirmed}>
                Delete
            </button>
            </DialogActions>
        </Dialog>
      
    </div>
  )
}


export default function GroupDetail(props:any) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const getCookieAccessToken:any = useContext(AccessTokenContext)
    let accessToken:string = getCookieAccessToken.cookieAccessToken

    const { groupId } = useParams();
    const [levelNo, setLevelNo] = useState("");

    const [open, setOpen] = useState({state:false, media:""});

    const [openDelete, setOpenDelete] = useState<
        {state:boolean, index:number}
    >({state:false, index:0})

    let initialFormValues = {
        "level_id": null,
        "index": 1,
        "level_name": null
    }

    const [downloadJsonFile, setDownloadJsonFile] = useState([])

    const [initialForm, setInitialForm] = useState([])
    const [zipArray, setZipArray] = useState([])

    const [openAllLevelDelete, setOpenAllLevelDelete] = useState(false)
    const [ responseFetched, setResponseFetched] = useState(false);

    const [ groupName, setGroupName] = useState("");
    const [ groupIndex, setGroupIndex] = useState("");

    const [ levelList, setlevelList] = useState([]);
    
    const [draggableGroupList, setDraggableGroupList] = useState([])
    const [updateValues, setUpdateValues] = useState([]);

    useEffect(() => {
        setDraggableGroupList(levelList)
      },[levelList])
    
    function getChangedValues(arr1, arr2) {
    var changedValues = [];
    
    // Loop through arr1 and compare each element with the corresponding element in arr2
    for (var i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
        changedValues.push(arr2[i]); // If elements are not equal, push the value from arr2 into changedValues array
        }
    }
    return changedValues;
    }

    useEffect(() => {
        let updatedValues = getChangedValues(levelList, draggableGroupList)
        if (updatedValues.length > 0){
            setUpdateValues(updatedValues)
        }else{
            setUpdateValues([])
        }
    },[draggableGroupList])


    useEffect(() => {
        getGroupLevels()
    },[])

    const getJsonDownload = () => {
        const jsonData = downloadJsonFile;
        // Format the JSON with indentation
        const formattedJson = JSON.stringify(jsonData, null, 2);
        const blob = new Blob([formattedJson], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `group${groupIndex}.json`;
        link.click();
      };

    const getGroupLevels = async() =>{
        setDownloadJsonFile([])

        let res:any = {}
    
        res = await tlPostRequest(
            TinyLearnerEndpoints.GROUP_DETAIL,
            {
                group_id: groupId
            },
            accessToken
        );

        if (res) {
            if (isApiCodeSucess(res)) {
                let responseData = res.data.data;
                let levelsArray = responseData.levels_list_data;
                
                if (levelsArray.length > 0){
                    levelsArray.forEach(level => {
                        let arrayObj = {
                            index: level.level_no,
                            color1: level.color1 ?? "",
                            color2: level.color2 ?? "",
                            color3: level.color3 ?? "",
                            coins: level.coins ?? "",
                            name: level.name ?? "",
                            folder_level: level.level_name ?? "",
                            zipSize: level.zipSize ?? "",
                        }
                        let tempArray = downloadJsonFile
                        tempArray.push(arrayObj)
                        setDownloadJsonFile(tempArray);
                    })
                }

                setlevelList(levelsArray);
                const group_no = responseData.group_no;
                setGroupIndex(group_no)
                const group_name = responseData.group_name;
                setGroupName(group_name)
                setLevelNo(group_no);
                if (levelsArray.length > 0) {
                    levelsArray.forEach((resObj, index) => {
                        let tempArray = initialForm;
                        tempArray.push({
                            "level_id": resObj.id,
                            "index": resObj.level_no,
                            "level_name": resObj.level_name
                        })
                        
                        let tempZipArray:any = zipArray;
                        
                        tempZipArray.push({
                            Id:`groupLevels[${index}].level_zip_name`, 
                            uploadStarted:false, 
                            uploaded: resObj.level_media_name ? true : false, 
                            media: `${resObj.level_media_name}`,
                            level_no: String(`${resObj.level_no}`),
                            level_id: resObj.id,
                            is_deleted: false,
                            zipSize: resObj.zip_size
                        });

                        setZipArray(tempZipArray)

                        setInitialForm(tempArray)
                    })
                }
                else {
                    setInitialForm([{...initialFormValues}])
                    let tempZipArray = []
                    tempZipArray.push({
                        Id:`groupLevels[0].level_zip_name`,
                        uploadStarted:false,
                        uploaded:false,
                        media:"",
                        level_id: "",
                        is_deleted: false,
                        zipSize: ""
                    });
                    setZipArray(tempZipArray)
                }
            }
            setResponseFetched(true)
        }
    }

    const verifyIndexFailed = (event) => {
        let isDuplicate = false
        let tempIndexArray:number[] = []
        event.groupLevels.map(
            (obj) => {
                if (tempIndexArray.includes(parseInt(obj.index))){
                    dispatch(ShowErrorAlert({visible:true, message: `Index ${obj.index} used multiple times`}))
                    isDuplicate = true;
                }else{
                    tempIndexArray.push(parseInt(obj.index));
                }
            }
        )
        return isDuplicate;
    }

    const verifyImageNotUploaded = (event) => {
        let failed = false
        for (let i=0; i < event.groupLevels.length; i++){
            // FG IMAGE
            // console.log("zipArray",zipArray)
            if (!zipArray[i].media){
                failed = true;
                dispatch(ShowErrorAlert({visible: true, message: `Zip for index ${event.groupLevels[i].index} is missing`}))
                break;
                
            }
        }   
        return failed
    }

    const updateIndex = async() => {
        let indexSendForUpdate = []
        updateValues.forEach(x => 
            {
            indexSendForUpdate.push(
                {
                id:x.id,
                index: draggableGroupList.findIndex(i => i.id === x.id) + 1
                }
            )
            }
        )

        let requestData = levelIndexUpdate(indexSendForUpdate)

        var response: any = null;
        
        response = await tlPostRequest(TinyLearnerEndpoints.LEVEL_INDEX_UPDATE, requestData, accessToken);

        if (response) {
            dispatch(HideProgressBar());
            if (isApiCodeSucess(response)) {
                const resData = response.data
                dispatch(ShowSuccessAlert({visible:true, message:resData.message}));
                navigate(0)
            }
        }
    }

    const validateFileUploaded = (jsonData) => {
        let validated = true;
        if (!Array.isArray(jsonData)){
            // dispatch(ShowErrorAlert({visible:true, message:"An Array format data is required"}));
            dispatch(ShowErrorAlert({visible:true, message:`Invalid Json provided`}))
            validated = false;
        }
        jsonData.forEach((x,i) => {
            if (!x.index){
                dispatch(ShowErrorAlert({visible:true, message:`Index is required for ${i} position object`}));
                validated = false;
            }
            if (!x.color1){
                dispatch(ShowErrorAlert({visible:true, message:`color1 is required for ${i} position object`}));
                validated = false;
            }

            if (!x.color2){
                dispatch(ShowErrorAlert({visible:true, message:`color2 is required for ${i} position object`}));
                validated = false;
            }

            if (!x.color3){
                dispatch(ShowErrorAlert({visible:true, message:`color3 is required for ${i} position object`}));
                validated = false;
            }

            if (!x.name){
                dispatch(ShowErrorAlert({visible:true, message:`name is required for ${i} position object`}));
                validated = false;
            }

            if (!x.folder_level){
                dispatch(ShowErrorAlert({visible:true, message:`folder_level is required for ${i} position object`}));
                validated = false;
            }
        })
        return validated
    }

    const handleFileInputChange = async (event) => {
        const fileReader = new FileReader();
        const file = event.target.files[0];

        fileReader.onload = async (event) => {
            const fileContents = event.target.result;
            let jsonData;
        
            if (typeof fileContents === 'string') {
                try{
                    jsonData = JSON.parse(fileContents);
                }
                catch{
                    dispatch(ShowErrorAlert({visible:true, message:`Invalid Json provided`}))
                    const navigate_path = '/tinylearner/group/' + groupId + "/";
                    navigate(navigate_path)
                }   
            } else {
                // Handle other cases, such as ArrayBuffer
                console.error('Unsupported file type');
            }

            let validated = validateFileUploaded(jsonData);
            if (validated){
                let data = createLevelsJSONData(jsonData , groupId);
                var response: any = null;
                response = await tlPostRequest(
                    TinyLearnerEndpoints.GROUP_ADD_UPDATE_LEVEL_JSON,
                    data,
                    accessToken
                );
                if (response) {
                    dispatch(HideProgressBar());
                    if (isApiCodeSucess(response)) {
                        const resData = response.data;
                        dispatch(ShowSuccessAlert({visible:true, message:resData.message}));
                        const navigate_path = '/tinylearner/group/' + groupId + "/";
                        navigate(navigate_path)
                    }
                }
            }
            else {
                const navigate_path = '/tinylearner/group/' + groupId + "/";
                navigate(navigate_path)
            }
        };
        fileReader.readAsText(file);
    };

    const deleteAllLevelZipProps = {
        setOpenAllLevelDelete: setOpenAllLevelDelete,
        openAllLevelDelete: openAllLevelDelete,
        groupId: groupId
    }

    return (
        <Container sx={{mt:"-70px"}}>

        
            <DeleteAllLevelConfirmation {...deleteAllLevelZipProps} />
            <PageStyles />
            <Lightbox
            open={open['state']}        
            close={() => setOpen({state:false, media:""})}
            slides={[
                {
                    src: open['media'],
                    alt: "image 1",
                    width: 3840,
                    height: 2560,
                }
            ]} />
           
            <div
                className='row'
                style={{
                    // height: "80px",
                    display: "grid",
                    // gridTemplateColumns: "repeat(auto-fit, minmax(430px, 1fr) )",
                    // gridTemplateColumns: "repeat(auto-fit, minmax(630px, 1fr) )",
                    gridColumnGap: "1rem",
                    // alignItems: "center",

                    gridTemplateColumns: "repeat(2,1fr)",
                }}
            >

                <div>
                    <ArrowCircleLeftIcon
                        fontSize="large"
                        color="secondary"
                        onClick={()=> navigate(-1)}
                        sx={{
                            marginBottom: "10px",
                            marginLeft: "10px",
                            mt: 1,
                            "&:hover": {
                                color: "#103996",
                                cursor:"pointer",
                            }
                        }}
                    />
                    <MUITextField
                        id="outlined-basic"
                        label="Group Name"
                        variant="outlined"
                        value={responseFetched ? groupName : ""}
                        sx={{
                            mr:"10%",
                            background: "#fff",
                            ml: '20px',
                            boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.1)',
                            // width: "100%"
                        }}
                        type="text"
                        disabled
                    />
                </div>
                <div
                className="all-buttons"
                    style={{
                        // float: "right"
                        // width: "max-content",
                        // float: "right",
                        // display: "flex",
                        // justifyContent: "flex-end",

                        display: "grid",
                        gridColumnGap: "1rem",    
                        gridTemplateColumns: "repeat(2,1fr)",
                    }}
                >
                    <div>

                        { (updateValues.length > 0) ? 
                            <>
                            <Button variant="contained" sx={{
                                backgroundColor:"Green",
                                height: "50px",

                                "&:hover": {
                                backgroundColor: "#225c22",
                                cursor:"pointer",
                                height: "50px",
                                }
                            }} onClick={() => {
                                updateIndex()
                            }}>
                                <span
                                    style={{
                                    fontWeight: 600,
                                    fontSize: "16px"
                                    }}
                                >
                                    Save
                                </span>
                            </Button>


                            <Button variant="contained" sx={{
                                backgroundColor:"red",
                                height: "50px",
                                ml:1,
                                "&:hover": {
                                backgroundColor: "red",
                                cursor:"pointer",
                                height: "50px",
                                }
                            }} onClick={() => {
                                navigate(0)
                            }}>
                                <span
                                    style={{
                                    fontWeight: 600,
                                    fontSize: "16px"
                                    }}
                                >
                                    Cancel
                                </span>
                            </Button>
                            </>
                            : 
                            "" 
                        }
                    </div>
                    

                    <div
                        style={{
                            float: "right",
                            display: "flex",
                            justifyContent: "flex-end"
                        }}
                    >
                        <Tooltip title="Add Level JSON">
                            <>
                                <label htmlFor="upload-json">
                                    <Button
                                        variant="contained"
                                        component="span"
                                        startIcon={<CloudUploadIcon />}
                                        style={{
                                            border: "none",
                                            cursor: "pointer",
                                            marginLeft: "10px",
                                            height: "50px",
                                            width: 'max-content'
                                        }}
                                    >
                                        Level JSON
                                    </Button>
                                </label>
                                <input
                                    accept=".json"
                                    style={{
                                        display: 'none',
                                        background: "none"
                                    }}
                                    id="upload-json"
                                    type="file"
                                    onChange={handleFileInputChange}
                                />
                            </>
                        </Tooltip>

                        <Button
                            variant="contained"
                            component="span"
                            startIcon={<FileDownloadIcon />}
                            onClick={getJsonDownload}
                            style={{
                                border: "none",
                                cursor: "pointer",
                                marginLeft: "10px",
                                height: "50px",
                                width: 'max-content'
                            }}
                        >
                            Level json
                        </Button>


                        {/* <Button
                            variant="contained"
                            component="span"
                            startIcon={<DeleteIcon sx={{color:"white"}} fontSize={'large'} />}
                            onClick={() => {setOpenAllLevelDelete(true)}}
                            style={{
                                backgroundColor: 'red',
                                border: "none",
                                cursor: "pointer",
                                marginLeft: "10px",
                                height: "50px"
                            }}
                        >
                            Delete all zip
                        </Button> */}
                    </div>
                </div>
            </div>
                <div className='row'>
                <ReactSortable  style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(1,1fr)",
                        gridColumnGap: "1rem",
                        marginTop: "25px",
                        gap: "25px",
                    }} list={draggableGroupList} setList={setDraggableGroupList}>

                    {draggableGroupList.map((level, index) => (
                        <div key={level.id}>
                            {/* <Stack  alignItems="left"  mb={5} 
                                sx={{
                                    // background:"linear-gradient(45deg, #2065d13d, #2065d121)",
                                    background: "#e6e8ef91", 
                                    borderRadius: "20px"
                                }}
                            > 
                                <Stack direction="row" alignItems="center" 
                                    sx={{
                                        ml: 3,
                                        height: "130px"
                                    }}
                                >           */}

                            
                            <Stack direction="row" sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}>
                                <div style={{
                                    // width: "700px",
                                    // justifyContent: "space-between",
                                    // marginLeft:"2%"
                                    
                                        width: "1150px",
                                        height: "121px",
                                        background: "#FFFFFF",
                                        boxShadow: "0px 9px 18px rgba(0, 64, 205, 0.06)",
                                        borderRadius: "20px",
                                        display: "flex",
                                        justifyContent: "space-between"
                
                                    }}>

                                <Stack direction="row" sx={{}}>
                                        {/* <MUITextField
                                            id="outlined-basic"
                                            label="Index"
                                            variant="outlined"
                                            value={level.level_no}
                                            sx={{
                                                background: "#F6F6F9",
                                                borderRadius: "7px",
                                                height: "55px",
                                            }}
                                            type="text"
                                            disabled
                                        /> */}

                                        <MUITextField
                                            id="outlined-basic"
                                            label="color1"
                                            variant="outlined"
                                            value={level.color1}
                                            sx={{
                                                background: "#F5F6F9",
                                                borderRadius: "8px",
                                                alignSelf: "center",
                                                height: "55px",
                                                width:"10%",
                                                ml:"1%"
                                            }}
                                            type="text"
                                            disabled
                                        />

                                        <MUITextField
                                            id="outlined-basic"
                                            label="color2"
                                            variant="outlined"
                                            value={level.color2}
                                            sx={{
                                                background: "#F5F6F9",
                                                borderRadius: "8px",
                                                alignSelf: "center",
                                                height: "55px",
                                                width:"10%",
                                                ml:"1%"
                                            }}
                                            type="text"
                                            disabled
                                        />

                                        <MUITextField
                                            id="outlined-basic"
                                            label="color3"
                                            variant="outlined"
                                            value={level.color3}
                                            sx={{
                                                background: "#F5F6F9",
                                                borderRadius: "8px",
                                                alignSelf: "center",
                                                height: "55px",
                                                width:"10%",
                                                ml:"1%"
                                            }}
                                            type="text"
                                            disabled
                                        />

                                        <MUITextField
                                            id="outlined-basic"
                                            label="coins"
                                            variant="outlined"
                                            value={level.coins}
                                            sx={{
                                                background: "#F5F6F9",
                                                borderRadius: "8px",
                                                alignSelf: "center",
                                                height: "55px",
                                                width:"11%",
                                                ml:"1%"
                                            }}
                                            type="text"
                                            disabled
                                        />

                                        <MUITextField
                                            id="outlined-basic"
                                            label="name"
                                            variant="outlined"
                                            value={level.name}
                                            sx={{
                                                background: "#F5F6F9",
                                                borderRadius: "8px",
                                                alignSelf: "center",
                                                height: "55px",
                                                width:"15%",
                                                ml:"1%"
                                            }}
                                            type="text"
                                            disabled
                                        />

                                        <MUITextField
                                            id="outlined-basic"
                                            label="File name"
                                            variant="outlined"
                                            value={level.level_name}
                                            sx={{
                                                background: "#F5F6F9",
                                                borderRadius: "8px",
                                                alignSelf: "center",
                                                height: "55px",
                                                width:"17%",
                                                ml:"1%"
                                            }}
                                            type="text"
                                            disabled
                                        />
                                    {/* </Stack>
                                    </div> */}
                                    <div style={{width: "15%"}}>
                                        <LevelZipUpload
                                            levelName = {level.level_name}
                                            groupId={groupId}
                                            groupIndex={groupIndex}
                                            levelId={level.id}
                                            levelMediaName={level.level_media_name ? level.level_media_name : ""}
                                            levelMediaPath={level.level_media_path ? level.level_media_path : ""}
                                            levelIndex={level.level_no ? level.level_no : ""}
                                            zipSize = {level.zip_size} 
                                        />
                                    </div>
                                    {
                                        (!level.level_media_name) ? ""
                                        :
                                        <div style={{ 
                                            // marginLeft:"100px",
                                            alignSelf: "center"
                                        }}>
                                            <Link to={level.level_media_path} target="_blank" download>
                                                <FileDownloadIcon
                                                    sx={{
                                                        color:"white",
                                                            background: "#C7CBD6",
                                                            border: "1px solid #C7CBD6",
                                                            borderRadius: "4px",
                                                            cursor: 'pointer',
                                                            marginLeft:"1.5%"

                                                    }}
                                                />                                    
                                            </Link>
                                        </div>
                                    }
                                </Stack>
                                </div>
                            </Stack>
                        </div>
                    ))}
                    </ReactSortable>
                </div>
            

       </Container>

    )
}




export function DeleteAllLevelConfirmation(props:any) {
    
    const {
        setOpenAllLevelDelete,
        openAllLevelDelete,
        groupId
    } = props;

    const navigate = useNavigate();

    const getCookieAccessToken:any = useContext(AccessTokenContext);
    let accessToken:string = getCookieAccessToken.cookieAccessToken;

    const handleClose = () => {
        setOpenAllLevelDelete(false);
    };


    const deleteConfirmed = async() => {    
        const res = await tlPostRequest(
            TinyLearnerEndpoints.ALL_LEVEL_ZIP_DELETE,
            {
                "group_id": groupId,
            },
            accessToken
        );
        if (res){
            if(isApiCodeSucess(res)){
                setOpenAllLevelDelete(false);
                navigate(`/tinylearner/group/${groupId}`)
            }
            else{
                setOpenAllLevelDelete(false);
            }
        }
    };

    return (
        <div>
            <Dialog
            open={openAllLevelDelete}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">
                <h4>{"Delete Confirmation"}</h4>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <span>
                        Are you sure you want to delete All zips of this group?
                    </span>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button className='cancel-class' onClick={handleClose}>
                    Cancel
                </button>

                <button className='delete-class' onClick={deleteConfirmed}>
                    Delete
                </button>
                </DialogActions>
            </Dialog>
        
        </div>
    )
}


