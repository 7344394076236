import React, { useContext, useEffect, useState } from 'react'
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material';
import { Field } from 'react-final-form'

import UploadIcon from '../icons/UploadIcon';
import CancelIcon from '@mui/icons-material/Cancel';
import { store } from '../store';
import { HideProgressBar, ShowErrorAlert, ShowSuccessAlert } from '../store/alerts';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Compressor from 'compressorjs';

import {
    S3Client, DeleteObjectCommand, PutObjectCommand,
    // HeadObjectCommand 
} from "@aws-sdk/client-s3";
// import AWS from "aws-sdk";
import { useDispatch } from 'react-redux';
import { isApiCodeSucess, postRequest } from '../data/helpers/services';
import { LEVEL_CREATE_API, MEDIA_DELETE } from '../constants';
import { createLevel } from '../data/mappers/createLevel';
import { AccessTokenContext } from '../routes';

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const REGION = process.env.REACT_APP_S3_REGION;
const accessKeyId = process.env.REACT_APP_S3_ACCESS_ID;
const secretAccessKey = process.env.REACT_APP_S3_ACCESS_KEY;
const BASE_URL = process.env.REACT_APP_S3_BASE_PATH
const BASE_URL_CLONE = process.env.REACT_APP_S3_BASE_PATH_CLONE

// AWS.config.update({
//   accessKeyId: accessKeyId,
//   secretAccessKey: secretAccessKey,
// });

const s3Client = new S3Client({
    credentials: {
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey
    },
    region: REGION
});

// const myBucket = new AWS.S3({
//     params: { Bucket: S3_BUCKET },
//     region: REGION,
// });


export default function SuperHeroLevelImageUploadOnS3(props: any) {
    const {
        levelId,
        character_image,
        set_character_image,
        character_image_name,
        set_character_image_name,
        levelNo,
        scale,
        isNewLevel,
        editableIndex,
        products,
        setProducts,
        uid
    } = props;
    const dispatch = useDispatch();

    const getCookieAccessToken: any = useContext(AccessTokenContext);
    let accessToken: string = getCookieAccessToken.cookieAccessToken;

    const [progress, setProgress] = useState(0);

    const [openDelete, setOpenDelete] = useState<{
        state: boolean,
        index: number
    }>({ state: false, index: 0 })


    const uploadFile = async (file) => {
        new Compressor(file, {
            quality: 0.25, // Compression quality (0.8 = 80% compression)
            success: (compressedFile) => {
                s3UploadFile(compressedFile);
            },
            error: (error) => {
                console.log('Error compressing file:', error);
            },
        });
    };

    const s3UploadFile = async (file) => {

        if (file.name.includes(" ")) {
            dispatch(ShowErrorAlert({
                visible: true,
                message: "Image file name contains spaces kindly remove"
            }));
            return false
        }
        let folder = `World/${file.name}`
        let key = "superhero/" + folder
        if (uid) {
            key = `V3_Clone/${uid}/${folder}`
        }

        const input = {
            ACL: "public-read",
            Body: file,
            Bucket: S3_BUCKET,
            Key: key,
            ContentType: 'image/jpeg',
        }

        // const command = new HeadObjectCommand(input);
        const command = new PutObjectCommand(input);

        try {
            let res = await s3Client.send(command);
            if (res && res['$metadata']['httpStatusCode'] === 200) {
                let uploaded_zip_name = "";
                const lastIndex = folder.lastIndexOf("/");
                const after = folder.slice(lastIndex + 1);
                uploaded_zip_name = after;

                // if ((Math.round((evt.loaded / evt.total) * 100) === 100)){

                var response: any = null;
                const event = {
                    "level_id": `${levelId}`,
                    "media_path_name": `${folder}`,
                    "media_path": `${BASE_URL}${folder}`,
                    "level": levelNo,
                    "scale": scale
                }
                let data = createLevel(
                    event,
                    folder,
                    "edit",
                    isNewLevel,
                    uid
                );

                let version = localStorage.getItem('level_version')

                response = await postRequest(
                    LEVEL_CREATE_API,
                    data,
                    accessToken,
                    (version ? version : "")
                );
                if (response) {
                    dispatch(HideProgressBar());
                    if (isApiCodeSucess(response)) {
                        const resData = response.data;
                    }
                }

                dispatch(ShowSuccessAlert({
                    visible: true,
                    message: `${uploaded_zip_name} Uploaded successfully.`
                }));

                let tempArray = []
                products.forEach(x => {
                    if (x.id === levelId) {
                        x.characterImageName = `${folder}`

                        x.characterImage =
                            uid
                                ?
                                `${BASE_URL_CLONE}${uid}/${folder}`
                                :
                                `${BASE_URL}${folder}`
                    }
                    tempArray.push(x)
                })
                setProducts(tempArray)

                set_character_image(
                    uid
                        ?
                        `${BASE_URL_CLONE}${uid}/${folder}`
                        :
                        `${BASE_URL}${folder}`
                );
                set_character_image_name(folder);
            }
        }
        catch (err) {
            console.log(err)
        }


        // const params = {
        //     ACL: "public-read",
        //     Body: file,
        //     Bucket: S3_BUCKET,
        //     Key: ("superhero/" + folder),
        //     ContentType: 'image/jpeg',
        // };

        // await myBucket
        //     .putObject(params)
        //     .on("httpUploadProgress", async (evt) => {
        //         let uploaded_zip_name = "";
        //         const lastIndex = folder.lastIndexOf("/");
        //         const after = folder.slice(lastIndex + 1);
        //         uploaded_zip_name = after;

        //         if ((Math.round((evt.loaded / evt.total) * 100) === 100)){

        //             var response: any = null;
        //             const event = {
        //                 "level_id": `${levelId}`,
        //                 "media_path_name": `${folder}`,
        //                 "media_path": `${BASE_URL}${folder}`,
        //                 "level": levelNo,
        //                 "scale": scale
        //             }
        //             let data = createLevel(
        //                 event,
        //                 folder,
        //                 "edit",
        //                 isNewLevel
        //             );

        //             let version = localStorage.getItem('level_version')

        //             response = await postRequest(
        //                 LEVEL_CREATE_API,
        //                 data,
        //                 accessToken,
        //                 (version ? version : "")
        //             );
        //             if (response) {
        //                 dispatch(HideProgressBar());
        //                 if (isApiCodeSucess(response)) {
        //                     const resData = response.data;
        //                 }
        //             }

        //             dispatch(ShowSuccessAlert({
        //                 visible:true,
        //                 message: `${uploaded_zip_name} Uploaded successfully.`
        //             }));

        //             let tempArray = []
        //             products.forEach(x => {
        //                 if (x.id === levelId){
        //                     x.characterImageName  = `${folder}`
        //                     x.characterImage =  `${BASE_URL}${folder}`
        //                 }
        //                 tempArray.push(x)
        //             })
        //             setProducts(tempArray)
        //         }
        //         setProgress(Math.round((evt.loaded / evt.total) * 100));

        //         set_character_image(`${BASE_URL}${folder}`);
        //         set_character_image_name(folder);
        //     })
        //     .send((err) => {
        //     if (err) console.log("err",err);
        // });
        return true
    };

    const handleFileSelect = async (event: any) => {
        await uploadFile(event.target.files[0])
        return true;
    };

    const handleFileRemove = async (event: any) => {
        const input = {
            Bucket: S3_BUCKET,
            Key: `superhero/${character_image_name}`
        }
        const command = new DeleteObjectCommand(input);
        await s3Client.send(command);

        const res = await postRequest(
            MEDIA_DELETE,
            {
                "id": levelId,
                "media_type": "SH_LE"   // Tiny learner group
            },
            accessToken
        );

        let deleted_zip_name = "";
        const lastIndex = character_image_name.lastIndexOf("/");
        const after = character_image_name.slice(lastIndex + 1);
        deleted_zip_name = after;

        set_character_image("");
        set_character_image_name("");
        dispatch(ShowSuccessAlert({
            visible: true,
            message: `${deleted_zip_name} Removed successfully.`
        }));
    }

    const deletionProps = {
        setOpenDelete: setOpenDelete,
        openDelete: openDelete,
        levelId: levelId,
        levelNo: levelNo,
        character_image_name: character_image_name,
        set_character_image: set_character_image,
        set_character_image_name: set_character_image_name,
        accessToken: accessToken,
        dispatch: dispatch,
        products: products,
        setProducts: setProducts,
        uid: uid
    }

    return (
        <div className="col-12 md-col-4"
            style={{
                // @ts-ignore
                // textAlign: "-webkit-center",
            }}
        >
            {
                !character_image
                    ?
                    (
                        <Box component="div"
                            sx={{
                                // width: '20%',
                                width: '100px',
                                background: '#232157',
                                marginBottom: "20px",
                                position: 'relative',
                                borderRadius: "15px",
                                cursor: "pointer",
                                aspectRatio: '4/3',
                                marginLeft: '40px'
                            }}
                        >
                            <Box
                                component="div"
                                sx={{
                                    position: 'absolute',
                                    left: '50%',
                                    top: '50%',
                                    transform: 'translate(-50%,-50%)',
                                    width: "60px"
                                }}
                            >
                                <UploadIcon />
                            </Box>

                            <Box
                                component="input"
                                type="file"
                                name="thumbnail"
                                disabled={(editableIndex === levelId) ? false : true}
                                onChange={handleFileSelect}
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    opacity: '0',
                                    cursor: 'pointer',
                                    left: '50%'
                                }}
                            ></Box>
                        </Box>
                    )
                    :
                    <>
                        <DeleteConfirmation
                            {...deletionProps}
                        />
                        <Box className="img--uploadBg" style={{
                            minHeight: '100px',
                            maxHeight: '100px'
                        }}>

                            <div
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    marginTop: "12px"
                                }}

                            >
                                <div className="row">

                                    <img
                                        src={character_image}
                                        style={{
                                            // borderRadius: '50%',
                                            height: '95px',
                                            width: '95px',
                                            marginLeft: "40px"
                                        }}
                                    />

                                    {
                                        (editableIndex === levelId) ?
                                            <svg
                                                viewBox="0 0 24 24"
                                                onClick={() => {
                                                    // fields.remove(index)
                                                    // removeImages(index)
                                                    setOpenDelete({
                                                        state: true,
                                                        index: levelId,
                                                    })
                                                }}
                                                // onClick={handleFileRemove}
                                                style={{
                                                    position: 'absolute',
                                                    top: '0px',
                                                    right: '-35px',
                                                    width: '20px',
                                                    height: '20px',
                                                    cursor: 'pointer'
                                                }}>
                                                <path
                                                    fill="#FF0000"
                                                    d="M12,0C5.373,0,0,5.373,0,12s5.373,12,12,12s12-5.373,12-12S18.627,0,12,0z M16.293,15.707l-1.414,1.414L12,13.414l-2.879,2.879l-1.414-1.414L10.586,12L7.707,9.121l1.414-1.414L12,10.586l2.879-2.879l1.414,1.414L13.414,12L16.293,15.707z"
                                                />
                                            </svg>
                                            : ""
                                    }
                                </div>

                            </div>
                        </Box>
                    </>
            }
        </div>
    )
}


export function DeleteConfirmation(props: any) {

    const {
        setOpenDelete,
        openDelete,
        levelId,
        levelNo,
        character_image_name,
        set_character_image,
        set_character_image_name,
        accessToken,
        dispatch,
        products,
        setProducts,
        uid
    } = props;
    const handleClose = () => {
        setOpenDelete({ state: false, index: 0 });
    };

    let splitted_image_name = "";
    const lastIndex = character_image_name.lastIndexOf("/");
    const after = character_image_name.slice(lastIndex + 1);
    splitted_image_name = after;

    const deleteConfirmed = async () => {
        //  Remove S3 deleteion directly because of error in realtime app user case

        // const input = {
        //     Bucket: S3_BUCKET,
        //     Key: `superhero/${character_image_name}`
        // }
        // const command = new DeleteObjectCommand(input);
        // await s3Client.send(command);

        // const res = await postRequest(
        //     MEDIA_DELETE,
        //     {
        //         "id": levelId,
        //         "media_type": "SH_LE"   // Tiny learner group
        //     },
        //     accessToken
        // );

        set_character_image("");
        set_character_image_name("");

        let tempArray = []
        products.forEach(x => {
            if (x.id === levelId) {
                x.characterImageName = ""
                x.characterImage = ""
            }
            tempArray.push(x)
        })
        setProducts(tempArray)

        // dispatch(ShowSuccessAlert({
        //     visible:true,
        //     message: `${splitted_image_name} Removed successfully.`
        // }));

        setOpenDelete({ state: false, index: 0 });
    };
    return (
        <div>
            <Dialog
                open={openDelete['state']}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <h4>{"Delete Confirmation"}</h4>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <span>
                            Are you sure you want to delete image
                            <strong> {splitted_image_name} </strong>
                            from index <strong>{levelNo}</strong> ?
                        </span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button className='cancel-class' onClick={handleClose}>
                        Cancel
                    </button>

                    <button className='delete-class' onClick={deleteConfirmed}>
                        Delete
                    </button>
                </DialogActions>
            </Dialog>

        </div>
    )
}

