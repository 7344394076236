export const createLevel = (data: any, imagePath:string, modeType:string, newLevel:boolean, uid?: string) => {
    let imageS3Path = ""
    if (imagePath){
        imageS3Path = `${process.env.REACT_APP_S3_BASE_PATH}${imagePath}`
        if (uid){
            imageS3Path = `${process.env.REACT_APP_S3_BASE_PATH_CLONE}${uid}/${imagePath}`
        }
    } 
    
    let characterPoints:any = {
        "level_no": data.level,
        "character_image_name": imagePath,
        "character_image_path": imageS3Path,
        "is_new_level": newLevel,
        "scaling": data.scale,
        "uid": uid
    }

    if(modeType === "edit") {
        characterPoints.level_id = data.level_id;
    }

    return characterPoints;
}


export const levelIndexUpdate = (data: any, uid?:string) => {
    let characterPoints:any = {
        "level_data": data,
        "uid":uid
    }
    return characterPoints;
}