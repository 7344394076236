import React,{ useContext, useState } from 'react'
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material';

import UploadIcon from '../icons/UploadIcon';
import { store } from '../store';
import { HideProgressBar, ShowErrorAlert, ShowSuccessAlert } from '../store/alerts';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';

import { S3Client, DeleteObjectCommand, PutObjectCommand
    // HeadObjectCommand 
} from "@aws-sdk/client-s3";
// import AWS from "aws-sdk";
import LevelZipLogo from '../pages/tinyLearner/LevelZipLogo';
import { AccessTokenContext } from '../routes';
import { MEDIA_DELETE, TinyLearnerEndpoints } from '../constants';
import { isApiCodeSucess, postRequest, tlPostRequest } from '../data/helpers/services';
import { Link, useNavigate } from 'react-router-dom';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import UploadIcon1 from '../static/UploadIcon1';

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const REGION = process.env.REACT_APP_S3_REGION;
const accessKeyId = process.env.REACT_APP_S3_ACCESS_ID;
const secretAccessKey = process.env.REACT_APP_S3_ACCESS_KEY;
const BASE_URL = process.env.REACT_APP_S3_BASE_PATH_TL;

// AWS.config.update({
//   accessKeyId: accessKeyId,
//   secretAccessKey: secretAccessKey,
// });

const s3Client = new S3Client({
    credentials: {
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey
    },
    region: REGION
});

// const myBucket = new AWS.S3({
//     params: { Bucket: S3_BUCKET },
//     region: REGION,
//   });


export default function LevelZipUpload(props:any) {
    let {
        levelName,
        groupId,
        groupIndex,
        levelId,
        levelMediaName,
        levelMediaPath,
        levelIndex,
        zipSize
    } = props;


    if (zipSize) {
        if (Number(zipSize) > 1024){
            zipSize = `${(zipSize/1024).toFixed(2)} MB`;
        }
        else{
            zipSize = `${zipSize} KB`
        }
    }

    const navigate = useNavigate();
    const getCookieAccessToken:any = useContext(AccessTokenContext);
    let accessToken:string = getCookieAccessToken.cookieAccessToken;

    const [progress, setProgress] = useState(0);

    const [openDelete, setOpenDelete] = useState({state: false,index: ""});
    
    let zip_name = "";
    if (levelMediaName) {
        const lastIndex = levelMediaName.lastIndexOf("/");
        const after = levelMediaName.slice(lastIndex + 1);
        zip_name = after;
    }

    const [levelMedia, setLevelMedia] = useState(zip_name);
    
    const [levelS3MediaName, setLevelS3MediaName] = useState(levelMediaName);

    const { dispatch } = store;

    const uploadFile = async (file) => {
        var filePath = file.name;
        var allowedExtensions = /(\.zip)$/i;        
        if(!allowedExtensions.exec(filePath)){
            dispatch(
                ShowErrorAlert({
                    visible:true,
                    message: "Please upload file having extensions .zip only."
                })
            )
            return false
        }
    

        if (file.name.includes(" ")){
            dispatch(
                ShowErrorAlert({
                    visible:true,
                    message: "Zip file name contains spaces kindly remove"
                })
            );
            return false
        }
        let folder = `group_${groupIndex}/levels/${file.name}`

        const input = {
            ACL: "public-read",
            Body: file,
            Bucket: S3_BUCKET,
            Key: ("tiny_learner/group/"+folder),
            ContentType: 'application/zip',
        }

        // const command = new HeadObjectCommand(input);
        const command = new PutObjectCommand(input);

        try{
            let res = await s3Client.send(command);
            if (res && res['$metadata']['httpStatusCode'] === 200){
                let uploaded_zip_name = "";
                const lastIndex = folder.lastIndexOf("/");
                const after = folder.slice(lastIndex + 1);
                uploaded_zip_name = after;
            
                // if ((Math.round((evt.loaded / evt.total) * 100) === 100)){

                    var response: any = null;
                    const data = {
                        "level_id": `${levelId}`,
                        "media_path_name": `${folder}`,
                        "media_path": `${BASE_URL}${folder}`
                    }
                    response = await tlPostRequest(
                        TinyLearnerEndpoints.GROUP_ADD_LEVEL_MEDIA,
                        data,
                        accessToken
                    );
                    if (response) {
                        dispatch(HideProgressBar());
                        if (isApiCodeSucess(response)) {
                            const resData = response.data;
                            // dispatch(ShowSuccessAlert({visible:true, message:resData.message}));
                            const navigate_path = '/tinylearner/group/' + groupId + "/";
                            // navigate(navigate_path)
                        }
                    }

                    dispatch(ShowSuccessAlert({
                        visible:true,
                        message: `${uploaded_zip_name} Uploaded successfully.`
                    }));
                // }
                // setProgress(Math.round((evt.loaded / evt.total) * 100));

                setLevelS3MediaName(folder);
                setLevelMedia(uploaded_zip_name);
            }
        }
        catch(err){
            console.log(err)
        }
  
        // const params = {
        //     ACL: "public-read",
        //     Body: file,
        //     Bucket: S3_BUCKET,
        //     Key: ("tiny_learner/group/"+folder),
        //     ContentType: 'application/zip',
        // };
        // await myBucket
        //     .putObject(params)
        //     .on("httpUploadProgress", async (evt) => {
        //         let uploaded_zip_name = "";
        //         const lastIndex = folder.lastIndexOf("/");
        //         const after = folder.slice(lastIndex + 1);
        //         uploaded_zip_name = after;
            
        //         if ((Math.round((evt.loaded / evt.total) * 100) === 100)){

        //             var response: any = null;
        //             const data = {
        //                 "level_id": `${levelId}`,
        //                 "media_path_name": `${folder}`,
        //                 "media_path": `${BASE_URL}${folder}`
        //             }
        //             response = await tlPostRequest(
        //                 TinyLearnerEndpoints.GROUP_ADD_LEVEL_MEDIA,
        //                 data,
        //                 accessToken
        //             );
        //             if (response) {
        //                 dispatch(HideProgressBar());
        //                 if (isApiCodeSucess(response)) {
        //                     const resData = response.data;
        //                     // dispatch(ShowSuccessAlert({visible:true, message:resData.message}));
        //                     const navigate_path = '/tinylearner/group/' + groupId + "/";
        //                     // navigate(navigate_path)
        //                 }
        //             }

        //             dispatch(ShowSuccessAlert({
        //                 visible:true,
        //                 message: `${uploaded_zip_name} Uploaded successfully.`
        //             }));
        //         }
        //         setProgress(Math.round((evt.loaded / evt.total) * 100));

        //         setLevelS3MediaName(folder);
        //         setLevelMedia(uploaded_zip_name);
        //     })
        //     .send((err) => {
        //         if (err) console.log("err",err);
        //     });
        return true
    };

    const handleFileSelect = async (event: any) => {
        if (levelName !== event.target.files[0].name.split(".zip")[0])
            dispatch(
                ShowErrorAlert({
                    visible:true,
                    message: "Zip file name mismatched with allocated file name"
                })
            );
        else{
            await uploadFile(event.target.files[0])
        }

        return true;
    };

    const handleFileRemove = async (event: any) => {
        const input = {
          Bucket: S3_BUCKET,
          Key: `tiny_learner/group/${levelS3MediaName}`
        }

        let deleted_zip_name = "";
        const lastIndex = levelS3MediaName.lastIndexOf("/");
        const after = levelS3MediaName.slice(lastIndex + 1);
        deleted_zip_name = after;

        const command = new DeleteObjectCommand(input);
        await s3Client.send(command);

        const res = await postRequest(
            MEDIA_DELETE,
            {
                "id": levelId,
                "media_type": "TL_GL"   // Tiny learner group
            },
            accessToken
        );
        setLevelS3MediaName("");
        setLevelMedia("");
        dispatch(ShowSuccessAlert({
            visible:true,
            message: `${deleted_zip_name} Removed successfully.`
        }));
    }

    const deletionProps = {
        setOpenDelete: setOpenDelete,
        openDelete: openDelete,
        levelS3MediaName: levelS3MediaName,
        levelId: levelId,
        setLevelS3MediaName: setLevelS3MediaName,
        setLevelMedia: setLevelMedia
    }

    console.log("zipSize",zipSize)

    let mediaUrlDownload = `${BASE_URL}${levelS3MediaName}`

    return (
        <>
         <DeleteConfirmation
            {...deletionProps}
        />

            <div className="col-12 md-col-4" 
                style={{
                    width:"100%",
                    marginTop: "-2px",
                    // @ts-ignore
                    textAlign: "-webkit-center",
                }}
            >
                {(!levelMedia)
                    ?
                    (
                        <Box component="div"
                            sx={{
                                width: '100px',
                                marginLeft: "25%",
                                position: 'relative',
                                borderRadius: "15px",
                                cursor: "pointer",
                                aspectRatio: '4/3'
                            }}
                        >
                            <Box
                                component="div"
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    width: "60px"
                                }}
                            >
                                <UploadIcon1 />
                            </Box>
                            
                            <Box
                                component="input"
                                type="file"
                                name="thumbnail"
                                onChange={handleFileSelect}
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    opacity: '0',
                                    cursor: 'pointer',
                                    left: '50%'
                                }}
                            ></Box>
                        </Box>
                    )
                    :
                    (
                        <>
                            <Box className="img--uploadBg" style={{
                                minHeight: '75px',
                                maxHeight: '75px'
                            }}>
                                <div
                                    style={{
                                        position: 'relative',
                                        display: 'inline-block',
                                        marginTop: "7%", 
                                    }}
                                >  
                                    <LevelZipLogo
                                        username={"username"}
                                        logoText={levelIndex}
                                    />
                                    <svg
                                        viewBox="0 0 24 24"
                                        onClick={() => {
                                            setOpenDelete({
                                                state: true,
                                                index: levelId,
                                            })
                                        }}
                                        style={{
                                            position: 'absolute',
                                            top: '-5px',
                                            right: '-13px',
                                            width: '20px',
                                            height: '20px',
                                            cursor: 'pointer'
                                        }}>
                                        <path
                                            fill="#FF0000"
                                            d="M12,0C5.373,0,0,5.373,0,12s5.373,12,12,12s12-5.373,12-12S18.627,0,12,0z M16.293,15.707l-1.414,1.414L12,13.414l-2.879,2.879l-1.414-1.414L10.586,12L7.707,9.121l1.414-1.414L12,10.586l2.879-2.879l1.414,1.414L13.414,12L16.293,15.707z"
                                        />
                                    </svg>
                                </div>
                            </Box>
                        </>
                    )
                }

                <label className='input--label' 
                htmlFor="thumbnail">
                    {
                        levelMedia
                        ?
                        <>
                        <span style={{
                            display: "inline-block",
                            width: "100%"
                        }}>
                            {levelMedia}
                            </span>
                            <span style={{
                                whiteSpace: "nowrap",
                            }}>
                                {zipSize}
                            </span>
                        </>
                        :
                        <span>Upload File</span>
                    }
                </label>
            </div>
        </>
    )
}


export function DeleteConfirmation(props:any) {
    
    const {
        setOpenDelete,
        openDelete,
        levelS3MediaName,
        levelId,
        setLevelS3MediaName,
        setLevelMedia

    } = props;

    const getCookieAccessToken:any = useContext(AccessTokenContext);
    let accessToken:string = getCookieAccessToken.cookieAccessToken;

    const handleClose = () => {
        setOpenDelete({state: false, index: 0});
    };

    let splitted_image_name = "";
    const lastIndex = levelS3MediaName.lastIndexOf("/");
    const after = levelS3MediaName.slice(lastIndex + 1);
    splitted_image_name = after;

    const deleteConfirmed = async() => {
        // const input = {
        //     Bucket: S3_BUCKET,
        //     Key: `superhero/${levelS3MediaName}`
        // }
        // const command = new DeleteObjectCommand(input);
        // await s3Client.send(command);
    
        const res = await postRequest(
            MEDIA_DELETE,
            {
                "id": levelId,
                "media_type": "TL_GL"   // Tiny learner group
            },
            accessToken
        );
        setLevelS3MediaName("");
        setLevelMedia("");
        setOpenDelete({state: false, index: 0});
    };
    return (
        <div>
            <Dialog
            open={openDelete['state']}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">
                <h4>{"Delete Confirmation"}</h4>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <span>
                        Are you sure you want to delete this zip
                        <strong> {splitted_image_name} </strong> ?
                    </span>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button className='cancel-class' onClick={handleClose}>
                    Cancel
                </button>

                <button className='delete-class' onClick={deleteConfirmed}>
                    Delete
                </button>
                </DialogActions>
            </Dialog>
        
        </div>
    )
}

