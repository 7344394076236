import PropTypes from 'prop-types';
// @mui
import { Grid, Snackbar } from '@mui/material';
import GroupCard from './GroupCard';
import { ReactSortable } from "react-sortablejs";
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

GroupList.propTypes = {
  groups: PropTypes.array.isRequired,
};

export default function GroupList({ groups, setGroupList, setUpdateValues, draggableGroupList, setDraggableGroupList, ...other }) {
  const [open, setOpen] = useState(false)

  const [editableIndex, setEditableIndex] = useState("-1");

  useEffect(() => {
    setDraggableGroupList(groups)
  }, [groups])

  function getChangedValues(arr1, arr2) {
    var changedValues = [];

    // Loop through arr1 and compare each element with the corresponding element in arr2
    for (var i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        changedValues.push(arr2[i]); // If elements are not equal, push the value from arr2 into changedValues array
      }
    }
    return changedValues;
  }

  useEffect(() => {
    let updatedValues = getChangedValues(groups, draggableGroupList)
    if (updatedValues.length > 0) {
      setUpdateValues(updatedValues)
    } else {
      setUpdateValues([])
    }
  }, [draggableGroupList])

  return (
    <>
      <ReactSortable style={{
        display: "grid",
        gap: "25px",
        gridTemplateColumns: "repeat(1, 1fr)",
        overflow: "auto"
      }} list={draggableGroupList} setList={setDraggableGroupList}>

        {draggableGroupList.map((group) => (
          <Grid
            key={group.id} item xs={12} sm={6} md={3}>
            <GroupCard
              group={group}
              editableIndex={editableIndex}
              setEditableIndex={setEditableIndex}
              groups={groups}
              setGroupList={setGroupList}
            />
          </Grid>
        ))}
      </ReactSortable>
    </>
  );
}

