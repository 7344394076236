
import React from 'react'

export default function DeleteIcon() {
  return (
    <div>
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="22"
      fill="none"
      viewBox="0 0 18 22"
    >
      <path
        fill="#C7CBD6"
        d="M0 4.825c0-.533.345-.965.771-.965h2.665c.529-.018.996-.439 1.176-1.062l.03-.11.115-.43c.07-.264.131-.495.217-.7C5.312.745 5.938.18 6.661.036 6.845 0 7.039 0 7.261 0h3.478c.223 0 .417 0 .6.036.723.144 1.35.709 1.687 1.522.086.205.147.435.218.7l.114.43.03.11c.18.623.74 1.045 1.27 1.062h2.57c.427 0 .772.432.772.965 0 .532-.345.964-.771.964H.77c-.425 0-.77-.432-.77-.964z"
      ></path>
      <path
        fill="#C7CBD6"
        fillRule="evenodd"
        d="M8.596 22h.808c2.783 0 4.174 0 5.08-.975.904-.974.996-2.573 1.181-5.77l.267-4.606c.1-1.735.15-2.603-.303-3.151-.454-.55-1.22-.55-2.753-.55H5.124c-1.533 0-2.3 0-2.753.55-.454.55-.404 1.416-.303 3.151l.267 4.607c.185 3.196.277 4.796 1.182 5.77.905.974 2.296.974 5.08.974zm-1.35-10.792c-.04-.478-.408-.825-.82-.778-.413.047-.713.473-.672.95l.5 5.79c.04.477.408.825.82.777.413-.047.713-.473.672-.95l-.5-5.79zm4.33-.778c.411.047.712.473.67.95l-.5 5.79c-.04.477-.409.825-.82.777-.413-.047-.713-.473-.672-.95l.5-5.79c.04-.477.41-.824.821-.777z"
        clipRule="evenodd"
      ></path>
    </svg>

    </div>
  )
}
