import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Button, Drawer, Stack } from '@mui/material';
// mock
import { useNavigate } from 'react-router-dom';

// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
import ViewSidebarOutlinedIcon from '@mui/icons-material/ViewSidebarOutlined';
import Cookies from 'js-cookie';
import SideBarNav from './SideBarNav';
import SideRibbon from '../../../components/SideRibbon';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../../store';
import { LOGOUT_API } from '../../../constants';
import { isApiCodeSucess, postRequest } from '../../../data/helpers/services';
import { AccessTokenContext } from '../../../routes';
import { ResetUserInfo } from '../../../store/Projects';
import PasswordModal from '../../../components/PasswordModal';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {

  const getCookieAccessToken: any = useContext(AccessTokenContext)
  let accessToken: string = getCookieAccessToken.cookieAccessToken

  let projectsListObject = useSelector((state: RootStateType) => state.projects);

  let uid = projectsListObject?.selectedProject?.uid || "";

  const [openPasswordModal, setOpenPasswordModal] = useState(false);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isDesktop = useResponsive('up', 'lg', '');
  useEffect(() => {
    // if (openNav) {
    //   onCloseNav();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const toggleDrawer = () => {
    onCloseNav();
  }

  const logout = async () => {
    let res: any = {}

    res = await postRequest(
      LOGOUT_API,
      {},
      accessToken,
      ""
    )
    if (isApiCodeSucess(res)) {
      Cookies.remove('access_token');
      navigate('/login', { replace: true });
    }

  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>

        <img src="/assets/logoSkycap.svg"
          style={{
            width: "119px",
          }} />

        <span style={{ color: '#2065D1', marginLeft: "10px", marginTop: "-5px", fontWeight: 600 }}>
          {process.env.REACT_APP_ENV}
        </span>
      </Box>

      {/* <NavSection data={navConfig} /> */}

      <SideBarNav />


      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          {/* <Box
            component="img"
            src="/assets/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          /> */}
          <Box>
            {
              (uid && (uid !== ""))
                ?
                <SideRibbon />
                :
                null
            }
          </Box>

          <span style={{
            color: "#2065D1",
            fontWeight: "500",
            marginTop: "10px",
            cursor: "pointer"
          }}
            onClick={() => setOpenPasswordModal(true)}
          >
            Edit Password
          </span>

          <PasswordModal openPasswordModal={openPasswordModal} setOpenPasswordModal={setOpenPasswordModal} />
          <Button sx={{
            marginTop: "10px !important",
          }} onClick={logout} variant="contained">
            Logout
          </Button>
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH, xs: "5%" },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <>
          <Button onClick={() => toggleDrawer()}>
            <img src={'/assets/sidebar.svg'} style={{ position: "fixed", marginLeft: "-6%", width: "25px", marginTop: "5vh", height: "30px" }} alt="" />
          </Button>
          <Drawer
            open={openNav}
            onClose={onCloseNav}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              sx: { width: NAV_WIDTH },
            }}
          >
            {renderContent}
          </Drawer>
        </>
      )}
    </Box>
  );
}
