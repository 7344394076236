import { Button, Stack } from '@mui/material';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { Form, Field } from 'react-final-form'
import { AppVersionApis } from '../../constants';
import { isApiCodeSucess, tlPostRequest } from '../../data/helpers/services';
import { AccessTokenContext } from '../../routes';
import { TextField } from 'mui-rff';
import { useDispatch } from 'react-redux';
import { HideProgressBar, ShowProgressBar, ShowSuccessAlert } from '../../store/alerts';


const initialFormData = {
  tl_android_version: "1",
  tl_ios_version: "1"
}

export default function TlAppVersion() {
  const dispatch = useDispatch();
  
  const getCookieAccessToken:any = useContext(AccessTokenContext)
  let accessToken:string = getCookieAccessToken.cookieAccessToken

  
  const [initialForm, setInitialForm] = useState(initialFormData);



  useEffect(()=>{
    getAppVersion()
  },[])

  const getAppVersion = async() =>{
      let res:any = {}
  
      res = await tlPostRequest(
          AppVersionApis.TL_APP_VERSION_LIST, "" , accessToken);
      if (res) {
          if (isApiCodeSucess(res)) {
              let responseData = res.data.data
              setInitialForm({
                tl_android_version: responseData.android_version,
                tl_ios_version: responseData.ios_version
              })
          }
      }
  }

  const handleSubmit = async(e) => {
    dispatch(ShowProgressBar());
    let data = {
        android_version: e.tl_android_version,
        ios_version: e.tl_ios_version
      };

    var response: any = null;

    response = await tlPostRequest(AppVersionApis.TL_APP_VERSION_UPDATE, data, accessToken);

    if (response) {
        dispatch(HideProgressBar());
        if (isApiCodeSucess(response)) {
            const resData = response.data
            dispatch(ShowSuccessAlert({visible:true, message:resData.message}));
        }
    }
  }

  return (
    <>
    <PageStyles />
    <div className="app-version">      
      <div style={{marginLeft:"30%", fontWeight:700 }}>
        Tiny Learner App Versions
      </div>
      <Form
        initialValues={{...initialForm}}
        onSubmit={handleSubmit}>
            {
            (props:any) => (
                <form onSubmit={props.handleSubmit}>
                  <Stack direction="row">
                    <TextField
                        label="Android version" name={`tl_android_version`} 
                        sx={{
                            width:"25%",
                            mt:"4%",
                            mr: "5%",
                            ml: "5%"
                        }} type="number" 
                        required={true}
                    />
                    <TextField 
                        label="ios version" name={`tl_ios_version`} 
                        sx={{
                            width:"25%",
                            mt:"4%"
                        }}
                        type="number" 
                        required={true}
                    />
  
                    <div className="button-section"
                      style={{
                          width:"15%",
                          marginLeft: "7%",
                          marginTop:"4.5%"
                      }}
                    >
                      <Button type="submit" variant="contained">
                          Save
                      </Button>
                    </div>
                  </Stack>
                </form>
              )
            }
      </Form>
    </div>
    </>
  )
}



export function PageStyles() {
  return (
    <>
        <style>
            {`
              .app-version {
                margin-left:10px;
                width:950px;
              }

              .app-version-form {
                border-bottom: 1px dotted #ccc !important;
                padding-bottom: 30px;
              }

              .Bg-toggles {
                margin-top: 50px;
                margin-left: 65px
              }
            `}
        </style>
    </>
  )
}