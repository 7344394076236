import React, { Suspense, createContext } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Cookies from 'js-cookie';

import ResetPasswordForm from './sections/auth/login/ResetPasswordForm';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
// import LevelPage from './pages/LevelPage';
import ProductsPage from './pages/ProductsPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import LevelPositions from './pages/LevelPositions';
// import { AccessTokenContext } from './App';
import LevelForm from './pages/LevelForm';
import GroupsPage from './pages/tinyLearner/GroupsPage';
import GroupForm from './pages/tinyLearner/GroupForm';
import GroupDetail from './pages/tinyLearner/GroupDetail';
import BaseAppVersion from './pages/AppVersion/Base';



const ResetPasswordFormLazy = React.lazy(() => import('./sections/auth/login/ResetPasswordForm'));
const DashboardLayoutLazy = React.lazy(() => import('./layouts/dashboard'));
const SimpleLayoutLazy = React.lazy(() => import('./layouts/simple'));
const ProductsPageLazy = React.lazy(() => import('./pages/ProductsPage'));
const LoginPageLazy = React.lazy(() => import('./pages/LoginPage'));
const Page404Lazy = React.lazy(() => import('./pages/Page404'));
const LevelPositionsLazy = React.lazy(() => import('./pages/LevelPositions'));
const LevelFormLazy = React.lazy(() => import('./pages/LevelForm'));
const GroupsPageLazy = React.lazy(() => import('./pages/tinyLearner/GroupsPage'));
const GroupFormLazy = React.lazy(() => import('./pages/tinyLearner/GroupForm'));
const GroupDetailLazy = React.lazy(() => import('./pages/tinyLearner/GroupDetail'));
const BaseAppVersionLazy = React.lazy(() => import('./pages/AppVersion/Base'));


export const AccessTokenContext = createContext({});
// ----------------------------------------------------------------------

export default function Router() {
  let cookieAccessToken = Cookies.get('access_token');
  let cookieRefreshToken = Cookies.get('refresh_token');

  const isPWA = window.matchMedia('(display-mode: standalone)').matches;
  const PrivateRoute = ({ children, ...rest }: any) => {
    const accessToken = cookieAccessToken
    // debugger; // eslint-disable-line no-debugger

    if (!localStorage.getItem('level_version')) {
      localStorage.setItem('level_version', 'v3');
    }

    if ((!accessToken) || accessToken === "null") {
      return <Navigate to={{ pathname: "/login" }} />
    }
    return children;
  }

  const IsLoggedIn = ({ children, ...rest }: any) => {
    // const token:any = useContext(AccessTokenContext);
    let accessToken = cookieAccessToken
    // debugger; // eslint-disable-line no-debugger

    if (
      accessToken
      &&
      typeof accessToken !== "undefined"
      &&
      accessToken !== "null"
      &&
      accessToken?.length > 0
    ) {
      window.history.replaceState(null, '', '/dashboard/super_hero/v3/level/');
      return <Navigate to={{ pathname: "/dashboard/super_hero/v3/level/" }} />
    }
    return children;
  }

  const routes = useRoutes([
    {
      path: '/dashboard',
      element:
        <PrivateRoute>
          {isPWA ?
            <DashboardLayout />
            :
            <Suspense fallback={<div>Loading...</div>}>
              <DashboardLayoutLazy />
            </Suspense>
          }
        </PrivateRoute>
      ,
      children: [
        { element: <Navigate to="/dashboard/level/list/:version" />, index: true },
        {
          path: 'level/list/:version', element:
            <PrivateRoute>
              {isPWA ?
                <ProductsPage />
                :
                <Suspense fallback={<div>Loading...</div>}>
                  <ProductsPageLazy />
                </Suspense>
              }
            </PrivateRoute>

        },
        {
          path: 'level/create', element:
            <PrivateRoute>
              {isPWA ?
                <LevelForm modeType={'add'} />
                :
                <Suspense fallback={<div>Loading...</div>}>
                  <LevelFormLazy modeType={'add'} />
                </Suspense>
              }
            </PrivateRoute>
        },

        {
          path: 'level/edit/:levelId', element:
            <PrivateRoute>
              {
                isPWA ?
                  <LevelForm modeType={'edit'} />
                  :
                  <Suspense fallback={<div>Loading...</div>}>
                    <LevelFormLazy modeType={'edit'} />
                  </Suspense>
              }
            </PrivateRoute>
        },

        {
          path: 'level/:levelId', element:
            <PrivateRoute>
              {
                isPWA ?
                  <LevelPositions />
                  :
                  <Suspense fallback={<div>Loading...</div>}>
                    <LevelPositionsLazy />
                  </Suspense>
              }
            </PrivateRoute>
        },

        {
          path: ':uid/:version/level/', element:
            <PrivateRoute>
              {
                isPWA ?
                  <ProductsPage />
                  :
                  <Suspense fallback={<div>Loading...</div>}>
                    <ProductsPageLazy />
                  </Suspense>
              }
            </PrivateRoute>

        },
        {
          path: ':uid/level/create', element:
            <PrivateRoute>
              {
                isPWA ?
                  <LevelForm modeType={'add'} />
                  :
                  <Suspense fallback={<div>Loading...</div>}>
                    <LevelFormLazy modeType={'add'} />
                  </Suspense>
              }
            </PrivateRoute>
        },

        {
          path: ':uid/level/edit/:levelId', element:
            <PrivateRoute>
              {
                isPWA ?
                  <LevelForm modeType={'edit'} />
                  :
                  <Suspense fallback={<div>Loading...</div>}>
                    <LevelFormLazy modeType={'edit'} />
                  </Suspense>
              }
            </PrivateRoute>
        },

        {
          path: ':uid/level/:levelId', element:
            <PrivateRoute>
              {
                isPWA ?
                  <LevelPositions />
                  :
                  <Suspense fallback={<div>Loading...</div>}>
                    <LevelPositionsLazy />
                  </Suspense>
              }
            </PrivateRoute>
        },

      ],
    },

    {
      path: '/tinylearner',
      element:
        <PrivateRoute>
          {
            isPWA ?
              <DashboardLayout />
              :
              <Suspense fallback={<div>Loading...</div>}>
                <DashboardLayoutLazy />
              </Suspense>
          }
        </PrivateRoute>,
      children: [
        { element: <Navigate to="/tinylearner/group" />, index: true },
        {
          path: 'group', element:
            <PrivateRoute>
              {isPWA ?
                <GroupsPage />
                :
                <Suspense fallback={<div>Loading...</div>}>
                  <GroupsPageLazy />
                </Suspense>
              }
            </PrivateRoute>
        },
        {
          path: 'group/create', element:
            <PrivateRoute>
              {
                isPWA ?
                  <GroupForm modeType={'add'} />
                  :
                  <Suspense fallback={<div>Loading...</div>}>
                    <GroupFormLazy modeType={'add'} />
                  </Suspense>
              }
            </PrivateRoute>
        },
        {
          path: 'group/edit/:groupId', element:
            <Suspense fallback={<div>Loading...</div>}>
              <PrivateRoute>
                <GroupForm modeType={'edit'} />
              </PrivateRoute>
            </Suspense>
        },
        {
          path: 'group/:groupId', element:
            <PrivateRoute>
              {
                isPWA ?
                  <GroupDetail />
                  :
                  <Suspense fallback={<div>Loading...</div>}>
                    <GroupDetailLazy />
                  </Suspense>
              }
            </PrivateRoute>
        },
      ],
    },


    {
      path: '/appVersion',
      element:
        <PrivateRoute>
          {
            isPWA ?
              <DashboardLayout />
              :
              <Suspense fallback={<div>Loading...</div>}>
                <DashboardLayoutLazy />
              </Suspense>
          }
        </PrivateRoute>,
      children: [
        // { element: <Navigate to="/tinylearner/group" />, index: true },
        {
          path: '', element:
            <PrivateRoute>
              {isPWA ?
                <BaseAppVersion />
                :
                <Suspense fallback={<div>Loading...</div>}>
                  <BaseAppVersionLazy />
                </Suspense>
              }
            </PrivateRoute>
        },
        // { path: 'group/create', element:
        //   <PrivateRoute>
        //     <GroupForm modeType={'add'}/>
        //   </PrivateRoute>
        // },
        // { path: 'group/edit/:groupId', element:
        //   <PrivateRoute>
        //     <GroupForm modeType={'edit'}/>
        //   </PrivateRoute>
        // },
        // { path: 'group/:groupId', element:
        //   <PrivateRoute>
        //     <GroupDetail />
        //   </PrivateRoute>
        // },
      ],
    },

    {
      path: '/login',
      element:
        <IsLoggedIn>
          {
            isPWA ?
              <LoginPage />
              :
              <Suspense fallback={<div>Loading...</div>}>
                <LoginPageLazy />
              </Suspense>
          }
        </IsLoggedIn>
    },


    {
      path: '/account/activate/:uuid',
      element:
        <>
          {
            isPWA ?
              <ResetPasswordForm />
              :
              < Suspense fallback={< div > Loading...</div>}>
                <ResetPasswordFormLazy />
              </Suspense >
          }
        </>
    },


    {
      element:
        <>
          {
            isPWA ?
              <SimpleLayout />
              :
              <Suspense fallback={<div>Loading...</div>}>
                <SimpleLayoutLazy />
              </Suspense>
          }
        </>,
      children: [
        { element: <Navigate to="/dashboard/super_hero/v3/level/" replace />, index: true },
        {
          path: '404', element:
            <>
              {
                isPWA ?
                  <Page404 />

                  :
                  <Suspense fallback={<div>Loading...</div>}>
                    <Page404Lazy />
                  </Suspense>
              }
            </>
        },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" />,
    },
  ]);

  return (
    <>
      <AccessTokenContext.Provider value={{ cookieAccessToken, cookieRefreshToken }}>
        {routes}
      </AccessTokenContext.Provider>
    </>
  );
}
