

import React from 'react'

export default function CheckMark() {
  return (
    <div>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="15"
            fill="none"
            viewBox="0 0 20 15"
            >
            <path
                fill="#0D9E1C"
                d="M6.28 12.101l-4.34-4.34A1.144 1.144 0 00.32 9.378l5.15 5.15a1.144 1.144 0 001.619 0l12.589-12.59A1.144 1.144 0 0018.06.322L6.28 12.101z"
            ></path>
        </svg>
    </div>
  )
}
