export const ADMIN_LOGIN = "signin/";

export const UPDATE_RESET_PASSWORD = "forgot/password/update/"
export const SEND_RESET_PASSWORD = "forgot/password/"

export const LEVELS = "level/list/"
export const LEVEL_CREATE_API = "level/create_edit/"
export const LEVEL_DETAIL_API = "level/detail/"
export const LEVEL_INDEX_UPDATE_API = "level/index/update/"
export const UPDATE_LEVELS_AVIALABLE = "update_availability/"
export const SuperHeroEndpoints = {
    ACTIVE_INACTIVE_LEVEL: "level/active_inactive/",
    BG_DOWNLOAD_TOGGLE:"level/bg_download_toggle/",

    LEVEL_DELETE: 'level/delete/',
    Update_Level_Selection: 'select_levels/'
}


export const PROJECTS_LIST_API = "project/list/"

export const LOGOUT_API = "logout/"

export const SEND_CUSTOM_PUSH = "push/send_custom/"
export const ADD_CLONE_JIGSAW = "project/add/"

export const CHANGE_PASSWORD = "change/password/"


export const LEVEL_CHARACTER_POSITION_API = 'position/create_edit/'

export const  CHARACTER_POSITION_LIST_API = 'position/list/'

export const TinyLearnerEndpoints = {
    GROUPS: 'group/list/',
    GROUP_INDEX_UPDATE: 'group/index_update/',
    GROUP_ACTIVE_INACTIVE: 'group/active_inactive/',
    GROUP_DELETE: 'group/delete/',

    GROUP_CREATE_EDIT: 'group/create/',
    LEVEL_INDEX_UPDATE: 'group/levels/index_update/',
    GROUP_DETAIL: 'group/detail/',
    GROUP_LEVEL_CREATE_EDIT: 'level/create_edit/',
    GROUP_ADD_UPDATE_LEVEL_JSON: 'group/update_level_data/',
    GROUP_ADD_LEVEL_MEDIA: 'group/level_media_add/',
    ALL_LEVEL_ZIP_DELETE: 'group/levels/all_media_delete/'
};

export const AppVersionApis = {
    SUPERHERO_APP_VERSION_LIST: "app_version/list/",
    SUPERHERO_APP_VERSION_UPDATE: "app_version/update/",
    SUPERHERO_APP_STORE_VERSION_UPDATE: "app_store_version/update/",
    SUPERHERO_APP_BG_TOGGLE: "level/bg_download_toggle/",

    TL_APP_VERSION_LIST:"app_version/list/",
    TL_APP_VERSION_UPDATE: "app_version/update/",

}

export const MEDIA_DELETE = 'media_delete/'

