const LevelZipLogo = ({ username, logoText }) => {
    const logoUrl = '/assets/zip1.svg'

    const logoStyle = {
        backgroundImage: `url(${logoUrl})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        width: '55px',
        height: '55px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // position: 'relative'
    };
    return (
        <div style={logoStyle}>
        </div>
    );
};


export default LevelZipLogo;

