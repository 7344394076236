import React,{ useEffect, useState } from 'react'
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material';
import { Field } from 'react-final-form'

import UploadIcon from '../icons/UploadIcon';
import CancelIcon from '@mui/icons-material/Cancel';
import { store } from '../store';
import { ShowErrorAlert, ShowSuccessAlert } from '../store/alerts';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';

import { S3Client, DeleteObjectCommand, PutObjectCommand 
    // HeadObjectCommand 
} from "@aws-sdk/client-s3";
// import AWS from "aws-sdk";
import { postRequest } from '../data/helpers/services';
import { MEDIA_DELETE } from '../constants';

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const REGION = process.env.REACT_APP_S3_REGION;
const accessKeyId = process.env.REACT_APP_S3_ACCESS_ID;
const secretAccessKey = process.env.REACT_APP_S3_ACCESS_KEY;
const BASE_URL = process.env.REACT_APP_S3_BASE_PATH

const BASE_URL_CLONE = process.env.REACT_APP_S3_BASE_PATH_CLONE

// AWS.config.update({
//   accessKeyId: accessKeyId,
//   secretAccessKey: secretAccessKey,
// });

const s3Client = new S3Client({
    credentials: {
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey
    },
    region: REGION
});

// const myBucket = new AWS.S3({
//     params: { Bucket: S3_BUCKET },
//     region: REGION,
//   });

  

export default function ImageUpload(props:any) {
    const {imageArray, setImageArray, levelNo, setOpen, charId, accessToken, ediatble, uid } = props;
    const [checkUpdated, setCheckUpdated] = useState(false)
    const [progress, setProgress] = useState(0);

    const [openDelete, setOpenDelete] = useState<{
        state:boolean,
        index:number
    }>({state:false, index:0})

    const { dispatch } = store;

    const [imageObj,setImageObj] = useState(
        props.imageArray.filter(obj =>{ 
            if (obj.Id === props.name) {
                return {...obj}
            }})[0]
    )

    const uploadFile = async (file) => {
        if (file.name.includes(" ")){
            dispatch(ShowErrorAlert({visible:true, message: "Image file name contains spaces kindly remove"}));
            return false
        }
        let folder = `World${levelNo}/${file.name}`
        let key = "superhero/" + folder
        if (uid){
            key = `V3_Clone/${uid}/${folder}`
        }

        const input = {
            ACL: "public-read",
            Body: file,
            Bucket: S3_BUCKET,
            Key: key,
            ContentType: 'image/jpeg',
        }

        // const command = new HeadObjectCommand(input);
        const command = new PutObjectCommand(input);

        try{
            let res = await s3Client.send(command);
            if (res && res['$metadata']['httpStatusCode'] === 200){

                let updatedImageArray = imageArray.map(obj => {
                            if (obj.Id === props.name) {
                                obj.uploadStarted = true 
                                return {...obj}
                            };
                            return obj;
                        })
                // if ((Math.round((evt.loaded / evt.total) * 100) === 100)){
                    updatedImageArray = imageArray.map(obj => {
                        if (obj.Id === props.name) {
                            obj.uploadStarted = false 
                            obj.uploaded = true
                            obj.media = `${folder}`
                            return {...obj}
                        };
                        setImageObj({...imageObj, media:folder, uploaded:true, uploadStarted:false })
                        return obj;
                    })
                setImageArray(updatedImageArray)
                // setProgress(Math.round((evt.loaded / evt.total) * 100));
            }
        }
        catch(err){
            console.log(err)
        }


        // const params = {
        //     ACL: "public-read",
        //     Body: file,
        //     Bucket: S3_BUCKET,
        //     Key: ("superhero/" + folder),
        //     ContentType: 'image/jpeg',
        // };

        // await myBucket
        //     .putObject(params)
        //     .on("httpUploadProgress", (evt) => {
        //     let updatedImageArray = imageArray.map(obj => {
        //         if (obj.Id === props.name) {
        //             obj.uploadStarted = true 
        //             return {...obj}
        //         };
        //         return obj;
        //     })
        //     if ((Math.round((evt.loaded / evt.total) * 100) === 100)){
        //         updatedImageArray = imageArray.map(obj => {
        //             if (obj.Id === props.name) {
        //                 obj.uploadStarted = false 
        //                 obj.uploaded = true
        //                 obj.media = `${folder}`
        //                 return {...obj}
        //             };
        //             setImageObj({...imageObj, media:folder, uploaded:true, uploadStarted:false })
        //             return obj;
        //         })
        //     }
        //     setImageArray(updatedImageArray)
        //     setProgress(Math.round((evt.loaded / evt.total) * 100));
        //     })
        //     .send((err) => {
        //     if (err) console.log("err",err);
        // });
        return true
    };

    const handleFileSelect = async (event: any) => {
        await uploadFile(event.target.files[0])
        return true;
    };

    const handleFileRemove = async (event: any) => {
        const input = {
          Bucket: S3_BUCKET,
          Key: imageObj.media
        }
        const command = new DeleteObjectCommand(input);
        await s3Client.send(command);
  
        let updatedImageArray = imageArray.map(obj => {
            if (obj.Id === props.name) {
                obj.uploadStarted = false 
                obj.uploaded = false
                obj.media = ""
                return {...obj}
            };
            return obj;
        })
        setImageArray(updatedImageArray)
        setImageObj({...imageObj, uploadStarted:false, uploaded:false, media:""})
    }

    const deletionProps = {
        charId: charId,
        setOpenDelete: setOpenDelete,
        openDelete: openDelete,
        levelNo: levelNo,
        dispatch: dispatch,
        imageObj: imageObj,
        imageArray: imageArray,
        setImageArray: setImageArray,
        setImageObj: setImageObj,
        imageType: props.imageType,
        accessToken: accessToken,
        uid: uid
    }

    return (
        <>
         <div className="col-12 md-col-4" 
            style={{
                // width:"33%"
                width:"100%",
                marginTop: "10px",
                // @ts-ignore
                textAlign: "-webkit-center",
            }}
            >
            <label className='input--label' htmlFor="thumbnail">
                {props.imageType} image
            </label>
            <Field
                name={props.name}
                type="hidden"
                component="input"
                id="thumbnail"
                placeholder="thumbnail"
                disabled= {ediatble ? true : false}
            />
            <Field
                name="id"
                type="hidden"
                component="input"
                id="id"
                placeholder="id"
                disabled= {ediatble ? true : false}
            />
    
            {(!imageObj.uploaded)
                ?
                (
                    <Box component="div"
                        sx={{
                            width: '20%',
                            background: '#232157',
                            marginBottom: "20px",
                            position: 'relative',
                            borderRadius: "15px",
                            cursor: "pointer",
                            aspectRatio: '4/3'
                        }}
                    >
                        <Box
                            component="div"
                            sx={{
                                position: 'absolute',
                                left: '50%',
                                top: '50%',
                                transform: 'translate(-50%,-50%)',
                            }}
                        >
                            <UploadIcon />
                        </Box>
                        
                        <Box
                            component="input"
                            disabled= {ediatble ? false : true}
                            type="file"
                            name="thumbnail"
                            onChange={handleFileSelect}
                            sx={{
                                height: '100%',
                                width: '100%',
                                opacity: '0',
                                cursor: 'pointer',
                                left: '50%'
                            }}
                        ></Box>
                    </Box>
                )
                :
                (imageObj.uploaded || imageObj.uploadStarted) ? (
                    <>
                        <Box className="img--uploadBg" style={{
                            minHeight: '100px',
                            maxHeight: '100px'
                        }}>
                        
                        
                            {
                                (!imageObj.uploaded)
                                ?
                                ''
                                : 
                                <div
                                    style={{
                                        position: 'relative',
                                        display: 'inline-block',
                                        marginTop: "12px"
                                    }}

                                >
                                    <img
                                        src={
                                        (uid)?
                                        `${BASE_URL_CLONE}${uid}/${imageObj.media}`
                                            :
                                            `${BASE_URL}${imageObj.media}`
                                        }
                                        style={{
                                            width: '65px',
                                            height: '65px',
                                            borderRadius: '50%'
                                        }}
                                    onClick={() =>setOpen({state:true, 
                                        
                                        media:
                                        (uid) ? 
                                        `${BASE_URL_CLONE}${uid}/${imageObj.media}`
                                        :    
                                        `${BASE_URL}${imageObj.media}`
                                    
                                    })}
                                        // onClick={() =>  {console.log("CK")}}
                                    />

                                    <DeleteConfirmation
                                        {...deletionProps}
                                    />
                                    
                                    {
                                        (ediatble) ?
                                            <svg
                                                viewBox="0 0 24 24"
                                                // onClick={handleFileRemove}
                                                onClick={() => {
                                                    // fields.remove(index)
                                                    // removeImages(index)
                                                    setOpenDelete({
                                                        state: true,
                                                        index: levelNo
                                                    })
                                                }}
                                                style={{
                                                    position: 'absolute',
                                                    top: '-10px',
                                                    right: '-10px',
                                                    width: '20px',
                                                    height: '20px',
                                                    cursor: 'pointer'
                                                }}>
                                                <path
                                                    fill="#FF0000"
                                                    d="M12,0C5.373,0,0,5.373,0,12s5.373,12,12,12s12-5.373,12-12S18.627,0,12,0z M16.293,15.707l-1.414,1.414L12,13.414l-2.879,2.879l-1.414-1.414L10.586,12L7.707,9.121l1.414-1.414L12,10.586l2.879-2.879l1.414,1.414L13.414,12L16.293,15.707z"
                                                />
                                            </svg>
                                        : ""
                                            }
                                </div>
                                }
                        </Box>
                    </>
                ) : ""
                }
             </div>
            </>
      )
}


export function DeleteConfirmation(props:any) {
    
    const {
        charId,
        setOpenDelete,
        openDelete,
        levelNo,
        dispatch,
        imageObj,
        imageArray,
        setImageArray,
        setImageObj,
        imageType,
        accessToken,
        uid
    } = props;
    const handleClose = () => {
        setOpenDelete({state: false, index: 0});
    };

    const character_image_name = imageObj.media;
    let splitted_image_name = "";
    const lastIndex = character_image_name.lastIndexOf("/");
    const after = character_image_name.slice(lastIndex + 1);
    splitted_image_name = after;

    const deleteConfirmed = async() => {
        
        let key =  `superhero/${imageObj.media}`

        if (uid){
            key = `V3_Clone/${uid}/${imageObj.media}`
        }

        const input = {
            Bucket: S3_BUCKET,
            Key: key
        }
        const command = new DeleteObjectCommand(input);
        await s3Client.send(command);
    
        let updatedImageArray = imageArray.map(obj => {
            if (obj.Id === props.name) {
                obj.uploadStarted = false 
                obj.uploaded = false
                obj.media = ""
                return {...obj}
            };
            return obj;
        })
        setImageArray(updatedImageArray)
        setImageObj({...imageObj, uploadStarted:false, uploaded:false, media:""})

        let media_type = "";
        if (imageType === "FG") {
            media_type = "SH_LF";
        }
        else if (imageType === "BG") {
            media_type = "SH_LB";
        }

        let version = localStorage.getItem('level_version')
        
        const res = await postRequest(
            MEDIA_DELETE,
            {
                "id": charId,
                "media_type": media_type,
                "uid": uid
            },
            accessToken,
            (version ? version : "")
        );

        setOpenDelete({state: false, index: 0});
    };
    return (
        <div>
            <Dialog
            open={openDelete['state']}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">
                <h4>{"Delete Confirmation"}</h4>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <span>
                        Are you sure you want to delete {imageType}_image 
                        <strong> ({splitted_image_name}) </strong> 
                        from index <strong>{levelNo}</strong> ?
                    </span>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button className='cancel-class' onClick={handleClose}>
                    Cancel
                </button>

                <button className='delete-class' onClick={deleteConfirmed}>
                    Delete
                </button>
                </DialogActions>
            </Dialog>
        
        </div>
    )
}

