import PropTypes from 'prop-types';

// @mui
import { Card, Button, Dialog, DialogTitle, DialogContent, Stack, DialogContentText, DialogActions } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import { Link, useNavigate } from 'react-router-dom';
import { TextField as MUITextField } from '@mui/material';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { useContext, useEffect, useState } from 'react';

// import { isApiCodeSucess, postRequest } from 'src/data/helpers/services';
import { isApiCodeSucess, postRequest } from '../../../data/helpers/services';
import { LEVEL_CREATE_API, SuperHeroEndpoints } from '../../../constants';
import { HideProgressBar, ShowErrorAlert, ShowProgressBar, ShowSuccessAlert } from '../../../store/alerts';
import { useDispatch } from 'react-redux';
import { AccessTokenContext } from '../../../routes';
import SuperHeroLevelImageUploadOnS3 from '../../../components/SuperHeroLevelImageUpload';
import { createLevel } from '../../../data/mappers/createLevel';
import CustomToggle from '../../../components/CustomToggle';
// import AWS from "aws-sdk";
import DeleteIcon from '../../../static/DeleteIcon';


const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const REGION = process.env.REACT_APP_S3_REGION;
const accessKeyId = process.env.REACT_APP_S3_ACCESS_ID;
const secretAccessKey = process.env.REACT_APP_S3_ACCESS_KEY;
const BASE_URL = process.env.REACT_APP_S3_BASE_PATH



// AWS.config.update({
//   region: REGION,
//   accessKeyId: accessKeyId,
//   secretAccessKey: secretAccessKey
// });


ShopProductCard.propTypes = {
  product: PropTypes.object,
};


export default function ShopProductCard({ product, products, setProducts, editableIndex, setEditableIndex, selectedLevels,
  setSelectedLevels, uid }) {
  const {
    characterImage,
    characterImageName,
    id,
    level,
    newLevel,
    scaling,
    isActive,
    imageSize
  } = product;

  const editLevel = (level_id) => {
    setEditableIndex(String(level_id));
  }

  const updateLevel = async (level_id) => {
    if (!characterImageName && !characterImage) {
      dispatch(ShowErrorAlert({ visible: true, message: `Character image is required.` }))
      return false;
    }
    else {
      var response: any = null;
      const event = {
        "level_id": `${level_id}`,
        "media_path_name": `${characterImageName}`,
        "media_path": `${characterImage}`,
        "level": level,
        "scale": scale
      }
      let data = createLevel(
        event,
        characterImageName,
        "edit",
        newLevel,
        uid
      );

      let version = localStorage.getItem('level_version')

      response = await postRequest(
        LEVEL_CREATE_API,
        data,
        accessToken,
        (version ? version : "")
      );
      dispatch(ShowProgressBar())

      if (response) {
        dispatch(HideProgressBar());
        if (isApiCodeSucess(response)) {
          const resData = response.data;
          dispatch(ShowSuccessAlert({
            visible: true,
            message: `Level updated successfully.`
          }));
          setEditableIndex("-1");
        }
      }
    }
  }

  const [deleteAllLevelConfirmation, setDeleteAllLevelConfirmation] = useState(false)

  const [scale, setScale] = useState(scaling);
  // const [imageSize, setImageSize] = useState("")

  const handleScaleChange = (event) => {
    setScale(event.target.value);
  };

  const [character_image, set_character_image] = useState(characterImage);
  const [character_image_name, set_character_image_name] = useState(characterImageName);

  const dispatch = useDispatch();
  const getCookieAccessToken: any = useContext(AccessTokenContext)
  let accessToken: string = getCookieAccessToken.cookieAccessToken

  let version = localStorage.getItem('level_version')

  // useEffect(() =>{
  //   getImageSize(characterImage)
  // },[])

  const onLevelToggleHandle = async () => {
    dispatch(ShowProgressBar())
    let res: any = {}

    res = await postRequest(
      SuperHeroEndpoints.ACTIVE_INACTIVE_LEVEL,
      {
        level_id: id,
        status: (isActive ? "2" : "1"),
        uid: uid
      },
      accessToken,
      (version ? version : "")

    );
    if (res) {
      if (isApiCodeSucess(res)) {
        const resData = res.data
        dispatch(ShowSuccessAlert({ visible: true, message: resData.message }));
        let tempArray = []
        products.forEach(x => {
          if (x.id === id) {
            x.isActive = !isActive
          }
          tempArray.push(x)
        })
        setProducts(tempArray)
        dispatch(HideProgressBar())

      }
    }
  }

  // const getImageSize = async (image_key) => {
  //   image_key = image_key.split("amazonaws.com/")[1]
  //   // Create an S3 client
  //   const s3 = new AWS.S3();

  //   // Set the S3 bucket and image key
  //   const bucketName = S3_BUCKET;
  //   const imageKey = image_key;

  //   // Create the parameters for the getObject API call
  //   const params = {
  //     Bucket: bucketName,
  //     Key: imageKey
  //   };
  //   // Retrieve the image metadata
  //   s3.headObject(params, (err, data) => {
  //     if (err) {
  //       console.log('Error retrieving image metadata:', err);
  //     } else {
  //       // console.log('Image size:', data.ContentLength);
  //       let size = (Math.round(data.ContentLength / 1024)).toString();
  //       setImageSize(size + " KB");
  //     }
  //   });
  // }

  const customLevelToggleProps = {
    onToggleHandle: onLevelToggleHandle,
    checked: isActive
  }

  const [isChecked, setIsChecked] = useState<boolean>(
    () => {
      if (selectedLevels.includes(id)) {
        return true;
      }
      else {
        return false;
      }
    }

  )

  const levelDeleteConfirmProps = {
    deleteAllLevelConfirmation: deleteAllLevelConfirmation,
    setDeleteAllLevelConfirmation: setDeleteAllLevelConfirmation,
    levelId: id,
    accessToken: accessToken,
    version: version,
    level: level,
    uid: uid
  }


  let onChange = async () => {
    var response: any = null;
    response = await postRequest(SuperHeroEndpoints.Update_Level_Selection,
      {
        selected_level: id.toString(),
        uid: uid
      }
      , accessToken,
      (version ? version : "")
    );
    if (response) {
      dispatch(HideProgressBar());
      if (isApiCodeSucess(response)) {
        setIsChecked(!isChecked)
        const resData = response.data
      }
    }
  }



  return (
    <Card style={{
      height: "110px"
    }}>
      <LevelDeleteConfirmation {...levelDeleteConfirmProps} />

      <Stack direction='row' justifyContent="space-between" sx={{ maxHeight: "100%" }}>
        <div style={{
          marginLeft: '10px',
          alignSelf: "center",
        }}>
          {
            localStorage.getItem('level_version') !== 'v1' &&
            <div style={{
              width: '8%',
              marginRight: "5%"
            }}>
              <Checkbox
                checked={isChecked}
                onChange={() => { onChange() }}
              />
            </div>
          }
        </div>
        {/* <div style={{
              width: "25%",
              alignSelf: "center"
          }}>
            <MUITextField
                id="outlined-basic__4"
                label="Level"
                variant="outlined"
                value={level}
                sx={{
                    mr:"10%",
                    background: "#fff",
                    // ml: '20px',
                }}
                type="text"
                disabled
            />
          </div> */}
        <div style={{
          minWidth: "17%",
          maxWidth: "20%",
          alignSelf: "center"
        }}>
          <MUITextField
            id="outlined-basic"
            label="Scale"
            variant="outlined"
            value={scale}
            onChange={handleScaleChange}
            type="text"
            disabled={
              (editableIndex === id) ? false : true
            }
          />
        </div>
        <div style={{
          alignSelf: "center",
          minWidth: "15%",
          maxWidth: "25%"
        }}>
          <SuperHeroLevelImageUploadOnS3
            levelId={id}
            character_image={character_image}
            set_character_image={set_character_image}
            character_image_name={character_image_name}
            set_character_image_name={set_character_image_name}
            levelNo={level}
            scale={scale}
            isNewLevel={newLevel}
            editableIndex={editableIndex}
            products={products}
            setProducts={setProducts}
            uid={uid}
          />
        </div>
        <div style={{
          alignSelf: "self-end",
          display: "block",
          maxWidth: "11%",
          marginLeft: "4%"
        }}>
          {imageSize ? `${imageSize} Kb` : ""}

        </div>
        <div
          style={{
            width: "13%",
            alignSelf: 'center'
          }}
        >
          <Link
            style={{
              textDecoration: "none",
              color: '#2065D1',
              alignSelf: "center",
              cursor: "pointer",
              marginLeft: '30px'
            }} to={
              uid ?
                `/dashboard/${uid}/level/${product.id}`
                :
                "/dashboard/level/" + product.id

            }>
            <ArrowCircleRightOutlinedIcon
              fontSize='large'
            />
          </Link>
        </div>

        {
          (localStorage.getItem('level_version') !== 'v1') &&
          <div style={{
            minWidth: "12%",
            alignSelf: "center",
            justifyContent: 'center'
          }}>
            {
              (editableIndex === id)
                ?
                <>
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} justifyContent="space-between">
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "red",
                        "&:hover": {
                          backgroundColor: "red",
                          cursor: "pointer",
                        }
                      }}
                      onClick={() => {
                        setEditableIndex("");
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      variant="contained"
                      onClick={() => {
                        updateLevel(id);
                      }}
                    >
                      Save
                    </Button>
                  </Stack>
                </>
                :
                <>

                  <EditIcon
                    fontSize='large'
                    onClick={() => editLevel(id)}
                  />
                </>
            }
          </div>
        }

        {
          localStorage.getItem('level_version') == 'v1'
            ? ""
            :

            <div style={{ marginLeft: "1%", alignSelf: "center" }}>
              <CustomToggle {...customLevelToggleProps} />
            </div>
        }



        {
          process.env.NODE_ENV === "Prod"
            ?
            ""
            :

            <div style={{ marginLeft: "4%", width: "10%", alignSelf: "center" }} onClick={() => {
              setDeleteAllLevelConfirmation(true)
            }}>

              <DeleteIcon />

            </div>

        }

      </Stack>

    </Card >
  );
}




export function LevelDeleteConfirmation(props: any) {

  const {
    deleteAllLevelConfirmation,
    setDeleteAllLevelConfirmation,
    levelId,
    accessToken,
    version,
    level,
    uid
  } = props;

  const handleClose = () => {
    setDeleteAllLevelConfirmation(false);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteConfirmed = async () => {
    const res = await postRequest(
      SuperHeroEndpoints.LEVEL_DELETE,
      {
        "level_id": levelId,
        "uid": uid
      },
      accessToken,
      version
    )
    if (res) {
      if (isApiCodeSucess(res)) {
        dispatch(ShowSuccessAlert({
          visible: true,
          message: `${res.data.message}`
        }));
        navigate(0)
      } else {
        setDeleteAllLevelConfirmation(false);
      }
    }

  };
  return (
    <div>
      <Dialog
        open={deleteAllLevelConfirmation}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h4>{"Delete Confirmation"}</h4>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>
              Are you sure you want to delete Level-{level} ?
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className='cancel-class' onClick={handleClose}>
            Cancel
          </button>

          <button className='delete-class' onClick={deleteConfirmed}>
            Delete
          </button>
        </DialogActions>
      </Dialog>

    </div>
  )
}
