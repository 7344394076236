export const ALERTS_REDUX = {
    SHOW_ERROR_ALERT: 'SHOW_ERROR_ALERT',
    SHOW_DIALOG_BOX: 'SHOW_DIALOG_BOX',
    SHOW_SUCCESS_ALERT: 'SHOW_SUCCESS_ALERT',
    SHOW_LOADER: 'SHOW_LOADER',
    HIDE_LOADER: 'HIDE_LOADER',
    SHOW_BACKDROP: 'SHOW_BACKDROP',
    HIDE_BACKDROP: 'HIDE_BACKDROP',   
};


export const PROJECTS_REDUX = {
    UPDATE: 'UPDATE_PROJECTS_LIST',
    SELECTED_PROJECT : 'SELECTED_PROJECT'
}


export const RESET = 'RESET';
