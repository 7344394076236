export const createGroupLevels = (data: any, groupId:string, zipArray:any) => {
    let levelArray = []
    data.groupLevels.forEach((x,arrayIndex) =>{
        let zipPath = ""
        const zip_media = zipArray[arrayIndex].media;
        if (zip_media){
            zipPath = `${process.env.REACT_APP_S3_BASE_PATH_TL}${zip_media}`
        }

        const level_name = zip_media;
        let level_media_name = zip_media;
        if (level_name) {
            const lastIndex = level_name.lastIndexOf("/");
            const after = level_name.slice(lastIndex + 1);
            level_media_name = after;
        }

        levelArray.push({
            level_id: (x.level_id > 0) ? (x.level_id).toString() : "",
            level_no: parseFloat(x.index),
            // level_name: x.level_name,
            level_name: level_media_name,
            level_media_name: zip_media,
            level_media_path: zipPath
            })
        }    
    )

    let groupLevels:any = {
        "group_id": groupId,
        "levels_data": levelArray
    }
    // if(data.id) {
    //     match.id = data.id;
    //     match.is_immediate = (startType === '0') ? "1"  : "2";
    // }

    return groupLevels;
}


export const createLevelsJSONData = (data: any, groupId:string) => {
    let levelArray = []
    data.forEach((level, index) => {
        const level_index = level.index ? level.index : "";
        const color1 = level.color1 ? level.color1 : "";
        const color2 = level.color2 ? level.color2 : "";
        const color3 = level.color3 ? level.color3 : "";
        const coins = level.coins ? level.coins : "";
        const name = level.name ? level.name : "";
        const folder_level = level.folder_level ? level.folder_level : "";
        const level_json = {
            index: level_index,
            color1: color1,
            color2: color2,
            color3: color3,
            coins: coins,
            name: name,
            folder_level: folder_level
        }
        levelArray.push(level_json)
    })
    const return_data = {
        group_id: groupId,
        level_data: levelArray
    }

    return return_data;
}

