import React, { useContext, useEffect, useState } from 'react'
import { Form, Field } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

import { TextField } from 'mui-rff';
import { createLevelPosition } from '../data/mappers/createLevelPositions';
import { LEVEL_CHARACTER_POSITION_API, CHARACTER_POSITION_LIST_API } from '../constants';

import { S3Client, DeleteObjectCommand } from "@aws-sdk/client-s3";
import TextFieldCommon from '../components/TextFieldCommon';

import ImageUpload from '../components/imageUpload';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { HideProgressBar, ShowProgressBar, ShowErrorAlert, ShowSuccessAlert } from '../store/alerts';
import { isApiCodeSucess, postRequest } from '../data/helpers/services';
import { AccessTokenContext } from '../routes';
import "yet-another-react-lightbox/styles.css";
import Lightbox from "yet-another-react-lightbox";


const s3Client = new S3Client({
    credentials: {
        accessKeyId: process.env.REACT_APP_S3_ACCESS_ID,
        secretAccessKey: process.env.REACT_APP_S3_ACCESS_KEY
    },
    region: process.env.REACT_APP_S3_REGION
});


export function PageStyles() {
    return (
        <>
            <style>
                {`
                .yarl__navigation_prev{
                    display: none !important;
                }
                .yarl__navigation_next{
                    display: none !important;
                }
            `}
            </style>
        </>
    )
}

export function DeleteConfirmation(props: any) {
    const { setOpenDelete, openDelete, imageArray, setImageArray, fields } = props;

    const handleClose = () => {
        setOpenDelete({ state: false, index: 0 });
    };

    const removeImages = async (index) => {
        let tempArray = imageArray
        let fgImg = tempArray[index * 2]
        let bgImg = tempArray[index * 2 + 1]
        let images = [fgImg, bgImg]

        images.forEach(async (image) => {
            const input = {
                Bucket: process.env.REACT_APP_S3_BUCKET,
                Key: image
            }
            const command = new DeleteObjectCommand(input);
            await s3Client.send(command);
        }
        )
        tempArray.splice(index * 2 + 1, 1)
        tempArray.splice(index * 2, 1)

        let newArray = []
        tempArray.forEach((x, i) => {
            if (i >= index * 2) {
                let a = x.Id
                let numb = a.match(/\d/g).join("");
                x.Id = a.replace(`${numb}`, `${numb - 1}`)
            }
            newArray.push(x)
        })

        setImageArray(newArray)
    }

    const deleteConfirmed = async () => {
        fields.remove(openDelete['index'])
        removeImages(openDelete['index'])
        setOpenDelete({ state: false, index: 0 });
    };


    return (
        <div>
            <Dialog
                open={openDelete['state']}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <h4>{"Delete Confirmation"}</h4>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this index ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button className='cancel-class' onClick={handleClose}>
                        Cancel
                    </button>

                    <button className='delete-class' onClick={deleteConfirmed}>
                        Delete
                    </button>
                </DialogActions>
            </Dialog>

        </div>
    )
}


export default function LevelPositions(props: any) {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const getCookieAccessToken: any = useContext(AccessTokenContext)

    let accessToken: string = getCookieAccessToken.cookieAccessToken

    const [ediatble, setEditable] = useState(false)

    const { uid, levelId } = useParams();
    const [levelNo, setLevelNo] = useState("");

    const [open, setOpen] = useState({ state: false, media: "" })
    const [openDelete, setOpenDelete] = useState<{ state: boolean, index: number }>({ state: false, index: 0 })

    let initialFormValues = {
        "char_id": null,
        "x_move": null,
        "y_move": null,
        "index": 1,
        "real_x_position": null,
        "real_y_position": null
    }

    const [initialForm, setInitialForm] = useState([])
    const [imageArray, setImageArray] = useState([])

    const [responseFetched, setResponseFetched] = useState(false)

    useEffect(() => {
        getCharacterPositions()
    }, [])

    const getCharacterPositions = async () => {
        let res: any = {}

        let version = localStorage.getItem('level_version')

        res = await postRequest(CHARACTER_POSITION_LIST_API, {
            level_id: levelId,
            uid: uid
        }, accessToken,
            (version ? version : "")
        );
        if (res) {
            if (isApiCodeSucess(res)) {
                let responseData = res.data.data
                let responseArray = responseData.charcter_list_data
                setLevelNo(responseData.level_no)
                if (responseArray.length > 0) {
                    responseArray.forEach((resObj, index) => {
                        let tempArray = initialForm
                        tempArray.push({
                            "char_id": resObj.id,
                            "x_move": resObj.x_move,
                            "y_move": resObj.y_move,
                            "index": resObj.index,
                            "real_x_position": resObj.real_x_position,
                            "real_y_position": resObj.real_y_position
                        })

                        let tempImageArray: any = imageArray

                        tempImageArray.push({
                            Id: `levelPositions[${index}].foreground`,
                            uploadStarted: false,
                            uploaded: resObj.fg_image ? true : false,
                            media: `${resObj.fg_image}`
                        });

                        tempImageArray.push({
                            Id: `levelPositions[${index}].background`,
                            uploadStarted: false,
                            uploaded: resObj.bg_image ? true : false,
                            media: `${resObj.bg_image}`
                        });
                        setImageArray(tempImageArray)

                        setInitialForm(tempArray)
                    })
                }
                else {
                    setInitialForm([{ ...initialFormValues }])
                    let tempImageArray = []
                    tempImageArray.push({ Id: `levelPositions[0].foreground`, uploadStarted: false, uploaded: false, media: "" });
                    tempImageArray.push({ Id: `levelPositions[0].background`, uploadStarted: false, uploaded: false, media: "" });
                    setImageArray(tempImageArray)
                }
            }

            setResponseFetched(true)
        }
    }

    const verifyIndexFailed = (event) => {
        let isDuplicate = false
        let tempIndexArray: number[] = []
        event.levelPositions.map(
            (obj) => {
                if (tempIndexArray.includes(parseInt(obj.index))) {
                    dispatch(ShowErrorAlert({ visible: true, message: `Index ${obj.index} used multiple times` }))
                    isDuplicate = true;
                } else {
                    tempIndexArray.push(parseInt(obj.index));
                }
            }
        )
        return isDuplicate;
    }

    const verifyImageNotUploaded = (event) => {
        let failed = false
        for (let i = 0; i < event.levelPositions.length; i++) {
            // FG IMAGE
            if (!imageArray[i * 2].media) {
                failed = true;
                dispatch(ShowErrorAlert({ visible: true, message: `Forground image for index ${event.levelPositions[i].index} is missing` }))
                break;

            }

            // BG IMAGE
            if (!imageArray[i * 2 + 1].media) {
                failed = true;
                dispatch(ShowErrorAlert({ visible: true, message: `Background image for index ${event.levelPositions[i].index} is missing` }))
                break;
            }
        }
        return failed
    }

    let handleSubmit = async (event) => {
        dispatch(ShowProgressBar());
        let duplicateIndex = verifyIndexFailed(event)
        let verifyNotAllImage = verifyImageNotUploaded(event)

        if (duplicateIndex) {
            dispatch(HideProgressBar());
            return true;
        }
        else if (verifyNotAllImage) {
            dispatch(HideProgressBar());
            return true;
        }
        else {
            let data = createLevelPosition(event, levelId, imageArray, uid);
            var response: any = null;

            let version = localStorage.getItem('level_version')

            response = await postRequest(LEVEL_CHARACTER_POSITION_API, data, accessToken,
                (version ? version : "")
            );
            if (response) {
                dispatch(HideProgressBar());
                if (isApiCodeSucess(response)) {
                    const resData = response.data;
                    getCharacterPositions();
                    navigate(0)
                }

            }
        }
    }

    const imageProps = {
        imageArray: imageArray,
        setImageArray: setImageArray,
        levelNo: levelNo,
        setOpen: setOpen,
        accessToken: accessToken,
        ediatble: ediatble
    }
    const AddAnotherRow = (fields) => {
        let index = 1
        if (fields.value) {
            index = fields.value[fields.value.length - 1].index + 1
        }
        fields.push({ ...initialFormValues, index: index })
        let tempImageArray: any = []
        if (imageArray.length) {
            tempImageArray = imageArray
        }
        tempImageArray.push({ Id: `${fields.name}[${fields.length}].foreground`, uploadStarted: false, uploaded: false, media: "" });
        tempImageArray.push({ Id: `${fields.name}[${fields.length}].background`, uploadStarted: false, uploaded: false, media: "" });
        setImageArray(tempImageArray)
        dispatch(ShowSuccessAlert({ visible: true, message: "New row added" }));
    }


    const deletionProps = {
        setOpenDelete: setOpenDelete,
        openDelete: openDelete,
        imageArray: imageArray,
        setImageArray: setImageArray,
        uid: uid
    }

    const EditButtonToggle = () => {
        if (ediatble) {
            navigate(0)
        }
        setEditable(!ediatble);
    }

    return (
        <div>
            <div
                style={{
                    marginTop: "-80px"
                }}
            >
                <ArrowCircleLeftIcon
                    fontSize="large"
                    color="secondary"
                    onClick={() => navigate(-1)}
                    sx={{
                        marginBottom: "10px",
                        marginLeft: "10px",
                        "&:hover": {
                            color: "#103996",
                            cursor: "pointer",
                        }
                    }}
                />
            </div>

            <PageStyles />
            <Lightbox
                open={open['state']}
                close={() => setOpen({ state: false, media: "" })}
                slides={[
                    {
                        src: open['media'],
                        alt: "image 1",
                        width: 3840,
                        height: 2560,
                    }
                ]} />

            {responseFetched ?
                <Form
                    mutators={{
                        // potentially other mutators could be merged here
                        ...arrayMutators
                    }}
                    initialValues={{ levelPositions: initialForm }}
                    onSubmit={handleSubmit}>
                    {
                        (props: any) => (
                            <form onSubmit={props.handleSubmit}>
                                <FieldArray name="levelPositions">
                                    {({ fields }) => (
                                        <>

                                            <DeleteConfirmation {...deletionProps} fields={fields} />

                                            <div
                                                className='row'
                                                style={{
                                                    display: 'flex',
                                                    marginBottom: "10px"
                                                }}
                                            >
                                                <div style={{
                                                    width: '60%'
                                                }}>
                                                    {
                                                        ediatble
                                                            ?
                                                            <button
                                                                style={{ background: "none", border: "none", cursor: "pointer" }}
                                                                type="button"
                                                                onClick={() => AddAnotherRow(fields)}
                                                            >
                                                                <AddCircleIcon sx={{ color: "green" }} fontSize={'large'} />
                                                            </button>

                                                            :
                                                            ""}
                                                </div>
                                                <div style={{
                                                    width: '40%'
                                                }}>
                                                    <Button
                                                        type="button"
                                                        variant="contained"
                                                        onClick={() => { EditButtonToggle() }}
                                                        style={{
                                                            float: 'right',
                                                            marginRight: "20px"
                                                        }}
                                                    >
                                                        {ediatble ? "Cancel" : "Edit"}
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className='row'
                                                style={{
                                                    display: "grid",
                                                    gridTemplateColumns: "repeat(1,1fr)",
                                                    gridColumnGap: "1rem",
                                                    overflow: "auto"
                                                }}
                                            >
                                                {fields.map((name, index) => (
                                                    <div key={name} style={{ minWidth: "600px" }}>
                                                        <Stack alignItems="left" mb={5}
                                                            sx={{
                                                                // background:"linear-gradient(45deg, #2065d13d, #2065d121)",
                                                                background: "#e6e8ef91",
                                                                borderRadius: "40px"
                                                            }}
                                                        >
                                                            <Stack direction="row" justifyContent={"space-around"} alignItems="center"
                                                                sx={{
                                                                    height: "130px"
                                                                }}

                                                            >
                                                                <input type="hidden" name={`${name}.char_id`} />
                                                                <input type="hidden" name={`${name}.index`} />

                                                                {/* <TextField 
                                                            label="Index" name={`${name}.index`} 
                                                            sx={{
                                                                width:"15%",
                                                                // mt:"4%"
                                                                mr:"1%",
                                                                background: "#e6e7ec"
                                                            }} type="number" 
                                                            required={true}
                                                            
                                                            disabled = {!ediatble}
                                                             /> */}

                                                                <TextField
                                                                    label="X Move" name={`${name}.x_move`}
                                                                    sx={{
                                                                        width: "12%",
                                                                        // mr: "1%",
                                                                        background: "#e6e7ec"
                                                                    }} type="number"
                                                                    required={true}
                                                                    disabled={!ediatble}
                                                                />

                                                                <TextField
                                                                    label="Y Move" name={`${name}.y_move`}
                                                                    sx={{
                                                                        width: "12%",
                                                                        // mr: "1%",
                                                                        background: "#e6e7ec"
                                                                    }} type="number"
                                                                    required={true}
                                                                    disabled={!ediatble}
                                                                />

                                                                <TextField
                                                                    label="Real X Position"
                                                                    name={`${name}.real_x_position`}
                                                                    sx={{
                                                                        width: "12%",
                                                                        // mr: "1%",
                                                                        background: "#e6e7ec"
                                                                    }} type="number"
                                                                    required={true}
                                                                    disabled={!ediatble}
                                                                />

                                                                <TextField
                                                                    label="Real Y Position"
                                                                    name={`${name}.real_y_position`}
                                                                    sx={{
                                                                        width: "12%",
                                                                        // mr: "1%",
                                                                        background: "#e6e7ec"
                                                                    }}
                                                                    type="number"
                                                                    required={true}
                                                                    disabled={!ediatble}
                                                                />

                                                                <div style={{ width: "15%" }}>
                                                                    <ImageUpload
                                                                        {...imageProps}
                                                                        imageType={"FG"}
                                                                        name={`${name}.foreground`}
                                                                        charId={fields.value[index].char_id}
                                                                        uid={uid}
                                                                    />
                                                                </div>

                                                                <div
                                                                    style={{
                                                                        width: "15%",
                                                                        // marginLeft: "-2%"
                                                                    }}
                                                                >
                                                                    <ImageUpload
                                                                        {...imageProps}
                                                                        imageType={"BG"}
                                                                        name={`${name}.background`}
                                                                        charId={fields.value[index].char_id}
                                                                        uid={uid}
                                                                    />
                                                                </div>

                                                                {
                                                                    ediatble ?
                                                                        <div
                                                                            style={{
                                                                                width: "15%"
                                                                            }}
                                                                        >
                                                                            {(fields.length > 1) ?
                                                                                <button
                                                                                    tabIndex={-1}
                                                                                    style={{
                                                                                        background: "none",
                                                                                        border: "none",
                                                                                        // float: "right",
                                                                                        // marginRight: "15%"
                                                                                        marginLeft: "10%"
                                                                                    }} type="button"
                                                                                    onClick={() => {
                                                                                        // fields.remove(index)
                                                                                        // removeImages(index)
                                                                                        setOpenDelete({
                                                                                            state: true,
                                                                                            index: index
                                                                                        })
                                                                                    }}>
                                                                                    <DeleteIcon sx={{ color: "red" }} fontSize={'large'} />
                                                                                </button>
                                                                                : ""
                                                                            }
                                                                        </div>
                                                                        : ""}
                                                            </Stack>
                                                        </Stack>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    )}



                                </FieldArray>

                                {
                                    ediatble ?
                                        <div className="button-section">
                                            <Button type="submit" variant="contained">
                                                Submit
                                            </Button>
                                        </div>
                                        : ""
                                }
                            </form>
                        )
                    }
                </Form>
                : ""
            }
        </div>
    )
}
