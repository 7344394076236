import { useState, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import { Link, Container, Stack, IconButton, Box, InputAdornment, TextField, Checkbox, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from "react-redux";
import { postRequest, isApiCodeSucess } from '../../../data/helpers/services';
import { UPDATE_RESET_PASSWORD } from '../../../constants';

import { userLogin } from "./../../../data/mappers/login"
import VisibilityIcon from '@mui/icons-material/Visibility';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { HideProgressBar, ShowErrorAlert, ShowProgressBar, ShowSuccessAlert } from '../../../store/alerts';
import Cookies from 'js-cookie';





import React from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
// hooks
import useResponsive from '../../../hooks/useResponsive';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));



// ----------------------------------------------------------------------

function ResetPasswordFormSide() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { uuid } = useParams();

  const [passwordValue, setPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");



  const handlePasswordChange = (event) => {
    let updatedPassword = event.target.value;
    setPasswordValue(updatedPassword);

    if (confirmPasswordValue) {
      if (confirmPasswordValue !== updatedPassword) {
        setConfirmPasswordError("Password does not match")
      } else {
        setConfirmPasswordError("")
      }
    }
  };


  const handleConfirmPasswordChange = (event) => {
    let updatedConfirmPassword = event.target.value;
    if (passwordValue !== updatedConfirmPassword) {
      setConfirmPasswordError("Password does not match")
    } else {
      setConfirmPasswordValue(updatedConfirmPassword);
      setConfirmPasswordError("")
    }
  };



  const handleSubmit = async (e) => {

    if (confirmPasswordError !== "") {
      dispatch(ShowErrorAlert({ visible: true, message: "Password does not match" }));
      return false;
    }

    if (passwordValue.length < 6) {
      dispatch(ShowErrorAlert({ visible: true, message: "Password should be of minimum 6 characters" }));
      return false;
    }

    dispatch(ShowProgressBar());
    let requestData = {
      password: passwordValue,
      uuid: uuid
    }

    var response: any = null;
    response = await postRequest(UPDATE_RESET_PASSWORD, requestData, "");

    if (response) {
      dispatch(HideProgressBar());
      if (isApiCodeSucess(response)) {
        try {
          // After receiving the access token from the server
          Cookies.set('access_token', response.data.data.data.authorization.access_token, {
            expires: 7, // in days
            secure: true,
            // httpOnly: true,
          });
          Cookies.set('refresh_token', response.data.data.data.authorization.refresh_token, {
            expires: 7, // in days
            secure: true,
            // httpOnly: true,
          });
          dispatch(ShowSuccessAlert({ visible: true, message: "Logged in successfully." }));
          navigate('/dashboard/super_hero/v3/level/');
        } catch (error) {
          console.error(error);
        }
      }
    }
  }



  return (
    <>
      <Stack spacing={3} marginTop={'4%'} marginBottom={'5%'}>
        <TextField name="password"
          // value={passwordValue} 
          InputProps={{
            onChange: handlePasswordChange
          }}
          required={true}
          label="Password" />

        <TextField
          name="confirmPassword"
          label="confirmPassword"
          type="password"
          required={true}
          // value={confirmPasswordValue} 

          InputProps={{
            onChange: handleConfirmPasswordChange

          }}
          error={confirmPasswordError !== ""}
          helperText={confirmPasswordError}


        />
      </Stack>
      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleSubmit}>
        Reset
      </LoadingButton>
    </>
  );
}





// ----------------------------------------------------------------------

export default function ResetPasswordForm() {
  const mdUp = useResponsive('up', 'md', '');

  return (
    <>
      <Helmet>
        <title> Reset Password </title>
      </Helmet>

      <StyledRoot>

        <Box>
          <img src="/assets/logoSkycap.svg"
            style={{
              position: "absolute",
              width: "119px",
              left: "32px",
              maxWidth: "none",
              top: "10px",
              height: "80px"
            }} />

          <span style={{
            color: '#2065D1', marginLeft: "160px", marginTop: "15px", fontWeight: 600,

            position: "absolute",
            width: "119px",
            // left: "42px",
            maxWidth: "none",
            top: "10px",
            height: "80px"
          }}>
            {process.env.REACT_APP_ENV}
          </span>
        </Box>

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Reset password
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            {/* <Typography variant="h4" gutterBottom>
              Reset Password
            </Typography> */}

            <ResetPasswordFormSide />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
