import React, { useContext, useEffect, useState } from 'react'
import { Form, Field } from 'react-final-form'
import { Box, Button, Checkbox, FormControlLabel, Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { TextField } from 'mui-rff';
import { createLevel } from '../data/mappers/createLevel';
import { LEVELS, LEVEL_CREATE_API, LEVEL_DETAIL_API } from '../constants';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

import UploadIcon from '../icons/UploadIcon';
import CancelIcon from '@mui/icons-material/Cancel';
import { store } from '../store';
import { S3Client, DeleteObjectCommand, PutObjectCommand } from "@aws-sdk/client-s3";
// import TextFieldCommon from '../components/TextFieldCommon';

import { useDispatch } from 'react-redux';
import { HideProgressBar, ShowProgressBar, ShowErrorAlert, ShowSuccessAlert } from '../store/alerts';
import { isApiCodeSucess, postRequest } from '../data/helpers/services';
import { AccessTokenContext } from '../routes';
// import AWS from "aws-sdk";
import Compressor from 'compressorjs';

// AWS.config.update({
//   accessKeyId: process.env.REACT_APP_S3_ACCESS_ID,
//   secretAccessKey: process.env.REACT_APP_S3_ACCESS_KEY,
// });


const s3Client = new S3Client({
    credentials: {
        accessKeyId: process.env.REACT_APP_S3_ACCESS_ID,
        secretAccessKey: process.env.REACT_APP_S3_ACCESS_KEY
    },
    region: process.env.REACT_APP_S3_REGION
});

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET


// const myBucket = new AWS.S3({
//     params: { Bucket: S3_BUCKET },
//     region: process.env.REACT_APP_S3_REGION,
//   });

export default function LevelFrom(props: any) {
    const { modeType } = props;

    const { uid, version } = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const getCookieAccessToken: any = useContext(AccessTokenContext)
    let accessToken: string = getCookieAccessToken.cookieAccessToken


    const [isNewLevel, SetIsNewLevel] = useState(true)

    const { levelId } = useParams();
    const initialFormData = {
        level: "",
        scale: "",
        level_id: ""
    }
    const [initialForm, setInitialForm] = useState(initialFormData);

    const [imageObj, setImageObj] = useState({
        uploadStarted: false,
        uploaded: false,
        media: ""
    })

    const [responseFetched, setResponseFetched] = useState(false)

    useEffect(() => {
        if (modeType === 'add') {
            getAllLevelList();
        }
        else if (modeType === 'edit') {
            getLevelDetail();
        }
    }, []);

    const getAllLevelList = async () => {
        let res: any = {}

        let version = localStorage.getItem('level_version')
        res = await postRequest(LEVELS, {
            uid: uid
        }, accessToken,
            (version ? version : "")
        );
        if (res) {
            if (isApiCodeSucess(res)) {
                let ContentData = res.data?.data.level_list
                let newlevel = 0
                if (ContentData.length === 0) {
                    newlevel = 1
                } else {
                    newlevel = (ContentData[ContentData.length - 1]).level_no + 1

                }
                setInitialForm({ ...initialForm, level: `${newlevel}` })
            }
        }
    }

    const getLevelDetail = async () => {
        let res: any = {}

        let version = localStorage.getItem('level_version')

        res = await postRequest(LEVEL_DETAIL_API, {
            level_id: levelId,
            uid: uid
        }, accessToken,
            (version ? version : "")
        );


        if (res) {
            if (isApiCodeSucess(res)) {
                let ContentData = res.data?.data
                setInitialForm({
                    scale: `${ContentData.scaling}`,
                    level: ContentData.level_no,
                    level_id: ContentData.id
                })
                SetIsNewLevel(ContentData.is_new_level)
                setImageObj({
                    uploaded: true,
                    uploadStarted: false,
                    media: ContentData.character_image_name
                })
            }
        }
    }



    let handleSubmit = async (event) => {
        if (!imageObj.media) {
            dispatch(ShowErrorAlert({ visible: true, message: "Character image is required" }));
        } else {
            dispatch(ShowProgressBar());
            let data = createLevel(event, imageObj.media, modeType, isNewLevel, uid);

            var response: any = null;
            let version = localStorage.getItem('level_version')

            response = await postRequest(LEVEL_CREATE_API, data, accessToken, (version ? version : ""));

            if (response) {
                dispatch(HideProgressBar());
                if (isApiCodeSucess(response)) {
                    const resData = response.data
                    dispatch(ShowSuccessAlert({ visible: true, message: resData.message }));
                    if (uid) {
                        navigate(`/dashboard/${uid}/${version}/level`)
                    } else {
                        navigate('/dashboard/level/list/' + version)
                    }
                }
            }
        }
    }


    const removeImages = async (image) => {
        const input = {
            Bucket: S3_BUCKET,
            Key: image
        }
        const command = new DeleteObjectCommand(input);
        await s3Client.send(command);

        // setImageArray(tempArray)
    }

    const imageProps = {
        imageObj: imageObj,
        setImageObj: setImageObj,
        uid: uid
    }

    return (
        <>
            <div>
                <div
                    style={{
                        marginTop: "-80px"
                    }}
                >
                    <ArrowCircleLeftIcon
                        fontSize="large"
                        color="secondary"
                        onClick={() => navigate(-1)}
                        sx={{
                            marginBottom: "10px",
                            marginLeft: "10px",
                            "&:hover": {
                                color: "#103996",
                                cursor: "pointer",
                            }
                        }}
                    />
                </div>
                <Form
                    initialValues={{ ...initialForm }}
                    onSubmit={handleSubmit}>
                    {
                        (props: any) => (
                            <form onSubmit={props.handleSubmit}>
                                <Stack direction="row" sx={{ flexWrap: "wrap", mt: "6%" }} justifyContent={"space-around"}>

                                    <input type="hidden" name={`level_id`} />

                                    <TextField
                                        label="Level" name={`level`}
                                        sx={{
                                            width: "25%",
                                        }} type="number"
                                        required={true}
                                        disabled
                                    />

                                    <TextField
                                        label="Scale" name={`scale`}
                                        sx={{
                                            width: "25%",
                                        }}
                                        type="number"
                                        required={true}
                                    />

                                    <ImageUpload {...imageProps} />
                                </Stack>

                                <div className="button-section"
                                    style={{
                                        marginLeft: "3%"
                                    }}
                                >
                                    <Button type="submit" variant="contained">
                                        Submit
                                    </Button>
                                </div>
                            </form>
                        )
                    }
                </Form>
            </div>
        </>
    )
}

function ImageUpload(props: any) {
    const [progress, setProgress] = useState(0);

    const { dispatch } = store;

    const { imageObj, setImageObj, uid } = props



    const uploadFile = async (file) => {
        new Compressor(file, {
            quality: 0.25, // Compression quality (0.8 = 80% compression)
            success: (compressedFile) => {
                s3UploadFile(compressedFile);
            },
            error: (error) => {
                console.log('Error compressing file:', error);
            },
        });
    };


    const s3UploadFile = async (file) => {
        if (file.name.includes(" ")) {
            dispatch(ShowErrorAlert({ visible: true, message: "Image file name contains spaces kindly remove" }));
            return false
        }
        let folder = `World/${file.name}`

        let key = "superhero/" + folder
        if (uid) {
            key = `V3_Clone/${uid}/${folder}`
        }

        const input = {
            ACL: "public-read",
            Body: file,
            Bucket: S3_BUCKET,
            Key: key,
            ContentType: 'image/jpeg',
        }

        // const command = new HeadObjectCommand(input);
        const command = new PutObjectCommand(input);

        try {
            let res = await s3Client.send(command);
            if (res && res['$metadata']['httpStatusCode'] === 200) {
                setImageObj({ ...imageObj, media: folder, uploaded: true, uploadStarted: false })
            }
        }
        catch (err) {
            console.log(err)
        }


        // const params = {
        //     ACL: "public-read",
        //     Body: file,
        //     Bucket: S3_BUCKET,
        //     Key: ("superhero/"+folder),
        //     ContentType: 'image/jpeg',
        // };

        // await myBucket
        //     .putObject(params)
        //     .on("httpUploadProgress", (evt) => {
        //     if ((Math.round((evt.loaded / evt.total) * 100) === 100)){
        //         setImageObj({...imageObj, media:folder, uploaded:true, uploadStarted:false })
        //     }
        //     setProgress(Math.round((evt.loaded / evt.total) * 100));
        //     })
        //     .send((err) => {
        //     if (err) console.log(err);
        // });
        return true
    };

    const handleFileSelect = async (event: any) => {
        await uploadFile(event.target.files[0])
        return true;
    };

    const handleFileRemove = async (event: any) => {
        const input = {
            Bucket: S3_BUCKET,
            Key: imageObj.media
        }
        const command = new DeleteObjectCommand(input);
        await s3Client.send(command);
        setImageObj({ ...imageObj, uploadStarted: false, uploaded: false, media: "" })
    }

    return (
        <>
            <div
                className="col-12 md-col-4"
                style={{
                    width: "30%",
                    alignItems: "",
                    justifyContent: "flex-start"
                }}
            >
                <label
                    className='input--label'
                    htmlFor="thumbnail"
                >
                    Character image
                </label>
                <Field
                    name={"character"}
                    type="hidden"
                    component="input"
                    id="thumbnail"
                    placeholder="thumbnail"
                />
                <Field
                    name="id"
                    type="hidden"
                    component="input"
                    id="id"
                    placeholder="id"
                />

                {(!imageObj.uploaded) ?
                    (<Box component="div"
                        sx={{
                            width: '20%',
                            background: '#232157',
                            marginBottom: "20px",
                            position: 'relative',
                            borderRadius: "15px",
                            cursor: "pointer",
                            aspectRatio: '4/3'
                        }}
                    >
                        <Box
                            component="div"
                            sx={{
                                position: 'absolute',
                                left: '50%',
                                top: '50%',
                                transform: 'translate(-50%,-50%)',
                            }}
                        >
                            <UploadIcon />
                        </Box>
                        <Box
                            component="input"
                            type="file"
                            name="thumbnail"
                            onChange={handleFileSelect}
                            sx={{
                                height: '100%',
                                width: '100%',
                                opacity: '0',
                                cursor: 'pointer',
                            }}
                        ></Box>
                    </Box>
                    ) : (imageObj.uploaded || imageObj.uploadStarted) ? (
                        <>
                            <Box className="img--uploadBg" style={{
                                minHeight: '100px',
                                maxHeight: '100px'
                            }}>
                                {(!imageObj.uploaded) ?
                                    '' :
                                    <button type='button' className="remove--pic" onClick={handleFileRemove}>
                                        <CancelIcon />
                                    </button>
                                }
                                <Box
                                    component="img"
                                    src={

                                        uid ?

                                            `${process.env.REACT_APP_S3_BASE_PATH_CLONE}${uid}/${imageObj.media}`

                                            :
                                            `${process.env.REACT_APP_S3_BASE_PATH}${imageObj.media}`


                                    }
                                    sx={{
                                        maxHeight: '20%',
                                        maxWidth: '40%',
                                        objectFit: 'contain',
                                        borderRadius: '15px',
                                        margin: 'auto'
                                    }}
                                ></Box>
                            </Box>
                        </>
                    ) : ""
                }
            </div>
        </>
    )
}

