import React, { useEffect } from 'react';

let NoPinchZoom = ({ children }) => {
  useEffect(() => {
    // Set touch-action property
    document.body.style.touchAction = 'manipulation';

    // Set viewport meta tag
    const metaTag = document.createElement('meta');
    metaTag.name = 'viewport';
    metaTag.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no';
    document.head.appendChild(metaTag);

    const isPWA = window.matchMedia('(display-mode: standalone)').matches;
    // Prevent context menu (right-click or long press)
    const preventContextMenu = (event) => {
      event.preventDefault();
    };

    if (isPWA) {
      document.addEventListener('contextmenu', preventContextMenu);
    }
    return () => {
      // Cleanup on component unmount
      document.body.style.touchAction = '';
      document.head.removeChild(metaTag);
      {
        if (isPWA) {
          document.removeEventListener('contextmenu', preventContextMenu);
        }
      }

    };
  }, []);
  return <>{children}</>;
};

export default NoPinchZoom;
