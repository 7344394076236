import { Button, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { Form, Field } from 'react-final-form'
import { AppVersionApis } from '../../constants';
import { isApiCodeSucess, postRequest } from '../../data/helpers/services';
import { AccessTokenContext } from '../../routes';
import CustomToggle from '../../components/CustomToggle';
import { TextField } from 'mui-rff';
import { useDispatch } from 'react-redux';
import { HideProgressBar, ShowProgressBar, ShowSuccessAlert } from '../../store/alerts';

const initialFormData = {
  android_version: "",
  ios_version: ""
}


const initialAppStoreFormData = {
  android_store_version: "",
  ios_store_version: "",
  // title: "",
  // description: ""
}


export default function SupHerAppVersion() {  
  const dispatch = useDispatch();

  const getCookieAccessToken:any = useContext(AccessTokenContext)
  let accessToken:string = getCookieAccessToken.cookieAccessToken

  const [initialForm, setInitialForm] = useState(initialFormData);
  const [initialAppStoreForm, setInitialAppStoreForm] = useState(initialAppStoreFormData);

  const [ androidBgDownload, setAndroidBgDownload ] = useState(false);
  const [ iosBgDownload, setIosBgDownload ] = useState(false);

  useEffect(()=>{
    getAppVersion()
  },[])

  const getAppVersion = async() =>{
      let res:any = {}
  
      res = await postRequest(
          AppVersionApis.SUPERHERO_APP_VERSION_LIST, "" , accessToken);
      if (res) {
          if (isApiCodeSucess(res)) {
              let responseData = res.data.data

              setAndroidBgDownload(responseData.android_download_levels)
              setIosBgDownload(responseData.ios_download_levels)

              setInitialForm({
                android_version: responseData.android_version,
                ios_version: responseData.ios_version
              })

              setInitialAppStoreForm({
                android_store_version: responseData.android_store_version,
                ios_store_version: responseData.ios_store_version,
                // title: responseData.title,
                // description: responseData.description
              })
          }
      }
  }

  const handleSubmit = async(e) => {
    dispatch(ShowProgressBar());
    let data = {
        android_version: e.android_version,
        ios_version: e.ios_version
      };

    var response: any = null;

    response = await postRequest(AppVersionApis.SUPERHERO_APP_VERSION_UPDATE, data, accessToken);

    if (response) {
        dispatch(HideProgressBar());
        if (isApiCodeSucess(response)) {
            const resData = response.data
            dispatch(ShowSuccessAlert({visible:true, message:resData.message}));
        }
    }
  }



  const handleStoreAppSubmit = async(e) => {
    dispatch(ShowProgressBar());
    let data = {
        android_version: e.android_store_version,
        ios_version: e.ios_store_version,
        title: "New Version avialable",
        description: "New Version avialable",
      };

    var response: any = null;
    response = await postRequest(AppVersionApis.SUPERHERO_APP_STORE_VERSION_UPDATE, data, accessToken);

    if (response) {
        dispatch(HideProgressBar());
        if (isApiCodeSucess(response)) {
            const resData = response.data
            dispatch(ShowSuccessAlert({visible:true, message:resData.message}));
        }
    }
  }



  const handleAndroidBgSubmit = async(e) => {
    dispatch(ShowProgressBar());
    let data = {
        device_type: "1",
        status: androidBgDownload ? "2" : "1"
      };

    var response: any = null;

    response = await postRequest(AppVersionApis.SUPERHERO_APP_BG_TOGGLE, data, accessToken);

    if (response) {
      dispatch(HideProgressBar());
      if (isApiCodeSucess(response)) {
        const resData = response.data
        dispatch(ShowSuccessAlert({visible:true, message:resData.message}));
        setAndroidBgDownload(!androidBgDownload)
        }
    }
  }

  const handleIosSubmit = async(e) => {
    dispatch(ShowProgressBar());
    let data = {
      device_type: "2",
      status: iosBgDownload ? "2" : "1"
    };

    var response: any = null;

    response = await postRequest(AppVersionApis.SUPERHERO_APP_BG_TOGGLE, data, accessToken);

    if (response) {
        dispatch(HideProgressBar());
        if (isApiCodeSucess(response)) {
            const resData = response.data
            dispatch(ShowSuccessAlert({visible:true, message:resData.message}));
            setIosBgDownload(!iosBgDownload)
        }
    }
  }


  const customAndroidBgDownloadToggleProps = {
    onToggleHandle: handleAndroidBgSubmit,
    checked: androidBgDownload,
    label: "Android background download"
  }


  const customIosBgDownloadToggleProps = {
    onToggleHandle: handleIosSubmit,
    checked: iosBgDownload,
    label: "Ios background download"
  }

  return (
    <>
    <PageStyles />
    <div className="app-version">
      
      <div style={{marginLeft:"3%", fontWeight:700, marginTop:"-10px" }}>
        For Ads
      </div>

      <div className='app-version-form'>
        <Form
        initialValues={{ ...initialForm }}

        onSubmit={handleSubmit}>
            {
            (props:any) => (
                <form onSubmit={props.handleSubmit}>
                  <Stack direction="row">
                    <input type="hidden" name={`level_id`}/>
                    <TextField 
                        label="Android verion" name={`android_version`} 
                        sx={{
                            width:"25%",
                            mt:"4%",
                            mr: "5%",
                            // ml: "5%"
                            ml: "65px"
                        }} type="number"
                        required={true}
                    />
                    <TextField 
                        label="ios version" name={`ios_version`} 
                        sx={{
                            width:"25%",
                            mt:"4%"
                        }}
                        type="number" 
                        required={true}
                    />

                    <div className="button-section"
                      style={{
                          width:"15%",
                          marginLeft: "7%",
                          marginTop:"4.5%"
                      }}
                    >
                      <Button type="submit" variant="contained">
                          Save
                      </Button>
                    </div>
                  </Stack>
                </form>
              )
            }
        </Form>
      </div>
    </div>
    
    {/* <div className="Bg-toggles">
      <CustomToggle {...customAndroidBgDownloadToggleProps}/>
      <CustomToggle {...customIosBgDownloadToggleProps}/>
    </div> */}

    <div className="app-version">
      <div style={{marginLeft:"3%", fontWeight:700, marginTop: "20px"}}>
        Force update
      </div>

      <div className=''>
        <Form
        initialValues={{ ...initialAppStoreForm }}

        onSubmit={handleStoreAppSubmit}>
            {
            (props:any) => (
                <form onSubmit={props.handleSubmit}>
                  <Stack direction="row">
                    <input type="hidden" name={`level_id`}/>
                    <TextField 
                        label="Android" name={`android_store_version`} 
                        sx={{
                            width:"25%",
                            mt:"4%",
                            mr: "5%",
                            ml: "65px"
                        }} 
                        onInput={(e: any) => {
                          const target = e.target;
                          target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                        type="number" 
                        required={true}
                    />
                    <TextField 
                        label="ios" name={`ios_store_version`} 
                        sx={{
                            width:"25%",
                            mt:"4%"
                        }}
                        onInput={(e: any) => {
                          const target = e.target;
                          target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                        type="number" 
                        required={true}
                    />
                  {/* </Stack>

                  <Stack direction="row">

                    <TextField 
                        label="Title (50 Chars max)" name={`title`} 
                        sx={{
                            width:"25%",
                            mt:"4%",
                            mr: "5%",
                            ml: "65px"
                        }} type="text" 
                        inputProps={{
                          maxLength: 50
                        }}
                        required={true}
                    />

                    <TextField 
                        label="Desctiption (100 Chars max)" name={`description`} 
                        sx={{
                            width:"50%",
                            mt:"4%",
                            mr: "5%",
                        }} type="text" 
                        // multiline
                        // rows={3}
                        inputProps={{
                          maxLength: 100
                        }}
                        required={false}
                    />
                  </Stack> */}

                    <div className="button-section"
                      style={{
                          width:"15%",
                          marginLeft: "7%",
                          marginTop:"4.5%"
                      }}
                    >
                      <Button type="submit" variant="contained">
                          Save
                      </Button>
                    </div>
                  </Stack>
                </form>
              )
            }
        </Form>
      </div>
    </div>
    </>
  )
}


export function PageStyles() {
  return (
    <>
        <style>
            {`
              .app-version {
                margin-left:10px;
                width:950px;
              }

              .app-version-form {
                border-bottom: 1px dotted #ccc !important;
                padding-bottom: 30px;
              }

              .Bg-toggles {
                margin-top: 50px;
                margin-left: 65px
              }
            `}
        </style>
    </>
  )
}

