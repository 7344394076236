import React, {useContext, useEffect, useState} from 'react'
import { Form, Field } from 'react-final-form'
import { Box, Button, Checkbox, Container, FormControlLabel, Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { TextField } from 'mui-rff';
import { createGroup } from '../../data/mappers/TinyLearner/createGroup';
import { MEDIA_DELETE, TinyLearnerEndpoints } from '../../constants';

import UploadIcon from '../../icons/UploadIcon';
import CancelIcon from '@mui/icons-material/Cancel';
import { store } from '../../store';
import { S3Client, DeleteObjectCommand, PutObjectCommand } from "@aws-sdk/client-s3";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

import { useDispatch } from 'react-redux';
import {
    HideProgressBar,
    ShowProgressBar,
    ShowErrorAlert,
    ShowSuccessAlert
} from '../../store/alerts';
import { isApiCodeSucess, postRequest, tlPatchRequest, tlPostRequest } from '../../data/helpers/services';
import { AccessTokenContext } from '../../routes';
// import AWS from "aws-sdk";
import ZipLogo from './ZipLogo';
import { Helmet } from 'react-helmet-async';

// AWS.config.update({
//   accessKeyId: process.env.REACT_APP_S3_ACCESS_ID,
//   secretAccessKey: process.env.REACT_APP_S3_ACCESS_KEY,
// });


const s3Client = new S3Client({
    credentials: {
        accessKeyId: process.env.REACT_APP_S3_ACCESS_ID,
        secretAccessKey: process.env.REACT_APP_S3_ACCESS_KEY
    },
    region: process.env.REACT_APP_S3_REGION
});

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET

// const myBucket = new AWS.S3({
//     params: { Bucket: S3_BUCKET },
//     region: process.env.REACT_APP_S3_REGION,
//   });

export default function GroupForm(props:any) {
    const { modeType } = props;

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const getCookieAccessToken:any = useContext(AccessTokenContext)
    let accessToken:string = getCookieAccessToken.cookieAccessToken

    const { groupId } = useParams();
    const initialFormData = {
        group_index: "",
        group_name: "",
        group_id:""
    }
    const [initialForm, setInitialForm] = useState(initialFormData);

    const [zipObj, setZipObj] = useState({
        uploadStarted: false, 
        uploaded: false,
        media: "",
        media_name: "",
        group_index: ""
    })

    const [ responseFetched, setResponseFetched] = useState(false)

    useEffect(() => {
        if (modeType === 'add'){
            getAllGroupList();
        }
        else if (modeType === 'edit'){
            getGroupDetail();
        }
      }, []);

    const getAllGroupList = async() => {
        let res:any = {}

        res = await tlPostRequest(
            TinyLearnerEndpoints.GROUPS,
            "" ,
            accessToken
        );
        if (res) {
            if (isApiCodeSucess(res)) {
                let ContentData = res.data?.data.group_list;
                let new_group_index = ContentData.length ? ((ContentData[ContentData.length - 1]).group_no + 1) : 1;
                setInitialForm({
                    ...initialForm,
                    group_index:`${new_group_index}`}
                )
            }
        }
    }

    const getGroupDetail = async() => {
        let res:any = {}

        res = await tlPostRequest(
            TinyLearnerEndpoints.GROUP_DETAIL,
            {
                "group_id": groupId
            },
            accessToken
        );

        
        if (res) {
            if (isApiCodeSucess(res)) {
                let ContentData = res.data?.data;
                setInitialForm({ 
                    group_index:`${ContentData.group_no}`,
                    group_name: ContentData.group_name,
                    // group_id: ContentData.id
                    group_id: groupId
                })
                let group_zip_name = "";
                if (ContentData.group_media_name) {
                    const zip_media = ContentData.group_media_name;
                    const lastIndex = zip_media.lastIndexOf("/");
                    const group_zip_name = zip_media.slice(lastIndex + 1);
                }
                setZipObj({
                    uploaded: ContentData.group_media_name ? true : false,
                    uploadStarted:false,
                    media: ContentData.group_media_name,
                    media_name: group_zip_name,
                    group_index: ContentData.group_no
                })
            }
        }
    }


    let handleSubmit = async (event) => {
        dispatch(ShowProgressBar());
        console.log("event",event)
        let data = createGroup(event, zipObj.media, modeType);
        var response: any = null;

        if (modeType == "add") {
            response = await tlPostRequest(
                TinyLearnerEndpoints.GROUP_CREATE_EDIT,
                data,
                accessToken
            );
        }
        else {
            response = await tlPatchRequest(
                TinyLearnerEndpoints.GROUP_CREATE_EDIT,
                data,
                accessToken
            );
        }

        if (response) {
            dispatch(HideProgressBar());
            if (isApiCodeSucess(response)) {
                const resData = response.data
                dispatch(ShowSuccessAlert({visible:true, message:resData.message}));
                navigate('/tinylearner/group')
            }
        }
    }


    const removeImages = async(image) =>{
        const input = {
            Bucket: S3_BUCKET,
            Key: image
        }
        const command = new DeleteObjectCommand(input);
        await s3Client.send(command);
        
        // setImageArray(tempArray)
    }

    const  zipProps = {
        zipObj: zipObj,
        setZipObj: setZipObj
    }

    return (
        <>
            <div
                style={{
                    marginTop: "-80px"
                    }}
                    >
                <ArrowCircleLeftIcon
                    fontSize="large"
                    color="secondary"
                    onClick={()=> navigate(-1)}
                    sx={{
                        marginBottom: "10px",
                        marginLeft: "10px",
                        "&:hover": {
                            color: "#103996",
                            cursor:"pointer",
                        }
                    }}
                />  
            </div>
            <div>
                <span
                    style={{
                        color: 'inherit',
                        fontFamily: 'sans-serif',
                        fontSize: '19px',
                        fontWeight: "bold",
                        marginLeft: "3%"
                    }}
                >
                    Create Group
                </span>
            </div>
            <div>  
                <Form
                    initialValues={{...initialForm}}
                    onSubmit={handleSubmit}>
                        {
                        (props:any) => (
                            <form onSubmit={props.handleSubmit}>
                                {/* <div className='row'
                                    style={{
                                        display: "grid",
                                        gridTemplateColumns: "repeat(2,1fr)",
                                        gridColumnGap: "1rem"
                                    }}
                                >         */}

                                <Stack direction="row">

                                    <input type="hidden" name={`group_id`}/>

                                    <TextField 
                                        label="Index" name={`group_index`} 
                                        sx={{
                                            width:"30%",
                                            mt:"4%",
                                            mr: "5%",
                                            ml: "3%"
                                        }} type="number" 
                                        required={true}
                                        disabled /> 

                                    <TextField 
                                        label="Name" name={`group_name`} 
                                        sx={{
                                            width:"30%",
                                            mt:"4%"
                                        }} type="text" 
                                        required={true} />

                                    
                                    <ZipUpload
                                        {...zipProps}
                                        groupId={`${groupId}`}
                                    />   
                                </Stack>
                                
                                <Stack direction="row">
                                    <div
                                        className="button-section"
                                        style={{
                                            width:"30%",
                                            marginTop:"2%",
                                            marginRight: "5%",
                                            marginLeft: "3%"
                                        }}
                                    >
                                        <Button type="submit" variant="contained">
                                            Submit
                                        </Button>
                                    </div>
                                </Stack>
                            </form>
                            )
                        }
                    </Form>
            </div>
        </>
    )
}

function ZipUpload(props:any) {
    const [progress, setProgress] = useState(0);
    const { dispatch } = store;

    const getCookieAccessToken:any = useContext(AccessTokenContext)
    let accessToken:string = getCookieAccessToken.cookieAccessToken

    const {zipObj, setZipObj, groupId} = props;
    const uploadFile = async (file) => {

        var filePath = file.name;
        var allowedExtensions = /(\.zip)$/i;        
        if(!allowedExtensions.exec(filePath)){
            dispatch(
                ShowErrorAlert({
                    visible:true,
                    message: "Please upload file having extensions .zip only."
                })
            )
            return false
        }
    
        
        if (file.name.includes(" ")){
            dispatch(ShowErrorAlert({visible:true, message: "Zip file name contains spaces kindly remove"}));
            return false
        }
        let folder = `${file.name}`
        const input = {
            ACL: "public-read",
            Body: file,
            Bucket: S3_BUCKET,
            Key: ("tiny_learner/group/"+folder),
            ContentType: 'application/zip',
        }

        // const command = new HeadObjectCommand(input);
        const command = new PutObjectCommand(input);

        try{
            let res = await s3Client.send(command);
            if (res && res['$metadata']['httpStatusCode'] === 200){
                zipObj.media_name = `${file.name}`
                setZipObj({...zipObj, media:folder, uploaded:true, uploadStarted:false })
            }
        }
        catch(err){
            console.log(err)
        }

        // const params = {
        //     ACL: "public-read",
        //     Body: file,
        //     Bucket: S3_BUCKET,
        //     Key: ("tiny_learner/group/"+folder),
        //     ContentType: 'application/zip',
        // };

        // await myBucket
        //     .putObject(params)
        //     .on("httpUploadProgress", (evt) => {
        //     if ((Math.round((evt.loaded / evt.total) * 100) === 100)){
        //         zipObj.media_name = `${file.name}`
        //         // zipObj.group_index = 
        //         setZipObj({...zipObj, media:folder, uploaded:true, uploadStarted:false })
        //     }
        //     setProgress(Math.round((evt.loaded / evt.total) * 100));
        //     })
        //     .send((err) => {
        //     if (err) console.log(err);
        // });
        return true
    };

    const handleFileSelect = async (event: any) => {
        await uploadFile(event.target.files[0])
        return true;
    };

    const handleFileRemove = async (event: any) => {
        const media_path = "tiny_learner/group/" + zipObj.media;
        const input = {
          Bucket: S3_BUCKET,
          Key: media_path
        }
        const command = new DeleteObjectCommand(input);
        const status = await s3Client.send(command);

        const res = await postRequest(
            MEDIA_DELETE,
            {
                "id": groupId,
                "media_type": "TL_GP"   // Tiny learner group
            },
            accessToken
        );
        zipObj.uploaded = false;
        setZipObj({...zipObj, uploadStarted:false, uploaded:false, media:""})
    }

    let group_no = groupId;
    if (!group_no || group_no === null || group_no === "undefined") {
        group_no = "";
    }
  return (
    <>
        <div className="col-12 md-col-4" 
            style={{
                width:"33%",
                marginLeft: "5%",
                marginTop: "25px"
            }}
        >
            <label
                className='input--label'
                htmlFor="thumbnail"
                style={{
                    marginLeft: "5px"
                }}
            >
                {/* Group Zip */}
                {
                    zipObj.media
                    ?
                    zipObj.media
                    :
                    <span>Group Zip</span>
                }
            </label>
            <Field
                name={"character"}
                type="hidden"
                component="input"
                id="thumbnail"
                placeholder="thumbnail"
            />
            <Field
                name="id"
                type="hidden"
                component="input"
                id="id"
                placeholder="id"
            />

            {(!zipObj.uploaded) ?
                (<Box component="div"
                    sx={{
                        width: '20%',
                        background: '#232157',
                        marginBottom: "20px",
                        position: 'relative',
                        borderRadius: "15px",
                        cursor: "pointer",
                        aspectRatio: '4/3'
                    }}
                >
                    <Box
                        component="div"
                        sx={{
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50%,-50%)',
                        }}
                    >
                        <UploadIcon />
                    </Box>
                    <Box
                        component="input"
                        type="file"
                        name="thumbnail"
                        onChange={handleFileSelect}
                        sx={{
                            height: '100%',
                            width: '100%',
                            opacity: '0',
                            cursor: 'pointer',
                        }}
                    ></Box>
                </Box>
                ) : (zipObj.uploaded || zipObj.uploadStarted) ? (
                    <>
                        <Box className="img--uploadBg" style={{
                            minHeight: '100px',
                            maxHeight: '100px'
                        }}>
                            {/* {(!zipObj.uploaded)
                            ?
                            ''
                            : 
                                <button
                                    type='button'
                                    className="remove--pic"
                                    onClick={handleFileRemove}
                                >
                                    <CancelIcon />
                                </button>
                            } */}
                            <div
                                    style={{
                                        position: 'relative',
                                        display: 'inline-block',
                                        marginTop: "12px"
                                    }}

                                >
                                    {/* <img
                                        src={'/assets/zip.svg'}
                                        style={{
                                            width: '65px',
                                            height: '65px',
                                            borderRadius: '50%'
                                        }}
                                    // onClick={() =>setOpen({state:true, media:`${BASE_URL}${imageObj.media}`})}
                                    //     // onClick={() =>  {console.log("CK")}}
                                    /> */}
                                    <ZipLogo
                                        username={"username"}
                                        logoText={zipObj.group_index ? zipObj.group_index : ""}
                                    />
                                    {
                                        (!zipObj.uploaded)
                                        ?
                                        ""
                                        :
                                        <svg
                                            viewBox="0 0 24 24"
                                            onClick={handleFileRemove}
                                            style={{
                                                position: 'absolute',
                                                top: '-10px',
                                                right: '-10px',
                                                width: '20px',
                                                height: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            <path
                                                fill="#FF0000"
                                                d="M12,0C5.373,0,0,5.373,0,12s5.373,12,12,12s12-5.373,12-12S18.627,0,12,0z M16.293,15.707l-1.414,1.414L12,13.414l-2.879,2.879l-1.414-1.414L10.586,12L7.707,9.121l1.414-1.414L12,10.586l2.879-2.879l1.414,1.414L13.414,12L16.293,15.707z"
                                            />
                                        </svg>
                                    }   
                                </div>
                        </Box>
                    </>
                ) : ""
                }
            </div>
            </>
  )
}

