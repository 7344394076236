const ZipLogo = ({ username, logoText }) => {
    const logoUrl = '/assets/zip1.svg'

    const logoStyle = {
        backgroundImage: `url(${logoUrl})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        width: '55px',
        height: '55px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // position: 'relative'
    };
    const letterStyle = {
        fontSize: '48px',
        fontWeight: 'bold',
        color: 'rgb(197 48 92)',
        marginTop: '-16px'
    };
    return (
        <div style={logoStyle}>
        </div>
    );
};


export default ZipLogo;

