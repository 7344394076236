import PropTypes from 'prop-types';
// @mui
import { Box, Card, Stack, Button, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Switch } from '@mui/material';

import { Link, useNavigate } from 'react-router-dom';
import ZipLogo from '../../../pages/tinyLearner/ZipLogo';
// import AWS from "aws-sdk";
import { useContext, useState } from 'react';
import { MEDIA_DELETE, TinyLearnerEndpoints } from '../../../constants';
import { tlPatchRequest, isApiCodeSucess, postRequest, tlPostRequest, } from '../../../data/helpers/services';
import { createGroup } from '../../../data/mappers/TinyLearner/createGroup';
import UploadIcon from '../../../icons/UploadIcon';
import { AccessTokenContext } from '../../../routes';
import { HideProgressBar, ShowErrorAlert, ShowSuccessAlert } from '../../../store/alerts';
import { TextField as MUITextField } from '@mui/material';
import LevelIcon from '../../../static/LevelIcon'
import EditIcon from '../../../static/EditIcon'
import DeleteIcon from '../../../static/DeleteIcon'



import { useDispatch } from 'react-redux';
import { DeleteObjectCommand, PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import UploadIcon1 from '../../../static/UploadIcon1';
import CheckMark from '../../../static/CheckMark';
import CrossMark from '../../../static/CrossMark';

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const REGION = process.env.REACT_APP_S3_REGION;
const BASE_URL = process.env.REACT_APP_S3_BASE_PATH_TL;
const accessKeyId = process.env.REACT_APP_S3_ACCESS_ID;
const secretAccessKey = process.env.REACT_APP_S3_ACCESS_KEY;

const s3Client = new S3Client({
    credentials: {
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey
    },
    region: REGION
});

// AWS.config.update({
//     accessKeyId: accessKeyId,
//     secretAccessKey: secretAccessKey,
// });

// const myBucket = new AWS.S3({
//   params: { Bucket: S3_BUCKET },
//   region: REGION,
// });

GroupCard.propTypes = {
    product: PropTypes.object,
};


export default function GroupCard({ group, editableIndex, setEditableIndex, groups, setGroupList }) {
    let {
        id,
        groupIndex,
        groupName,
        groupMediaName,
        groupMediaPath,
        isActive,
        levelZipCount,
        zipSize
    } = group;


    if (zipSize) {
        if (Number(zipSize) > 1024) {
            zipSize = `${(zipSize / 1024).toFixed(2)} MB`;
        }
        else {
            zipSize = `${zipSize} KB`
        }
    }
    const dispatch = useDispatch();

    const getCookieAccessToken: any = useContext(AccessTokenContext)
    let accessToken: string = getCookieAccessToken.cookieAccessToken

    const [openDelete, setOpenDelete] = useState({ state: false, index: "" })
    const [groupOpenDelete, setGroupOpenDelete] = useState({ state: false, index: "" })
    const [groupNameState, setGroupNameState] = useState(groupName)

    const uploadFile = async (file) => {
        var filePath = file.name;
        var allowedExtensions = /(\.zip)$/i;
        if (!allowedExtensions.exec(filePath)) {
            dispatch(
                ShowErrorAlert({
                    visible: true,
                    message: "Please upload file having extensions .zip only."
                })
            )
            return false
        }


        if (file.name.includes(" ")) {
            dispatch(
                ShowErrorAlert({
                    visible: true,
                    message: "Zip file name contains spaces kindly remove"
                })
            );
            return false
        }

        let folder = `${file.name}`



        const input = {
            ACL: "public-read",
            Body: file,
            Bucket: S3_BUCKET,
            Key: ("tiny_learner/group/" + folder),
            ContentType: 'application/zip',
        }

        // const command = new HeadObjectCommand(input);
        const command = new PutObjectCommand(input);

        try {
            let res = await s3Client.send(command);
            if (res && res['$metadata']['httpStatusCode'] === 200) {
                let uploaded_zip_name = "";
                const lastIndex = folder.lastIndexOf("/");
                const after = folder.slice(lastIndex + 1);
                uploaded_zip_name = after;

                // if ((Math.round((evt.loaded / evt.total) * 100) === 100)){
                const dataObj = {
                    "group_id": `${id}`,
                    "group_index": `${groupIndex}`,
                    "group_name": `${groupNameState}`,
                }

                let data = createGroup(dataObj, folder, "edit");
                var response: any = null;

                response = await tlPatchRequest(
                    TinyLearnerEndpoints.GROUP_CREATE_EDIT,
                    data,
                    accessToken
                )
                if (response) {
                    dispatch(HideProgressBar());
                    if (isApiCodeSucess(response)) {
                        dispatch(ShowSuccessAlert({
                            visible: true,
                            message: `${uploaded_zip_name} Uploaded successfully.`
                        }));

                    }
                }

                let tempArray = []
                groups.forEach(x => {
                    if (x.id === id) {
                        x.groupName = groupNameState
                        x.groupMediaName = `${folder}`
                        x.groupMediaPath = `${BASE_URL}${folder}`
                    }
                    tempArray.push(x)
                })
                setGroupList(tempArray)

                // }
            }
        }
        catch (err) {
            console.log(err)
        }


        //   const params = {
        //     ACL: "public-read",
        //     Body: file,
        //     Bucket: S3_BUCKET,
        //     Key: ("tiny_learner/group/"+folder),
        //     ContentType: 'application/zip',
        //   };

        //     await myBucket
        //         .putObject(params)
        //         .on("httpUploadProgress", async (evt) => {
        //             let uploaded_zip_name = "";
        //             const lastIndex = folder.lastIndexOf("/");
        //             const after = folder.slice(lastIndex + 1);
        //             uploaded_zip_name = after;

        //             if ((Math.round((evt.loaded / evt.total) * 100) === 100)){
        //                 const dataObj = {
        //                     "group_id": `${id}`,
        //                     "group_index": `${groupIndex}`,
        //                     "group_name": `${groupNameState}`,
        //                 }

        //                 let data = createGroup(dataObj, folder, "edit");
        //                 var response: any = null;

        //                 response = await tlPatchRequest(
        //                     TinyLearnerEndpoints.GROUP_CREATE_EDIT,
        //                     data,
        //                     accessToken
        //                   )
        //                 if (response) {
        //                     dispatch(HideProgressBar());
        //                     if (isApiCodeSucess(response)) {
        //                         dispatch(ShowSuccessAlert({
        //                           visible:true,
        //                           message: `${uploaded_zip_name} Uploaded successfully.`
        //                       }));

        //                     }
        //                 }

        //                 let tempArray = []
        //                 groups.forEach(x => {
        //                     if (x.id === id){
        //                         x.groupName = groupNameState
        //                         x.groupMediaName = `${folder}`
        //                         x.groupMediaPath =  `${BASE_URL}${folder}`
        //                     }
        //                     tempArray.push(x)
        //                 })
        //                 setGroupList(tempArray)

        //             }
        //             // setLevelS3MediaName(folder);
        //             // setLevelMedia(uploaded_zip_name);
        //         })
        //         .send((err) => {
        //             if (err) console.log("err",err);
        //         });
        return true
    };

    const handleFileSelect = async (event: any) => {
        await uploadFile(event.target.files[0])
        return true;
    };


    const handleGroupNameChange = (event) => {
        setGroupNameState(event.target.value);
    };

    const resetGroupEditIndex = (group_id) => {
        setEditableIndex("-1");
    };

    const editGroup = (group_id) => {
        setEditableIndex(String(group_id));
    }

    const updateGroup = async (group_id) => {
        if (!groupMediaName && !groupMediaPath) {
            dispatch(ShowErrorAlert({ visible: true, message: `Group Zip is required.` }))
            return false;
        }
        else {
            const event = {
                "group_id": id,
                "group_index": groupIndex,
                "group_name": groupNameState,
                "group_media_name": groupMediaName,
                "group_media_path": groupMediaPath
            }

            let data = createGroup(event, groupMediaName, "edit");
            var response: any = null;
            response = await tlPatchRequest(
                TinyLearnerEndpoints.GROUP_CREATE_EDIT,
                data,
                accessToken
            );
            if (response) {
                dispatch(HideProgressBar());
                if (isApiCodeSucess(response)) {
                    const resData = response.data;
                    dispatch(ShowSuccessAlert({
                        visible: true,
                        message: `Group updated successfully.`
                    }));
                    setEditableIndex("-1");
                }
            }
        }
    }


    const deletionProps = {
        charId: id,
        setOpenDelete: setOpenDelete,
        openDelete: openDelete,
        groupMediaName: groupMediaName,
        accessToken: accessToken,
        groupIndex: groupIndex,
        groups: groups,
        setGroupList: setGroupList,
    }


    const groupDeletionProps = {
        charId: id,
        setGroupOpenDelete: setGroupOpenDelete,
        groupOpenDelete: groupOpenDelete,
        accessToken: accessToken,
    }

    let mediaUrlDownload = `${BASE_URL}${groupMediaName}`

    const updateGroupStatus = async (status) => {
        let res: any = {}

        res = await tlPostRequest(
            TinyLearnerEndpoints.GROUP_ACTIVE_INACTIVE,
            {
                group_id: id,
                status: (status ? "1" : "2")
            },
            accessToken
        );
        if (res) {
            if (isApiCodeSucess(res)) {
                const resData = res.data
                dispatch(ShowSuccessAlert({ visible: true, message: resData.message }));
                let tempArray = []
                groups.forEach(x => {
                    if (x.id === id) {
                        x.isActive = status
                    }
                    tempArray.push(x)
                })

                setGroupList(tempArray)
            }
        }
    }

    return (
        <>
            {/* <Card> */}
            <DeleteConfirmation
                {...deletionProps}
            />

            <GroupDeleteConfirmation
                {...groupDeletionProps}
            />
            {/* <Box sx={{
              pt: '2%',
              pb: '20%',
              position: 'relative'
          }}>

            <Stack
                justifyContent="left"
                direction="row"
                sx={{
                    p: 1
                }}
            >
                <Switch 
                    checked={isActive ? true : false} 
                    onChange = {() => {
                        updateGroupStatus(!isActive);
                    }}
                    color='secondary'
                    sx={{
                        // // "& .MuiSwitch-switchBase.Mui-checked": {
                        // //     color: "green !important",
                        // //     backgroundColor: 'rgb(187 219 178 / 16%)'
                        // //   },
                        // //   "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                        // //     backgroundColor: 'green !important'
                        // //   },
                        // //   "&:hover .MuiSwitch-switchBase": {
                        // //     color: 'brown'
                        // //   },
                        //   "& .MuiSwitch-switchBase.Mui-disabled": {
                        //     color: 'green !important',
                        //     backgroundColor: 'rgb(187 219 178 / 16%) !important' 
                        //   },
                        // //   "& .MuiSwitch-track.Mui-checked": {
                        // //     backgroundColor: 'green'
                        // //   }
                    }}
                />
            </Stack>   

            <Stack  justifyContent="right" direction="row" sx={{ p: 1 }}>
            {
                    (editableIndex===id)
                    ?
                    <div style={{marginTop: "-50px"}}>
                        <Button
                            variant="contained"
                            disableElevation
                            disableTouchRipple
                            color='error'
                            onClick= {() => {
                                setGroupOpenDelete({
                                    state: true,
                                    index: id,
                                })
                            }}
                            sx={{
                                minWidth: 'fit-content !important',
                                width: '38px',
                                pl: 0.2,
                                pr: 0.2,
                            }}
                            >
                            <DeleteIcon
                                // color='error'                
                                />
                    </Button>
                        <Button
                            variant="contained"
                            sx={{
                                pl: 0.5,
                                pr: 0.5,
                                marginLeft: "5px",
                            }}
                            onClick={() => {
                                updateGroup(id);
                            }}
                            >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            disableElevation
                            disableTouchRipple
                            color='error'
                            sx={{
                                pl: 0.8,
                                pr: 0.8,
                                marginLeft: "5px",
                                marginRight: "7px",
                            }}
                            onClick={() => {
                                resetGroupEditIndex(id);
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                    :
                    <EditIcon
                        fontSize='medium'
                        onClick={() => 
                            editGroup(id)  
                    }
                    sx={{
                        marginTop: "-50px"
                    }}
                    />
                }
            </Stack>

            <Stack direction="row" sx={{ p: 1 }}>

                <MUITextField
                    id="outlined-basic__4"
                    label="Group Name"
                    variant="outlined"
                    value={groupNameState}
                    sx={{
                        background: "#fff",
                        ml: '10px',
                    }}
                    onChange={handleGroupNameChange}
                    type="text"
                    disabled = { (editableIndex === id) ? false: true}
                />
            </Stack>

            <Stack
                spacing={2}
                justifyContent="center"
                direction="row"
                sx={{
                    p: 1
                }}
            >
          {(!groupMediaName)
            ?
            (
                <Box component="div"
                    sx={{
                        // width: '20%',
                        width: '100px',
                        background: '#232157',
                        marginBottom: "20px",
                        position: 'relative',
                        borderRadius: "15px",
                        cursor: "pointer",
                        aspectRatio: '4/3'
                    }}
                >
                    <Box
                        component="div"
                        sx={{
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50%,-50%)',
                            width: "60px"
                        }}
                    >
                        <UploadIcon />
                    </Box>
                    
                    <Box
                        component="input"
                        type="file"
                        name="thumbnail"
                        onChange={handleFileSelect}
                        sx={{
                            height: '100%',
                            width: '100%',
                            opacity: '0',
                            cursor: 'pointer',
                            left: '50%'
                        }}
                        disabled = { (editableIndex === id) ? false: true}
                    ></Box>
                </Box>
                )
                :
                (
                <>
                    <Box className="img--uploadBg" style={{
                        minHeight: '100px',
                        maxHeight: '100px'
                    }}>
                        <div
                            style={{
                                position: 'relative',
                                display: 'inline-block',
                                marginTop: "6px"
                            }}
                        >
                            <ZipLogo username={"username"} logoText={groupIndex} />

                            <Link to={mediaUrlDownload} target="_blank" download>
                                <FileDownloadIcon
                                    sx={{
                                        color:"green",
                                            position: 'absolute',
                                            top: '80px',
                                            right: '-15px',
                                            width: '25px',
                                            height: '25px',
                                            cursor: 'pointer'
                                    }}
                                />
                            </Link>
                            
                            {
                                (editableIndex === id) ?
                                  <svg
                                      viewBox="0 0 24 24"
                                      onClick={() => {
                                          setOpenDelete({
                                              state: true,
                                              index: id,
                                          })
                                      }}
                                      style={{
                                          position: 'absolute',
                                          top: '-5px',
                                        //   right: '-38px',
                                          right: '-15px',
                                          width: '20px',
                                          height: '20px',
                                          cursor: 'pointer'
                                      }}>
                                      <path
                                          fill="#FF0000"
                                          d="M12,0C5.373,0,0,5.373,0,12s5.373,12,12,12s12-5.373,12-12S18.627,0,12,0z M16.293,15.707l-1.414,1.414L12,13.414l-2.879,2.879l-1.414-1.414L10.586,12L7.707,9.121l1.414-1.414L12,10.586l2.879-2.879l1.414,1.414L13.414,12L16.293,15.707z"
                                          />
                                  </svg>
                              : ""
                            }
                        </div>
                    </Box>
                </>
            )
          }
          </Stack>

          <Stack
              spacing={1}
              justifyContent="center"
              direction="row"
              sx={{
                  p: 1,
                  mb: -5
              }}
            >
               <Link to={"/tinylearner/group/"+group.id}>
                    <Button type="button" variant="contained">
                        Levels ({levelZipCount})
                    </Button>
                </Link>
          </Stack>
        </Box> */}

            <Stack direction="row" sx={{
                display: "flex",
                justifyContent: "space-between",
            }}>
                <div style={{
                    width: "1050px",
                    height: "121px",
                    background: "#FFFFFF",
                    boxShadow: "0px 9px 18px rgba(0, 64, 205, 0.06)",
                    borderRadius: "20px",
                    display: "flex",
                    justifyContent: "space-between"
                }}>

                    <Stack direction="row" sx={{}}>

                        <div style={{
                            alignSelf: "center",
                            width: "249px",
                            justifyContent: "space-between",
                            marginLeft: "2%"
                        }}>

                            <MUITextField
                                id="outlined-basic__4"
                                label="Group Name"
                                variant="outlined"
                                value={groupNameState}
                                sx={{
                                    background: "#F6F6F9",
                                    borderRadius: "7px",
                                    height: "55px",
                                }}
                                onChange={handleGroupNameChange}
                                type="text"
                                disabled={(editableIndex === id) ? false : true}
                            />
                        </div>
                        <Link to={"/tinylearner/group/" + group.id} style={{
                            textDecoration: "none",
                            color: 'inherit',
                            alignSelf: "center",
                            cursor: "pointer",
                        }}>
                            <div style={{
                                marginLeft: "120px",
                                width: "120px",
                                display: "flex",
                                justifyContent: "space-between"
                            }}>
                                <LevelIcon />
                                <span style={{ marginLeft: "2px" }}>
                                    Levels-{levelZipCount}
                                </span>
                            </div>
                        </Link>


                        <div style={{
                            marginLeft: "120px",
                            alignSelf: "center"
                        }}>
                            <Switch
                                checked={isActive ? true : false}
                                onChange={() => {
                                    updateGroupStatus(!isActive);
                                }}
                                color='secondary'
                                sx={{
                                    "& .MuiSwitch-switchBase.Mui-disabled": {
                                        color: 'green !important',
                                        backgroundColor: 'rgb(187 219 178 / 16%) !important'
                                    },
                                }}
                            />
                        </div>



                        <div style={{
                            marginLeft: "120px",
                            alignSelf: "center",
                            marginTop: "-2%"
                        }}>
                            {(!groupMediaName)
                                ?
                                (
                                    <Box component="div"
                                        sx={{
                                            width: '100px',
                                            // background: '#232157',
                                            marginBottom: "20px",
                                            position: 'relative',
                                            borderRadius: "15px",
                                            cursor: "pointer",
                                            aspectRatio: '4/3'
                                        }}
                                    >
                                        <Box
                                            component="div"
                                            sx={{
                                                position: 'absolute',
                                                left: '10%',
                                                top: '50%',
                                                // transform: 'translate(-50%,-50%)',
                                                width: "60px"
                                            }}
                                        >
                                            {/* <UploadIcon /> */}
                                            <UploadIcon1 />
                                        </Box>

                                        <Box
                                            component="input"
                                            type="file"
                                            name="thumbnail"
                                            onChange={handleFileSelect}
                                            sx={{
                                                height: '100%',
                                                width: '100%',
                                                opacity: '0',
                                                cursor: 'pointer',
                                                left: '50%'
                                            }}
                                            disabled={(editableIndex === id) ? false : true}
                                        ></Box>
                                    </Box>
                                )
                                :
                                (
                                    <>
                                        <Box className="img--uploadBg" style={{
                                            minHeight: '55px',
                                            maxHeight: '55px'
                                        }}>
                                            <div
                                                style={{
                                                    position: 'relative',
                                                    display: 'inline-block',
                                                    marginTop: "6px"
                                                }}
                                            >
                                                <ZipLogo username={"username"} logoText={groupIndex} />
                                                <span style={{ whiteSpace: "nowrap" }}>
                                                    {zipSize}
                                                </span>
                                                {
                                                    (editableIndex === id) ?
                                                        <svg
                                                            viewBox="0 0 24 24"
                                                            onClick={() => {
                                                                setOpenDelete({
                                                                    state: true,
                                                                    index: id,
                                                                })
                                                            }}
                                                            style={{
                                                                position: 'absolute',
                                                                top: '-5px',
                                                                //   right: '-38px',
                                                                right: '-15px',
                                                                width: '20px',
                                                                height: '20px',
                                                                cursor: 'pointer'
                                                            }}>
                                                            <path
                                                                fill="#FF0000"
                                                                d="M12,0C5.373,0,0,5.373,0,12s5.373,12,12,12s12-5.373,12-12S18.627,0,12,0z M16.293,15.707l-1.414,1.414L12,13.414l-2.879,2.879l-1.414-1.414L10.586,12L7.707,9.121l1.414-1.414L12,10.586l2.879-2.879l1.414,1.414L13.414,12L16.293,15.707z"
                                                            />
                                                        </svg>
                                                        : ""
                                                }
                                            </div>
                                        </Box>
                                    </>
                                )
                            }
                        </div>

                        {
                            (!groupMediaName) ? ""
                                :
                                <div style={{
                                    // marginLeft:"100px",
                                    alignSelf: "center"
                                }}>
                                    <Link to={mediaUrlDownload} target="_blank" download>
                                        <FileDownloadIcon
                                            sx={{
                                                color: "white",
                                                background: "#C7CBD6",
                                                border: "1px solid #C7CBD6",
                                                borderRadius: "4px",
                                                cursor: 'pointer',
                                                marginLeft: "120px"

                                            }}
                                        />
                                    </Link>

                                </div>
                        }

                    </Stack>
                </div>

                <div style={{
                    width: "58px",
                    height: "121px",
                    background: "#FFFFFF",
                    boxShadow: "0px 9px 18px rgba(0, 64, 205, 0.06)",
                    borderRadius: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                }}>

                    {
                        (editableIndex === id)
                            ?
                            <>
                                <div
                                    style={{
                                        alignSelf: 'center',
                                        marginBottom: "50%"
                                    }}
                                    onClick={() => {
                                        updateGroup(id);
                                    }}>
                                    <CheckMark />
                                </div>
                                <div style={{
                                    alignSelf: 'center'
                                }}
                                    onClick={() => {
                                        resetGroupEditIndex(id);
                                    }}>
                                    <CrossMark />
                                </div>
                            </>
                            :
                            <>
                                <div
                                    style={{
                                        alignSelf: 'center',
                                        marginBottom: "50%"
                                    }}
                                    onClick={() =>
                                        editGroup(id)
                                    }>
                                    <EditIcon />
                                </div>
                                <div style={{
                                    alignSelf: 'center'
                                }}
                                    onClick={() => {
                                        setGroupOpenDelete({
                                            state: true,
                                            index: id,
                                        })
                                    }}>
                                    <DeleteIcon />
                                </div>
                            </>
                    }

                </div>

            </Stack>

            {/* </Card> */}
        </>

    );
}



export function DeleteConfirmation(props: any) {

    const {
        charId,
        setOpenDelete,
        openDelete,
        groupMediaName,
        accessToken,
        groupIndex,
        groups,
        setGroupList
    } = props;

    const handleClose = () => {
        setOpenDelete({ state: false, index: 0 });
    };
    const dispatch = useDispatch();

    const character_image_name = groupMediaName;
    let splitted_image_name = "";
    splitted_image_name = groupMediaName;

    const deleteConfirmed = async () => {

        const media_path = "tiny_learner/group/" + groupMediaName;
        //   const input = {
        //     Bucket: S3_BUCKET,
        //     Key: media_path
        //   }

        //   const command = new DeleteObjectCommand(input);
        //   await s3Client.send(command);

        //   const res = await postRequest(
        //       MEDIA_DELETE,
        //       {
        //           "id": charId,
        //           "media_type": "TL_GP"
        //       },
        //       accessToken
        //   );


        let tempArray = []
        groups.forEach(x => {
            if (x.id === charId) {
                x.groupMediaName = ""
                x.groupMediaPath = ""
            }
            tempArray.push(x)
        })
        setGroupList(tempArray)

        //     dispatch(ShowSuccessAlert({
        //       visible:true,
        //       message: `${splitted_image_name} Removed successfully.`
        //   }));
        setOpenDelete({ state: false, index: 0 });
    };
    return (
        <div>
            <Dialog
                open={openDelete['state']}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <h4>{"Delete Confirmation"}</h4>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <span>
                            Are you sure you want to delete
                            <strong> {splitted_image_name} </strong> for group <strong> {groupIndex} </strong>?
                        </span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button className='cancel-class' onClick={handleClose}>
                        Cancel
                    </button>

                    <button className='delete-class' onClick={deleteConfirmed}>
                        Delete
                    </button>
                </DialogActions>
            </Dialog>

        </div>
    )
}


export function GroupDeleteConfirmation(props: any) {

    const {
        charId,
        setGroupOpenDelete,
        groupOpenDelete,
        accessToken,
    } = props;

    const handleClose = () => {
        setGroupOpenDelete({ state: false, index: 0 });
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const deleteConfirmed = async () => {
        const res = await tlPostRequest(
            TinyLearnerEndpoints.GROUP_DELETE,
            {
                "group_id": charId,
            },
            accessToken
        )
        console.log("res", res)
        if (res) {
            if (isApiCodeSucess(res)) {
                dispatch(ShowSuccessAlert({
                    visible: true,
                    message: `${res.data.message}`
                }));
                navigate('/tinylearner/group')
            } else {
                setGroupOpenDelete({ state: false, index: 0 });
            }
        }

    };
    return (
        <div>
            <Dialog
                open={groupOpenDelete['state']}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <h4>{"Delete Confirmation"}</h4>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <span>
                            Are you sure you want to delete this group ?
                        </span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button className='cancel-class' onClick={handleClose}>
                        Cancel
                    </button>

                    <button className='delete-class' onClick={deleteConfirmed}>
                        Delete
                    </button>
                </DialogActions>
            </Dialog>

        </div>
    )
}
