import { combineReducers } from 'redux';

import {alertsReducer} from "./alerts";
import { projectsReducer } from './Projects';


const rootReducer = combineReducers({
    alerts: alertsReducer,
    projects: projectsReducer
});

export default rootReducer;