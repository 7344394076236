import { Reducer } from "react";
import { PROJECTS_REDUX, RESET } from "../../constants";

import { Actions, projectsInterface } from "./types";

export const initialState:projectsInterface = {
    projectsList: [],
    selectedProject:{
        uid: '',
        projectName: ''
    }
}


export const projectsReducer: Reducer<projectsInterface, Actions> = (state = initialState, { type, ...payload }: any) => {
    switch (type) {
        case PROJECTS_REDUX.UPDATE:
            return {
                ...state,
                projectsList: [...(payload?.payload || [])],
            };

        case PROJECTS_REDUX.SELECTED_PROJECT:
            return {
                ...state,
                selectedProject: {
                    uid: payload?.payload.uid,
                    projectName: payload?.payload.projectName,
                },
            };
      
        case RESET:
            return {
                ...initialState
            };

        default:
            return state;
    }
};
