import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { RootStateType } from '../store';

export default function SideRibbon(props:any) {

  let projectsListObject = useSelector((state: RootStateType) => state.projects);
  let projectName = projectsListObject?.selectedProject?.projectName || "";

  return (
    <div style={{
      display: 'block',
      borderRadius: '8px',
      width: '225px',
      padding: '15px',
      backgroundColor: 'rgb(15 111 175 / 93%)',
      boxShadow: '0 5px 10px rgba(0,0,0,.1)',
      color: '#fff',
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '1',
      textShadow: '0 1px 1px rgba(0,0,0,.2)',
      textAlign: 'center',
    }}>
      <span>{projectName}</span>
    </div>
  // </div>
  )
}



// export default function SideRibbon(props: any){
//   useEffect(() => {
//     // Update the localStorage value
//     localStorage.setItem('projectSelected', localStorage.getItem('projectSelected')
//     );

//     // Cleanup function to remove the localStorage value if needed
//     return () => {
//       localStorage.removeItem('projectSelected');
//     };
//   }, []);

//   return (
//     <>
//     <SideRibbonDesign />
//     </>
//   );
// };

