import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';

import { useContext, useEffect, useState } from 'react';
// @mui
import { Button, Container, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
// components
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';
// mock

import Checkbox from '@mui/material/Checkbox';


import { LEVELS, LEVEL_INDEX_UPDATE_API, UPDATE_LEVELS_AVIALABLE } from '../constants';


// import PRODUCTS from '../_mock/products';
import { AccessTokenContext } from '../routes';
import { isApiCodeSucess, postRequest } from '../data/helpers/services';
import { levelIndexUpdate } from '../data/mappers/createLevel';
import { HideProgressBar, ShowSuccessAlert } from '../store/alerts';
import { useDispatch, useSelector } from 'react-redux';
import PushPopUpModal from '../components/PushPopUpModal';
import JigsawAppVersion from './JigsawAppVersion';
import SideRibbon from '../components/SideRibbon';
import { RootStateType } from '../store';
import { SelectedProjectsInfo } from '../store/Projects';

// ----------------------------------------------------------------------


export function PageStyles() {
  return (
    <style>
      {`
        .css-dmfr2k:hover {
            background-color: #337417; !important;        
        }
    `}
    </style>
  )
}


export default function ProductsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openFilter, setOpenFilter] = useState(false);

  const [updateValues, setUpdateValues] = useState([]);

  const { uid, version } = useParams();

  if (localStorage.getItem('level_version') && localStorage.getItem('level_version') !== version) {
    localStorage.setItem('level_version', version)
  }

  let projectsListObject = useSelector((state: RootStateType) => state.projects);
  // if ((localStorage.getItem('uid') || (localStorage.getItem('uid') !== uid))){
  //   localStorage.setItem('uid', uid)
  //   localStorage.setItem('projectSelected', projectsListObject?.projectsList.filter((obj) => obj.uid === uid)[0].projectName)
  // }else{
  //   localStorage.setItem('uid', null)
  //   localStorage.setItem('projectSelected', projectsListObject?.projectsList.filter((obj) => obj.uid === uid)[0].projectName)
  // }


  if (uid && projectsListObject?.selectedProject) {
    if (projectsListObject?.selectedProject?.uid !== uid) {
      let projectName = uid ? projectsListObject?.projectsList.filter((obj) => obj.uid === uid)[0]?.projectName : ""
      dispatch(SelectedProjectsInfo({
        uid: uid,
        projectName: projectName ?? "SuperHero"
      }))
    }
  } else {
    if (projectsListObject?.selectedProject?.uid !== "") {
      dispatch(SelectedProjectsInfo({
        uid: "",
        projectName: ""
      }))
    }
  }


  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const [userList, setUserList] = useState<any>([])
  const [levelList, setLevelList] = useState(userList)

  const [selectedLevels, setSelectedLevels] = useState<any>([])
  const [alignment, setAlignment] = useState('');


  const getCookieAccessToken: any = useContext(AccessTokenContext)
  let accessToken: string = getCookieAccessToken.cookieAccessToken

  useEffect(() => {
    getAllLevelList();
  }, []);

  const getAllLevelList = async () => {
    let res: any = {}

    let version = localStorage.getItem('level_version')

    res = await postRequest(LEVELS, { "uid": uid }, accessToken,
      (version ? version : "")
    );
    if (res) {
      if (isApiCodeSucess(res)) {
        let allLevelAvialable = res.data?.data.all_level_avialable
        if (allLevelAvialable) {
          setAlignment("All")
        } else {
          setAlignment("Selected")
        }
        let ContentData = res.data?.data.level_list as any,
          formattedLevelListContent: any = [];

        ContentData.forEach((x) => {
          if (!x.is_deleted) {
            formattedLevelListContent.push({
              id: x.id,
              characterImage: x.character_image_path ?? "-",
              characterImageName: x.character_image_name ?? "",
              level: x.level_no,
              newLevel: x.is_new_level,
              scaling: x.scaling,
              isActive: x.is_active,
              imageSize: x.image_size
            });
            if (x.limited_level_selected) {
              let tempArray = selectedLevels
              tempArray.push(x.id);
              setSelectedLevels(tempArray)
            }
            setUserList(formattedLevelListContent)
          }
        });
      }
    }
  }

  const cardProps = {
    setUpdateValues: setUpdateValues,
    levelList: levelList,
    setLevelList: setLevelList,
    products: userList,
    setProducts: setUserList,
    selectedLevels: selectedLevels,
    setSelectedLevels: setSelectedLevels,
    uid: uid
  }


  const updateIndex = async () => {
    let indexSendForUpdate = []
    updateValues.forEach(x => {
      indexSendForUpdate.push(
        {
          id: x.id,
          index: levelList.findIndex(i => i.id === x.id) + 1
        }
      )
    }
    )

    let requestData = levelIndexUpdate(indexSendForUpdate, uid)

    var response: any = null;


    let version = localStorage.getItem('level_version')

    response = await postRequest(LEVEL_INDEX_UPDATE_API, requestData, accessToken,
      (version ? version : "")
    );

    if (response) {
      dispatch(HideProgressBar());
      if (isApiCodeSucess(response)) {
        const resData = response.data
        dispatch(ShowSuccessAlert({ visible: true, message: resData.message }));
        navigate(0)
      }
    }
  }

  const handleChange = async (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {

    var response: any = null;

    response = await postRequest(UPDATE_LEVELS_AVIALABLE, {
      uid: uid
    }, accessToken,
      (version ? version : "")
    );

    if (response) {
      dispatch(HideProgressBar());
      if (isApiCodeSucess(response)) {
        setAlignment(newAlignment)
        const resData = response.data
        dispatch(ShowSuccessAlert({ visible: true, message: resData.message }));
      }
    }
  };

  return (
    <>
      <Helmet>
        <title> Levels </title>
      </Helmet>

      <PageStyles />
      <Container style={{
        marginTop: "-80px"
      }}
      >
        <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" sx={{ marginBottom: "10px" }}>
          <div style={{
            display: "grid",
            gridColumnGap: "1rem",
            gridTemplateColumns: "repeat(2,1fr)",
          }}>
            <Typography variant="h4" sx={{ mb: 5 }}>
              Levels
            </Typography>
            {
              ((uid) || (version !== "v1")) ?
                <JigsawAppVersion />
                :
                null
            }

            <div style={{ width: "max-content" }}>
              {(updateValues.length > 0) ?
                <>
                  <Button variant="contained" sx={{
                    backgroundColor: "Green",
                    height: "50px",
                    "&:hover": {
                      backgroundColor: "#225c22",
                      cursor: "pointer",
                      height: "50px",
                    }
                  }} onClick={() => {
                    updateIndex()
                  }}>
                    <span
                      style={{
                        fontWeight: 600,
                        fontSize: "16px"
                      }}
                    >
                      Save
                    </span>
                  </Button>

                  <Button variant="contained" sx={{
                    backgroundColor: "red",
                    ml: 1,
                    height: "50px",
                    "&:hover": {
                      backgroundColor: "red",
                      cursor: "pointer",
                      height: "50px",
                      ml: 1,
                    }
                  }} onClick={() => {
                    navigate(0)
                  }}>
                    <span
                      style={{
                        fontWeight: 600,
                        fontSize: "16px"
                      }}
                    >
                      Cancel
                    </span>
                  </Button>
                </>
                : ""}
            </div>
          </div>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} justifyContent="space-between">
            {
              localStorage.getItem('level_version') === "v1"
                ?
                ""
                :
                <>
                  <ToggleButtonGroup
                    color="primary"
                    style={{
                      height: "fit-content",
                      marginRight: "30px"
                    }}
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                  >
                    <ToggleButton value="Selected">Selected</ToggleButton>
                    <ToggleButton value="All">All</ToggleButton>
                  </ToggleButtonGroup>

                  <PushPopUpModal style={{ marginLeft: "20px" }} project={1} uid={uid} />
                  <Button variant="contained" sx={{
                    ml: "20px",
                    height: "fit-content",
                  }} onClick={() => {
                    if (uid) {
                      navigate(`/dashboard/${uid}/level/create`)
                    } else {
                      navigate('/dashboard/level/create')
                    }
                  }}>
                    Add
                  </Button>
                </>
            }
          </Stack>
        </Stack>
        <div style={{ width: "95%", overflow: "auto" }}>

          <ProductList {...cardProps} />
        </div>
      </Container>
    </>
  );
}
