import { ALERTS_REDUX } from "../../constants";
import { 
        ShowSuccessAlertAction,
        ShowErrorAlertAction,
        ShowDialogBoxAction,
        alertParamsInterface,
        dialogInterface,
        ShowLoaderAction,
        HideLoaderAction,
        ShowBackdropAction,
        HideBackdropAction
    } from "./types";

export const ShowSuccessAlert = (payload: alertParamsInterface) : ShowSuccessAlertAction => ({
    type: ALERTS_REDUX.SHOW_SUCCESS_ALERT,
    payload: payload

});

export const ShowErrorAlert = (payload: alertParamsInterface) : ShowErrorAlertAction => ({
    type: ALERTS_REDUX.SHOW_ERROR_ALERT,
    payload: payload
});

export const ShowDialogBox = (payload: dialogInterface) : ShowDialogBoxAction => ({
    type: ALERTS_REDUX.SHOW_DIALOG_BOX,
    payload: payload
})

export const ShowProgressBar = () : ShowLoaderAction => ({
    type: ALERTS_REDUX.SHOW_LOADER
});

export const HideProgressBar = () : HideLoaderAction => ({
    type: ALERTS_REDUX.HIDE_LOADER
});

export const ShowBackdrop = () : ShowBackdropAction => ({
    type: ALERTS_REDUX.SHOW_BACKDROP
});

export const HideBackdrop = () : HideBackdropAction => ({
    type: ALERTS_REDUX.HIDE_BACKDROP
});

