import { Helmet } from 'react-helmet-async';
import { useContext, useEffect, useState } from 'react';

// @mui
import { Button, Container, Stack, Typography } from '@mui/material';

// components
import { GroupList } from '../../sections/@dashboard/groups';
import { groupIndexUpdate } from '../../data/mappers/TinyLearner/createGroup'

// mock
// import PRODUCTS from '../_mock/products';
import { AccessTokenContext } from '../../routes';
import { isApiCodeSucess, postRequest, tlPostRequest } from '../../data/helpers/services';
import { LEVELS, TinyLearnerEndpoints } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { HideLoaderAction, HideProgressBar, ShowSuccessAlert } from '../../store/alerts';
import { useDispatch } from 'react-redux';
import PushPopUpModal from '../../components/PushPopUpModal';
import JigsawAppVersion from '../JigsawAppVersion';
import TinyAppVersion from './TinyAppVersion';


// ----------------------------------------------------------------------

export default function GroupsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [groupList, setGroupList] = useState<any>([])
  const [updateValues, setUpdateValues] = useState([]);
  const [draggableGroupList, setDraggableGroupList] = useState(groupList)

  if (!localStorage.getItem('level_version') && localStorage.getItem('level_version') !== "v2") {
    localStorage.setItem('level_version', "v2")
  }

  const getCookieAccessToken: any = useContext(AccessTokenContext)
  let accessToken: string = getCookieAccessToken.cookieAccessToken

  useEffect(() => {
    getAllGroupList();
  }, []);

  const getAllGroupList = async () => {
    let res: any = {}

    res = await tlPostRequest(
      TinyLearnerEndpoints.GROUPS,
      "",
      accessToken
    );
    if (res) {
      if (isApiCodeSucess(res)) {
        let groupData = res.data?.data.group_list as any,
          formattedGroupListContent: any = [];

        groupData.forEach((x) => {
          formattedGroupListContent.push({
            id: x.id,
            groupName: x.group_name,
            groupIndex: x.group_no,
            groupPdf: x.is_new_level,
            groupMediaName: x.group_media_name,
            groupMediaPath: x.group_media_path,
            isActive: x.is_active,
            levelZipCount: x.levels_zip_count,
            zipSize: x.zip_size,

          });
          setGroupList(formattedGroupListContent)
        });
      }
    }
  }

  const updateIndex = async () => {
    let indexSendForUpdate = []
    updateValues.forEach(x => {
      indexSendForUpdate.push(
        {
          id: x.id,
          index: draggableGroupList.findIndex(i => i.id === x.id) + 1
        }
      )
    }
    )

    let requestData = groupIndexUpdate(indexSendForUpdate)

    var response: any = null;

    response = await tlPostRequest(TinyLearnerEndpoints.GROUP_INDEX_UPDATE, requestData, accessToken);

    if (response) {
      dispatch(HideProgressBar());
      if (isApiCodeSucess(response)) {
        const resData = response.data
        dispatch(ShowSuccessAlert({ visible: true, message: resData.message }));
        navigate(0)
      }
    }
  }

  return (
    <>
      <Helmet>
        <title> Groups </title>

      </Helmet>
      {/* 
      { (updateValues.length > 0) ? 
        <>
          <Button variant="contained" sx={{
            height: "2%",
            backgroundColor:"Green",
            position: "fixed",
            top: "60px",
            padding: "24px",
            zIndex: 99999,
            "&:hover": {
              backgroundColor: "#225c22",
              cursor:"pointer",
            }
          }} onClick={() => {
            updateIndex()
          }}>
              <span
                style={{
                  fontWeight: 600,
                  fontSize: "16px"
                }}
              >
                Save Changes
              </span>
          </Button>
        </>
        : 
        "" 
      } */}

      <Container style={{
        marginTop: "-80px"
      }}
      >
        <Stack direction="row" justifyContent="space-between" sx={{ flexWrap: "wrap", rowGap: "10px", mb: "30px", alignItems: "center" }}>
          <Stack direction="row" justifyContent="space-around" sx={{ minWidth: "260px" }}>
            <Typography variant="h4">
              Groups
            </Typography>
            <TinyAppVersion />
          </Stack>

          {(updateValues.length > 0) ?
            <>
              <div style={{ minWidth: "140px", display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between" }}>
                <Button variant="contained" sx={{
                  backgroundColor: "Green",
                  height: "fit-content",
                  "&:hover": {
                    backgroundColor: "#225c22",
                    cursor: "pointer",
                    height: "fit-content",
                  }
                }} onClick={() => {
                  updateIndex()
                }}>
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: "16px"
                    }}
                  >
                    Save
                  </span>
                </Button>


                <Button variant="contained" sx={{
                  backgroundColor: "red",
                  ml: 1,
                  height: "fit-content",
                  "&:hover": {
                    backgroundColor: "red",
                    cursor: "pointer",
                    height: "fit-content",
                    ml: 1,
                  }
                }} onClick={() => {
                  navigate(0)
                }}>
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: "16px"
                    }}
                  >
                    Cancel
                  </span>
                </Button>
              </div>
            </>
            :
            null
          }

          <div style={{ minWidth: "260px", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
            <PushPopUpModal project={2} />
            <Button variant="contained" onClick={() => {
              navigate('/tinylearner/group/create')
            }}>
              Add
            </Button>
          </div>
        </Stack>

        <GroupList groups={groupList} setGroupList={setGroupList} setUpdateValues={setUpdateValues} draggableGroupList={draggableGroupList} setDraggableGroupList={setDraggableGroupList} />
        {/* <ProductCartWidget /> */}

      </Container>
    </>
  );
}

