export const createLevelPosition = (data: any, levelId:string, imageArray:any, uid?:string ) => {
    let characterArray = []
    data.levelPositions.forEach((x,arrayIndex) =>{
        let imageFgPath = ""
        if (imageArray[arrayIndex*2].media){
            imageFgPath = `${process.env.REACT_APP_S3_BASE_PATH}${imageArray[arrayIndex*2].media}`
            if (uid){
                imageFgPath = `${process.env.REACT_APP_S3_BASE_PATH_CLONE}${uid}/${imageArray[arrayIndex*2].media}`
            }
        }
        let imageBgPath = ""
        if (imageArray[arrayIndex*2].media){
            imageBgPath = `${process.env.REACT_APP_S3_BASE_PATH}${imageArray[arrayIndex*2 + 1].media}`
            if (uid){
                imageBgPath = `${process.env.REACT_APP_S3_BASE_PATH_CLONE}${uid}/${imageArray[arrayIndex*2 + 1].media}`
            }
        }

        characterArray.push({
            char_id:(x.char_id > 0) ? (x.char_id).toString() : "",
            index:parseFloat(x.index),
            x_move: parseFloat(x.x_move),
            y_move: parseFloat(x.y_move),
            real_x_position:parseFloat(x.real_x_position),
            real_y_position:parseFloat(x.real_y_position),
            fg_image:`${imageArray[arrayIndex*2].media}`,
            bg_image:`${imageArray[arrayIndex*2 + 1].media}`,
            image_fg_path:`${imageFgPath}`,
            image_bg_path:`${imageBgPath}`
            })
        }    
    )

    let characterPoints:any = {
        "level_id": levelId,
        "uid":uid,
        "character_position_data": characterArray
    }
    // if(data.id) {
    //     match.id = data.id;
    //     match.is_immediate = (startType === '0') ? "1"  : "2";
    // }

    return characterPoints;
}