import { Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootStateType } from '../../../store';
import { isApiCodeSucess, postRequest } from '../../../data/helpers/services';
import { PROJECTS_LIST_API } from '../../../constants';
import { useContext, useEffect, useState } from 'react';
import { AccessTokenContext } from '../../../routes';
import { SaveProjectsInfo, projectInfoInterface, projectsInterface } from '../../../store/Projects';
import AddSuperHeroClone from '../../../pages/AddSuperHeroClone';

export default function SideBarNav() {
  const dispatch = useDispatch();
  const getCookieAccessToken: any = useContext(AccessTokenContext)
  let accessToken: string = getCookieAccessToken.cookieAccessToken

  let projectsListObject = useSelector((state: RootStateType) => state.projects);


  const [projects, setProjects] = useState(projectsListObject?.projectsList)

  const getProjectsList = async () => {
    let res: any = {}

    res = await postRequest(PROJECTS_LIST_API, {
    }, accessToken, ""
    );
    if (res) {
      if (isApiCodeSucess(res)) {
        let responseData = res.data.data
        let responseArray = responseData.project_list

        if (responseArray.length > 0) {
          let tempArray: projectInfoInterface[] = [];
          responseArray.forEach((resObj, index) => {
            tempArray.push({
              "id": resObj.id,
              "projectName": resObj.project_name,
              "uid": resObj.uid,
            })
          })
          setProjects(tempArray)
          dispatch(SaveProjectsInfo(tempArray));
        }
      }
    }
  }

  if (projects && projects.length === 0) {
    getProjectsList()
  }

  return (
    <Sidebar>

      <Menu renderExpandIcon={({ open }) => <span>{open ? '-' : '+'}</span>}>
        <SubMenu label="Old Projects">

          <Link
            style={{
              textDecoration: "none",
              color: 'inherit',
              alignSelf: "center",
              cursor: "pointer",
            }}
            to="/dashboard/level/list/v1">
            <MenuItem>
              SuperHero(Index Based)
            </MenuItem>
          </Link>

          <Link
            style={{
              textDecoration: "none",
              color: 'inherit',
              alignSelf: "center",
              cursor: "pointer",
            }}
            to="/dashboard/level/list/v2">

            <MenuItem>
              SuperHero(Name Based)
            </MenuItem>
          </Link>


          <Link
            style={{
              textDecoration: "none",
              color: 'inherit',
              alignSelf: "center",
              cursor: "pointer",
            }}
            to="/tinylearner/group">

            <MenuItem>
              Tiny Learner
            </MenuItem>
          </Link>

        </SubMenu>
      </Menu>


      <Menu renderExpandIcon={({ open }) => <span>{open ? '-' : '+'}</span>}>
        <SubMenu label="Jigsaw Clones">

          {projects &&
            projects.map(obj =>
              <>
                <Link
                  style={{
                    textDecoration: "none",
                    color: 'inherit',
                    alignSelf: "center",
                    cursor: "pointer",
                  }}
                  to={`/dashboard/${obj.uid}/v3/level/`}>
                  <MenuItem>
                    {obj.projectName}
                  </MenuItem>
                </Link>
              </>)
          }

          {/* <Link 
                style={{
                  textDecoration: "none",
                  color: 'inherit',
                  alignSelf: "center",
                  cursor: "pointer",
                }}
                to={`/dashboard/add/clone/`}> */}

          {
            projects &&
            <>
              <MenuItem>
                <AddSuperHeroClone projects={projects} setProjects={setProjects} />
              </MenuItem>
            </>
          }
          {/* </Link>          */}
        </SubMenu>
      </Menu>
    </Sidebar>

  )
}
