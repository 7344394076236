import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Form, Field } from 'react-final-form'
import { TextField } from 'mui-rff';
import CancelIcon from '@mui/icons-material/Cancel';
import Slide from '@mui/material/Slide';
import { isApiCodeSucess, postRequest } from '../../data/helpers/services';
import { HideProgressBar, ShowProgressBar, ShowSuccessAlert } from '../../store/alerts';
import { useDispatch } from 'react-redux';
import { AccessTokenContext } from '../../routes';
import { ADD_CLONE_JIGSAW, AppVersionApis } from '../../constants';
import { ResetUserInfo } from '../../store/Projects';
import { useParams } from 'react-router-dom';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "70%",
  maxWidth: "600px",
  height: "fit-content",
  borderRadius: "10px",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderTopRightRadius: "24px"
};



const initialFormData = {
  android_version: "",
  ios_version: ""
}


const initialAppStoreFormData = {
  android_store_version: "",
  ios_store_version: ""
}

export default function TinyVersionClone(props: any) {
  const dispatch = useDispatch();

  let { uid, version } = useParams();

  const getCookieAccessToken: any = useContext(AccessTokenContext)
  let accessToken: string = getCookieAccessToken.cookieAccessToken

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [initialForm, setInitialForm] = useState(initialFormData);
  const [initialAppStoreForm, setInitialAppStoreForm] = useState(initialAppStoreFormData);

  const [androidBgDownload, setAndroidBgDownload] = useState(false);
  const [iosBgDownload, setIosBgDownload] = useState(false);

  useEffect(() => {
    getAppVersion()
  }, [open])

  const getAppVersion = async () => {
    let res: any = {}

    res = await postRequest(
      AppVersionApis.TL_APP_VERSION_LIST, {}, accessToken,
      "v2"
    );
    if (res) {
      if (isApiCodeSucess(res)) {
        let responseData = res.data.data

        setAndroidBgDownload(responseData.android_download_levels)
        setIosBgDownload(responseData.ios_download_levels)

        setInitialForm({
          android_version: responseData.android_version,
          ios_version: responseData.ios_version
        })

        setInitialAppStoreForm({
          android_store_version: responseData.android_store_version,
          ios_store_version: responseData.ios_store_version,
        })
      }
    }
  }

  const handleSubmit = async (e) => {
    dispatch(ShowProgressBar());
    let data = {
      android_version: e.android_version,
      ios_version: e.ios_version,
      uid: uid
    };

    var response: any = null;

    response = await postRequest(AppVersionApis.TL_APP_VERSION_UPDATE, data, accessToken, "v2");

    if (response) {
      dispatch(HideProgressBar());
      if (isApiCodeSucess(response)) {
        const resData = response.data
        dispatch(ShowSuccessAlert({ visible: true, message: resData.message }));
        handleClose()
      }
    }
  }

  const handleStoreAppSubmit = async (e) => {
    dispatch(ShowProgressBar());
    let data = {
      android_version: e.android_store_version,
      ios_version: e.ios_store_version,
      title: "New Version avialable",
      description: "New Version avialable",
      uid: uid
    };

    var response: any = null;
    response = await postRequest(AppVersionApis.SUPERHERO_APP_STORE_VERSION_UPDATE, data, accessToken);

    if (response) {
      dispatch(HideProgressBar());
      if (isApiCodeSucess(response)) {
        const resData = response.data
        dispatch(ShowSuccessAlert({ visible: true, message: resData.message }));
        handleClose()
      }
    }
  }
  return (
    <>
      <Button variant="contained" sx={{
        fontWeight: "600", cursor: "pointer",
        height: "fit-content"
      }} onClick={handleOpen}>Version</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CancelIcon
            onClick={handleClose}
            sx={{
              color: "#C64242",
              position: "absolute",
              top: "-8px",
              right: "-11px",
              cursor: "pointer"
            }} />
          <div className="app-version">
            <div className='app-version-form'>
              <Form
                initialValues={{ ...initialForm }}
                onSubmit={handleSubmit}>
                {
                  (props: any) => (
                    <form onSubmit={props.handleSubmit}>
                      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" spacing={1} sx={{
                        mt: "4%", alignItems: "center", rowGap: "10px"
                      }}>
                        <input type="hidden" name={`level_id`} />
                        <TextField
                          label="Android verion" name={`android_version`}
                          sx={{
                            minWidth: "100px",
                            width: "25%",
                          }} type="number"
                          required={true}
                        />
                        <TextField
                          label="ios version" name={`ios_version`}
                          sx={{
                            width: "25%",
                            minWidth: "100px"
                          }}
                          type="number"
                          required={true}
                        />

                        <div className="button-section"
                          style={{
                            width: "20%",
                          }}
                        >
                          <Button type="submit" variant="contained">
                            Save
                          </Button>
                        </div>
                      </Stack>
                    </form>
                  )
                }
              </Form>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}
