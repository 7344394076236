import { useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from "react-redux";
import { postRequest, isApiCodeSucess } from '../../../data/helpers/services';
import { ADMIN_LOGIN, SEND_RESET_PASSWORD } from '../../../constants';

import { userLogin } from "./../../../data/mappers/login"
import VisibilityIcon from '@mui/icons-material/Visibility';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { HideProgressBar, ShowProgressBar, ShowSuccessAlert } from '../../../store/alerts';
import Cookies from 'js-cookie';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const emailForm = useRef(null);
  const passwordForm = useRef(null);

  const [formType, setFormType] = useState(1);

  const [showPassword, setShowPassword] = useState(false);

  const handleClick = async () => {

    const values = {
      email: emailForm.current.value,
      password: passwordForm.current.value
    }
    const data = userLogin(values);

    let response: any = null;
    dispatch(ShowProgressBar());
    response = await postRequest(ADMIN_LOGIN, data, "");
    if (response) {
      dispatch(HideProgressBar());
      if (isApiCodeSucess(response)) {
        try {
          // After receiving the access token from the server
          Cookies.set('access_token', response.data.data.authorization.access_token, {
            expires: 7, // in days
            secure: true,
            // httpOnly: true,
          });
          Cookies.set('refresh_token', response.data.data.authorization.refresh_token, {
            expires: 7, // in days
            secure: true,
            // httpOnly: true,
          });
          dispatch(ShowSuccessAlert({ visible: true, message: "Logged in successfully." }));
          navigate('/dashboard/super_hero/v3/level/', { replace: true });
        } catch (error) {
          console.error(error);
        }
      }
    }
  };


  const handleResetMail = async () => {
    const values = {
      email: (emailForm.current.value.trim()).toLowerCase(),
    }

    let response: any = null;
    dispatch(ShowProgressBar());
    response = await postRequest(SEND_RESET_PASSWORD, values, "");
    if (response) {
      dispatch(HideProgressBar());
      if (isApiCodeSucess(response)) {
        dispatch(ShowSuccessAlert({ visible: true, message: response.data.message }));
        setFormType(1)
      }
    }
  }



  return (
    <>
      {
        formType === 1 ?
          <>
            <Typography variant="h4" gutterBottom>
              Sign in to SuperHero
            </Typography>
            <Stack spacing={3} marginTop={'4%'} marginBottom={'5%'}>
              <TextField name="email" inputRef={emailForm} label="Email address" />
              <TextField
                inputRef={passwordForm}
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <div>
              <span style={{
                color: "#2065D1",
                fontWeight: "500",
                cursor: "pointer",
                float: "right",
                marginBottom: "15px",
              }}
                onClick={() => setFormType(2)}
              >
                Forgot Password
              </span>
            </div>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
              Login
            </LoadingButton>
          </>
          :
          <>
            <Typography variant="h4" gutterBottom>
              Forgot Password
            </Typography>
            <Stack spacing={3} marginTop={'4%'} marginBottom={'5%'}>
              <TextField name="email" inputRef={emailForm} label="Email address" />
            </Stack>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleResetMail}>
              Send Mail
            </LoadingButton>
          </>
      }

    </>
  );
}

