import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// theme
import ThemeProvider from './theme';
// components
// import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';

// routes
import Router from './routes';
import ProgressBar from './components/progressbar';

import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";

import { store, persistor } from './store';
import NoPinchZoom from './components/no-pinch-zoom/NoPinchZoom';
import ExitConfirmation from './components/no-pinch-zoom/ExitConfirmation'; // Path to your ExitConfirmation component



// ----------------------------------------------------------------------


export default function App() {

  return (
    /* eslint-disable no-debugger */
    <HelmetProvider>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider>
              <NoPinchZoom>
                <ScrollToTop />
                {/* <StyledChart /> */}
                <ProgressBar />
                {/* <ExitConfirmation /> */}
                <Router />
              </NoPinchZoom>
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </HelmetProvider >
    /* eslint-enable no-debugger */
  );
}
