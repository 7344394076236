import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Form, Field } from 'react-final-form'
import { TextField } from 'mui-rff';
import CancelIcon from '@mui/icons-material/Cancel';
import Slide from '@mui/material/Slide';
import { isApiCodeSucess, postRequest } from '../data/helpers/services';
import { HideProgressBar, ShowErrorAlert, ShowProgressBar, ShowSuccessAlert } from '../store/alerts';
import { useDispatch } from 'react-redux';
import { AccessTokenContext } from '../routes';
import { CHANGE_PASSWORD } from '../constants';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "70%",
  height: 300,
  borderRadius: "10px",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderTopRightRadius: "24px"
};


const initialFormData = {
  password: "",
  confirmPassword: "",
}

export default function PasswordModal(props: any) {
  const dispatch = useDispatch();

  let { openPasswordModal, setOpenPasswordModal } = props;
  const [passwordValue, setPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");


  const getCookieAccessToken: any = useContext(AccessTokenContext)
  let accessToken: string = getCookieAccessToken.cookieAccessToken

  const [initialForm, setInitialForm] = useState(initialFormData);
  const handleOpen = () => setOpenPasswordModal(true);
  const handleClose = () => setOpenPasswordModal(false);

  const handlePasswordChange = (event) => {
    let updatedPassword = event.target.value;
    setPasswordValue(updatedPassword);

    if (confirmPasswordValue) {
      if (confirmPasswordValue !== updatedPassword) {
        setConfirmPasswordError("Password does not match")
      } else {
        setConfirmPasswordError("")
      }
    }
  };


  const handleConfirmPasswordChange = (event) => {
    let updatedConfirmPassword = event.target.value;
    if (passwordValue !== updatedConfirmPassword) {
      setConfirmPasswordError("Password does not match")
    } else {
      setConfirmPasswordValue(updatedConfirmPassword);
      setConfirmPasswordError("")
    }
  };



  const handleSubmit = async (e) => {

    if (confirmPasswordError !== "") {
      dispatch(ShowErrorAlert({ visible: true, message: "Password does not match" }));
      return false;
    }

    dispatch(ShowProgressBar());
    let requestData = {
      password: e.password,
    }

    var response: any = null;
    response = await postRequest(CHANGE_PASSWORD, requestData, accessToken, "");

    if (response) {
      dispatch(HideProgressBar());
      if (isApiCodeSucess(response)) {
        const resData = response.data
        dispatch(ShowSuccessAlert({ visible: true, message: resData.message }));
        handleClose()
      }
    }
  }

  return (
    <>
      <Modal
        open={openPasswordModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CancelIcon
            onClick={handleClose}
            sx={{
              color: "#C64242",
              position: "absolute",
              top: "-8px",
              right: "-11px",
              cursor: "pointer"
            }} />

          <Form
            initialValues={{ ...initialForm }}
            onSubmit={handleSubmit}>
            {
              (props: any) => (
                <form onSubmit={props.handleSubmit}>
                  <TextField
                    label="Password (max 20 chars)" name={`password`}
                    type="text"
                    required
                    inputProps={{
                      maxLength: 20,
                      onChange: handlePasswordChange
                    }}

                  />
                  <TextField
                    label="Confirm Password" name={`confirmPassword`}
                    sx={{
                      mt: "20px"
                    }}
                    type="text"
                    required={true}
                    inputProps={{
                      maxLength: 20,
                      onChange: handleConfirmPasswordChange

                    }}
                    error={confirmPasswordError !== ""}
                    helperText={confirmPasswordError}

                  />
                  <div className="button-section"
                    style={{
                      marginTop: "30px",
                    }}
                  >
                    <Button sx={{ float: "right" }} type="submit" variant="contained">
                      Submit
                    </Button>
                  </div>
                </form>
              )
            }
          </Form>
        </Box>
      </Modal>
    </>
  )
}
